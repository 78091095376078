import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  Button,
  InputBase,
  Menu,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {ReactComponent as AIIcon} from "assets/icons/ai.svg";
import useEnsoAIButtonStyles from "styles/useEnsoAIButtonStyles";
// Custom
import ButtonLabelLoader from "core/loaders/ButtonLabelLoader";
import ServiceInfoDialog from "components/Dialogs/ServiceInfoContent";
import PrimaryButton from "core/buttons/PrimaryButton";
// Actions
import {createAction} from "redux/actions/experiencesActions";
import {postChannels} from "redux/actions/accountsActions";
// Utils
import {suggestedPrompts} from "configuration/specs";
import AIBGImage from "assets/img/AI-background.png";
import {addonServices} from "configuration/enums";
import {THEME} from "configuration/settings";
import clsx from "clsx";

export default function EnsoAIButton({
  type = "",
  handleGeneratedText = () => null,
  iconOnly,
  ...props
}) {
  const classes = useEnsoAIButtonStyles();
  const dispatch = useDispatch();
  const currentGuestId = React.useRef(null);
  const userProfile = useSelector((state) => state.defaultReducer.user_profile);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [text, setText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [loadingDialogAction, setLoadingDialogAction] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const suggtdPrmts = suggestedPrompts[type]?.prompts || [];
  const EnsoAIEnabled = userProfile?.connected_services?.includes("cb");

  React.useEffect(() => {
    if (!!props.guest && props.guest.guest_id !== currentGuestId.current) {
      currentGuestId.current = props.guest.guest_id;
      setText((prev) => "");
      setError((prev) => null);
    }
  }, [props.guest]);

  const handleClose = () => setAnchorEl(null);
  const handleCloseModal = () => setModalOpen(false);
  const handleOnKeyDown = (e) => e.stopPropagation();
  const onSuggestedPromptClick = (prompt) => () => setText((prev) => prompt);

  const handleEnable = () => {
    setLoadingDialogAction(true);
    dispatch(
      postChannels(
        {
          enso_key: userProfile?.enso_key,
          data: {
            service: "cb",
            provider: "chatgpt",
            options: {},
          },
        },
        (response) => {
          if (response.options.success) {
            if (!userProfile.connected_services) {
              userProfile.connected_services = [];
            }
            userProfile.connected_services.push("cb");
          } else if (response.options.message === "pay") {
            window.location.href = "/admin/settings/billing/:redirect";
          }
          setLoadingDialogAction(false);
          handleCloseModal();
        },
        (error) => {
          console.log(error);
          setLoadingDialogAction(false);
          handleCloseModal();
        },
      ),
    );
  };

  const handleClick = (e) => {
    const target = e.currentTarget;
    if (EnsoAIEnabled) {
      setAnchorEl((prev) => (!!prev ? null : target));
    } else {
      setModalOpen((prev) => true);
    }
  };

  const handleInputChange = (e) => {
    setText(e.target.value);
    if (!!error) {
      setError((prev) => null);
    }
  };

  const handleSubmit = () => {
    setLoading((prev) => true);
    generateResponse();
  };

  const handleSuccess = (response) => {
    setLoading((prev) => false);
    handleGeneratedText(response);
    setText((prev) => "");
    handleClose();
  };

  const handleError = (err) => {
    setLoading((prev) => false);
    setError((prev) => err);
  };

  const generateResponse = () => {
    dispatch(
      createAction({
        bookingId: props.guest?.bookings?.[0],
        guestId: props.guest?.guest_id,
        messageSent: props.latest_message?.sent,
        listingId: props.listing?.listing_id,
        action: {
          action_id: "generate_text",
          prompt: text,
          content_type: type,
          extra_context: props.extra_context,
        },
        onSuccess: (r) => {
          if (!r?.action?.response) {
            handleError(
              "Sorry, there are no results for this prompt, please try another prompt.",
            );
          } else if (!!r?.action?.response?.errorMessage) {
            handleError(r.action.response.errorMessage);
          } else {
            handleSuccess(r.action.response);
          }
        },
        onError: (err) =>
          handleError("Sorry, something went wrong, please try again."),
      }),
    );
  };

  const promptMenu = (
    <Menu
      elevation={0}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      disablePortal
      classes={{
        paper: classes.paper,
        list: classes.menuContainer,
      }}
      anchorOrigin={{vertical: "top", horizontal: "left"}}
      transformOrigin={{vertical: "bottom", horizontal: "center"}}
    >
      <div className={classes.titleSection}>
        <div className={classes.titleRow}>
          <SvgIcon
            component={AIIcon}
            viewBox="0 0 18 18"
            className={classes.AIIcon}
          />
          <Typography variant="h1" className={classes.colored}>
            {"EnsoAI Prompt"}
          </Typography>
        </div>
        <Typography className={classes.colored}>
          {suggestedPrompts[type]?.title || ""}
        </Typography>
      </div>
      <InputBase
        fullWidth
        autoFocus
        value={text}
        error={!!error}
        multiline
        maxRows={10}
        disabled={!!loading}
        className={classes.input}
        classes={{error: classes.inputError}}
        onKeyDown={handleOnKeyDown}
        onChange={handleInputChange}
        endAdornment={
          <div className={classes.generateBtnContainer}>
            <PrimaryButton
              color="secondary"
              variant="contained"
              label={
                loading ? <ButtonLabelLoader variant="white" /> : "Generate"
              }
              disabled={!text.trim() || !!loading}
              onClick={handleSubmit}
            />
          </div>
        }
      />
      {!!error && (
        <Box mt={-3}>
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        </Box>
      )}
      {!!suggtdPrmts?.length && (
        <Typography variant="subtitle2" className={classes.subheader}>
          {"Suggested prompts"}
        </Typography>
      )}
      {!!suggtdPrmts?.length &&
        suggtdPrmts.map((sp) => (
          <Button
            key={sp}
            disableRipple
            variant="contained"
            classes={{
              root: classes.promptButton,
              label: classes.btnLabel,
            }}
            onClick={onSuggestedPromptClick(sp)}
          >
            {sp}
          </Button>
        ))}
    </Menu>
  );

  return (
    <>
      {promptMenu}
      <ServiceInfoDialog
        open={modalOpen}
        service={addonServices.enso[0]}
        loading={loadingDialogAction}
        dialogBGImage={AIBGImage}
        handleEnable={handleEnable}
        onClose={handleCloseModal}
        dialogBGColors={{bg1: THEME.AI.color, bg2: THEME.AI.color2}}
      />
      <Button
        variant="outlined"
        disableRipple
        classes={{
          root: clsx(classes.btn, {zindex: !!EnsoAIEnabled && !!anchorEl}),
          label: classes.btnLabel,
          startIcon: classes.AIIcon,
        }}
        onClick={handleClick}
        startIcon={<SvgIcon component={AIIcon} viewBox="0 0 18 18" />}
      >
        {iconOnly ? "" : "EnsoAI"}
      </Button>
    </>
  );
}
