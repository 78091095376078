import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Storage} from "aws-amplify";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {
  Box,
  CardContent,
  ListItem,
  MenuList,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
// Custom
import CustomMenu from "core/menus/CustomMenu";
import ErrorAlert from "core/alerts/ErrorAlert";
import ImageDialog from "core/dialogs/ImageDialog";
import ExpandButton from "core/buttons/ExpandButton";
import CloseIconButton from "core/buttons/CloseIconButton";
import CustomCardHeader from "core/cards/CustomCardHeader";
// Redux
import {
  closeGeneralError,
  openGeneralError,
} from "redux/actions/settingsActions";
// Utils
import {getFileErrorMessage} from "utilities/formatUtilities";
import {useImageCompressor} from "utilities/fileUtilities";
import config, {THEME} from "configuration/settings";
import {newUuid, sleep} from "utilities/helperFunctions";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  imageSelectorBtn: {
    border: `1px solid ${THEME.text} !important`,
    color: THEME.text,
    padding: theme.spacing(1, 3),
    marginRight: theme.spacing(2),
    "&:hover": {backgroundColor: THEME.text + "0d"},
    "&.light": {
      border: `1px solid ${theme.palette.common.white} !important`,
      color: theme.palette.common.white,
      "&:hover": {backgroundColor: "transparent"},
    },
  },
  modal: {
    position: "absolute",
    backgroundColor: "#FFF",
    boxShadow:
      "0px 10px 20px rgba(32, 37, 38, 0.1), 0px 20px 50px rgba(32, 37, 38, 0.1)",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "fit-content",
    width: "fit-content",
    maxHeight: "80%",
    maxWidth: "80%",
    minWidth: "60%",
    borderRadius: 6,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    outline: "none",
  },
  cardHeader: {
    padding: theme.spacing(4),
    borderBottom: "1px solid #F2F2F5",
  },
  cardContent: {
    padding: theme.spacing(4) + "px !important",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
  },
  imageGrid: {
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "row",
    flexWrap: "wrap",
  },
  img: {
    borderRadius: 5,
    height: 175,
    objectFit: "cover",
    cursor: "pointer",
    minWidth: 130,
    flex: 1,
    transition: "transform 0.15s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  subheader: {fontWeight: 600},
}));

const ImageSelector = ({
  pictures,
  light,
  recommendedPictures = [],
  resourceType,
  onPictureChange = () => {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [imgToUpload, setImgToUpload] = React.useState(null);
  const [compressEnabled, setCompressEnabled] = React.useState(false);
  const [uploadingText, setUploadingText] = React.useState(false);
  const [error, setError] = React.useState({show: false, message: ""});
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  let noPictures = !pictures?.length && !recommendedPictures.length;

  useImageCompressor({
    srcImgs: !!compressEnabled && !!imgToUpload ? [imgToUpload] : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
  });

  const handleCloseMenu = () => setAnchorEl((prev) => null);
  const handleCloseImageDialog = () => setOpenDialog((prev) => false);
  const handleErrorClose = () => setError({show: false, message: ""});
  function handleCloseError(dispatch) {
    dispatch(closeGeneralError());
  }

  const handleSetLoading = (text) => {
    setUploadingText(text);
    setOpenDialog(true);
  };

  function handlePictureToggle(picture) {
    onPictureChange(picture);
    setOpenDialog((prev) => false);
    setUploadingText((prev) => false);
  }

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled((prev) => false);
    setImgToUpload((prev) => null);
    handleUploadImage(outputImgs[0]);
  };

  const handleUploadImage = async (file) => {
    const imageName = `${current_user}/listing-resources/${resourceType}/header-image-${newUuid()}`;
    try {
      await Storage.put(imageName, file, {contentType: file.type});
      const link = `${config.s3.URL}${imageName}`;
      handlePictureToggle(link);
    } catch (error) {
      dispatch(
        openGeneralError({
          message: "Failed to upload the image",
          subtitle: "Something went wrong, please try again later",
          open: true,
          disableSeverity: true,
          onClose: () => handleCloseError(dispatch),
        }),
      );
      setOpenDialog(false);
      setUploadingText((prev) => false);
    }
  };

  const fileUploaded = (e) => {
    handleSetLoading("Uploading image...");
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      setImgToUpload((prev) => file);
      sleep(250).then(() => setCompressEnabled((prev) => true));
    };
    reader.readAsDataURL(file);
  };

  const displayError = (picture, errorType, customMessage) => {
    let message = getFileErrorMessage({picture, errorType, customMessage});
    setError({show: true, message});
  };

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Paper elevation={0} className={classes.modal}>
          <CustomCardHeader
            type="title"
            title="Select an image"
            className={classes.cardHeader}
            action={<CloseIconButton onClick={() => setModalOpen(false)} />}
          />
          <CardContent className={classes.cardContent}>
            {!!recommendedPictures.length && (
              <>
                <Box mb={2}>
                  <Typography variant="caption" className={classes.subheader}>
                    {"RECOMMENDED"}
                  </Typography>
                </Box>
                <Box className={classes.imageGrid} mb={2}>
                  {recommendedPictures.map((p, i) => (
                    <img
                      src={p}
                      className={classes.img}
                      key={`recommended-${p.picture ?? i}`}
                      onClick={() => {
                        setModalOpen((prev) => false);
                        handlePictureToggle(p);
                      }}
                    />
                  ))}
                </Box>
              </>
            )}
            {!!recommendedPictures.length && !!pictures.length && (
              <Box mb={2}>
                <Typography variant="caption" className={classes.subheader}>
                  {"LISTING IMAGES"}
                </Typography>
              </Box>
            )}
            {!!pictures.length && (
              <div className={classes.imageGrid}>
                {pictures.map((p, i) => (
                  <img
                    key={p.picture ?? i}
                    src={p.picture}
                    className={classes.img}
                    onClick={() => {
                      setModalOpen((prev) => false);
                      handlePictureToggle(p.picture);
                    }}
                  />
                ))}
              </div>
            )}
          </CardContent>
        </Paper>
      </Modal>
      <ErrorAlert
        open={error.show}
        onClose={handleErrorClose}
        message={error.message}
      />
      <ImageDialog
        open={openDialog}
        currentUser={current_user}
        uploadingText={uploadingText}
        pictures={pictures}
        handleClose={handleCloseImageDialog}
        displayError={displayError}
      />
      <input
        ref={inputRef}
        style={{display: "none"}}
        type="file"
        accept="image/*"
        onChange={fileUploaded}
      />
      <CustomMenu
        transformOrigin="right top"
        zIndex={1301}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        content={
          <MenuList>
            <ListItem
              button
              disableRipple
              onClick={() => {
                inputRef.current.click();
                handleCloseMenu();
              }}
            >
              Upload Photo
            </ListItem>
            <ListItem
              button
              disableRipple
              disabled={noPictures}
              onClick={() => {
                setModalOpen(true);
                handleCloseMenu();
              }}
            >
              Select from existing photos
            </ListItem>
          </MenuList>
        }
      />
      <ExpandButton
        hasPopup
        variant="outlined"
        className={clsx(classes.imageSelectorBtn, {light: !!light})}
        label={"Edit main image"}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
    </>
  );
};

export default ImageSelector;
