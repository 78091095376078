import React from "react";
import {Typography} from "@material-ui/core";

export default function HTMLContent({v}) {
  return (
    <Typography
      className="html-content"
      style={{wordWrap: "break-word"}}
      dangerouslySetInnerHTML={{__html: v}}
    />
  );
}
