import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
// UI
import NumberFormat from "react-number-format";
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
//Custom
import {EmptyListPanel} from "components/Helpers/EmptyPanels";
import VirtualizedList from "components/Lists/VirtualizedList";
import CustomCardHeader from "core/cards/CustomCardHeader";
import PrimaryButton from "core/buttons/PrimaryButton";
import UserPanel from "components/Panels/UserPanel";
import UserScopeTag from "core/chips/UserScopeTag";
import SearchBar from "core/bars/SearchBar";
import Panel2 from "core/panels/Panel2.jsx";
import Panel3 from "core/panels/Panel3";
// Actions
import {getProfile, setSelectedViewItem} from "redux/actions/accountsActions";
// Utilities
import {searchRows} from "utilities/helperFunctions";
import {FIXED_SIZES} from "configuration/settings";
import usePrevious from "hooks/usePrevious";
import _ from "lodash";

const searchKeys = ["name"];

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  panel2Container: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  header: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  searchContainer: {
    padding: theme.spacing(0, 4, 4),
    "& > .searchContainer": {paddingBottom: 0},
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
  },
  item: {
    height: "100%",
    padding: theme.spacing(0, 4),
  },
  avatar: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(4),
  },
  subtitle: {textTransform: "capitalize"},
  yourAccountText: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,
  },
}));

export default function Users() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch("/admin/settings/users/:enso_user_id");
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const loading = useSelector((state) => state.defaultReducer.loading).profile;
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const selectedViewItems = useSelector(
    (state) => state.defaultReducer.selected_view_items,
  );
  let savedItem = selectedViewItems.settings.item;
  let savedUser = selectedViewItems.settings.props.selectedUser;
  const [users, setUsers] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(savedUser);
  const [searchText, setSearchText] = React.useState("");
  const prevSearchText = usePrevious(searchText);
  const {me, completeUsers} = React.useMemo(() => {
    if (!!user_profile) {
      const newUsers = user_profile.enso_users ?? [];
      const newMe = {
        created_at: user_profile.created_at,
        profile_picture: user_profile.profile_picture,
        enso_user_id: user_profile.enso_user_id,
        enso_key: user_profile.enso_key,
        email_signature: user_profile.email_signature,
        scopes: user_profile.scopes,
        enso_user_email: user_profile.enso_user_email,
        description: user_profile.description,
        name: user_profile.name,
        phone: user_profile.phone,
        sms_notifications: user_profile.sms_notifications,
        email_notifications: user_profile.email_notifications,
        mobile_notifications: user_profile.mobile_notifications,
      };
      const newCompleteUsers = !!newUsers.find(
        (u) => u.enso_user_id === newMe.enso_user_id,
      )
        ? newUsers
        : [newMe].concat(newUsers);
      return {me: newMe, completeUsers: newCompleteUsers};
    } else {
      return {me: null, completeUsers: null};
    }
  }, [user_profile]);

  React.useEffect(() => {
    let userId = match?.params?.enso_user_id;
    if (!users?.length) {
      return;
    }
    if (
      match?.isExact &&
      (!selectedUser || !selectedUser.enso_user_id !== userId)
    ) {
      setSelectedUser((prev) => users.find((eu) => eu.enso_user_id === userId));
    }
  }, [location, users]);

  React.useEffect(() => {
    if (!current_user || !!user_profile?.enso_users[0]?.scopes) {
      return;
    }
    const params = `enso_key=${current_user}`;
    dispatch(getProfile(params, true));
  }, [current_user]);

  React.useEffect(() => {
    if (!user_profile?.enso_users) {
      return;
    }
    const orderedItems = _.orderBy(
      user_profile.enso_users.filter(
        (u) => u.enso_user_id !== user_profile.enso_user_id,
      ),
      "name",
      "asc",
    );
    const newUsers = [me].concat(orderedItems);
    setUsers((prev) => newUsers);
  }, [user_profile]);

  React.useEffect(() => {
    if (!searchText) {
      if (!!prevSearchText) {
        const orderedItems = _.orderBy(
          user_profile.enso_users.filter(
            (u) => u.enso_user_id !== user_profile.enso_user_id,
          ),
          "name",
          "asc",
        );
        const newUsers = [me].concat(orderedItems);
        setUsers((prev) => newUsers);
      }
    } else {
      filterRows(searchText);
    }
  }, [searchText]);

  React.useEffect(() => {
    if (selectedUser === "new_user") {
      return;
    } else {
      let newProps = {
        selectedDevice: null,
        selectedUser: selectedUser,
        connectedAccount: {
          account: null,
          accountType: null,
          integrationSpec: null,
        },
      };
      dispatch(setSelectedViewItem("settings", savedItem, newProps));
    }
  }, [selectedUser]);

  const onCreateNew = () => setSelectedUser("new_user");
  const filterRows = (searchText) => {
    setUsers((prev) =>
      _.orderBy(
        searchRows(completeUsers, searchKeys, searchText),
        "name",
        "asc",
      ),
    );
  };

  const onClose = () => {
    setSelectedUser(null);
    history.replace("/admin/settings/users");
  };

  function getUserItem({index}) {
    const user = users[index];
    const itsMe = user.enso_user_id === me.enso_user_id;
    const isAdmin = user.scopes?.accounts === "write";

    return (
      <ListItem
        dense
        button
        divider
        disableRipple
        className={classes.item}
        selected={selectedUser?.enso_user_id === user.enso_user_id}
        onClick={() => setSelectedUser(user)}
      >
        <ListItemIcon>
          <Avatar
            className={classes.avatar}
            alt={`${user.name} profile picture`}
            src={user.picture || user.profile_picture}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              {user.name}
              {(itsMe || isAdmin) && (
                <span className={classes.yourAccountText}>
                  {itsMe ? "• Your account" : " "}
                </span>
              )}
              <UserScopeTag user={user} />
            </>
          }
          primaryTypographyProps={{variant: "h1"}}
          secondary={user.role || ""}
          secondaryTypographyProps={{
            className: classes.subtitle,
            variant: "body1",
            color: "textSecondary",
          }}
        />
      </ListItem>
    );
  }

  const searchbar = React.useMemo(() => {
    return (
      <div className={classes.searchContainer}>
        <SearchBar
          disableGutters
          searchInput={searchText}
          handleSearchInput={(val) => setSearchText((prev) => val)}
        />
      </div>
    );
  }, [searchText]);

  const userList = React.useMemo(() => {
    if (!users) {
      return null;
    }
    return (
      <VirtualizedList
        hideScrollbar
        getRowItem={getUserItem}
        rowHeight={FIXED_SIZES.users}
        totalRows={users.length}
      />
    );
  }, [users, selectedUser]);

  const panel2 = (
    <div className={classes.panel2Container}>
      {loading ? (
        <EmptyListPanel loading list="users" />
      ) : (
        <>
          <CustomCardHeader
            type="header"
            action={
              <PrimaryButton
                label="Create New"
                size="small"
                id="create-new-user-btn"
                disabled={
                  user_profile.scopes?.accounts !== "write" &&
                  user_profile.scopes?.accounts !== "read"
                }
                onClick={onCreateNew}
              />
            }
            className={classes.header}
            title={
              <>
                <NumberFormat
                  value={users?.length || 0}
                  thousandSeparator
                  displayType="text"
                />
                {" Users"}
              </>
            }
          />
          {searchbar}
          <div className={classes.content}>{userList}</div>
        </>
      )}
    </div>
  );

  const panel3 = !!selectedUser && (
    <UserPanel
      userId={selectedUser?.enso_user_id || selectedUser}
      myUser={me}
      onClose={onClose}
    />
  );

  return (
    <div className={classes.root}>
      <Panel2
        flexAuto
        content={panel2}
        isPanel3Open={isTabletView ? false : !!panel3}
        hidden={isTabletView && !!selectedUser}
      />
      {(!isTabletView || !!selectedUser) && (
        <Panel3
          content={panel3}
          hidden={!panel3}
          maintainWidth={!isTabletView}
          disableMaxWidth={isTabletView}
        />
      )}
    </div>
  );
}
