import React from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
import {isMobile} from "react-device-detect";
// UI
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowDownIcon from "@material-ui/icons/ExpandMore";
import useMessageThreadStyles from "styles/useMessageThreadStyles";
import {ReactComponent as CalendarIcon} from "assets/icons/calendar.svg";
import {SvgIcon} from "@material-ui/core";
// Custom Components
import SendInput from "../TextFields/SendInput";
import CustomDialog from "core/dialogs/CustomDialog";
import PrimaryButton from "core/buttons/PrimaryButton";
import EnsoAIButton from "components/Misc/EnsoAIButton";
import {EmptyMessagesPanel} from "../Helpers/EmptyPanels";
import MessageSelector from "components/MultiOption/MessageSelector";
import ScheduledMessagePanel from "components/Panels/ScheduledMessagePanel";
import {
  CommonChannelMsg,
  getAutoMsg,
  GetEmail,
  Notification,
  NotificationsGroup,
  TimeDivider,
} from "../Helpers/messageHelpers";
// Utilities
import {
  getGuestContactInfo,
  getScheduledMsgsData,
  isChannelAvailable,
  orderBookings,
  replaceFields,
  selectDefaultChannel,
  sleep,
} from "utilities/helperFunctions";
import {messageChannelLabels} from "configuration/constants";
import {useResizeDetector} from "react-resize-detector";
import useScrollPosition from "hooks/useScrollPosition";
import {THEME} from "configuration/settings";
import {pmsConfig} from "configuration/specs.js";
import usePrevious from "hooks/usePrevious";
import {format, getDayOfYear, isValid} from "date-fns";
import {contentTypeEnum} from "configuration/enums";
import clsx from "clsx";
import _ from "lodash";
import qs from "qs";
// Actions
import {setSelectedGuestBookings} from "redux/actions/bookingsActions";
import {getNotifications} from "redux/actions/notificationsActions";
import {createAction} from "redux/actions/experiencesActions";
import {getServiceAccounts} from "redux/actions/servicesActions";
import {getMessages} from "redux/actions/messagesActions";
import {getGuest} from "redux/actions/guestsActions";

const maxWidthForIconBtns = 500;
const messageChannelKeys = Object.keys(messageChannelLabels);

function MessagesPanel({
  initialGuestMsgs,
  loadingAnimations,
  setLoadingAnimations,
  guestId = null,
  onMessageSent,
  onMessageError,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch("/admin/messages/:guest_id");
  const mobileChatMatch = useRouteMatch("/admin/messages/chat/:guest_id");
  const mobileProfileMatch = useRouteMatch("/admin/messages/profile/:guest_id");
  const {width, height, ref} = useResizeDetector();
  const containerResizeProps = useResizeDetector();
  // Selectors
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const notifications_dict = useSelector(
    (state) => state.defaultReducer.notifications_dict,
  );
  const start_keys = useSelector((state) => state.defaultReducer.start_keys);
  const guests_arr = useSelector((state) => state.defaultReducer.guests);
  const guests = useSelector((state) => state.defaultReducer.guests_dict);
  const messages = useSelector((state) => state.messagesReducer.messages);
  const guestBookings = useSelector(
    (state) => state.defaultReducer.selected_guest_bookings,
  );
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const houses_dict = useSelector(
    (state) => state.defaultReducer.house_data_dict,
  );
  const loadingNotifs = useSelector(
    (state) => state.defaultReducer.loading,
  ).guest_notifications;
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const bookings_dict = useSelector(
    (state) => state.defaultReducer.bookings_dict,
  );
  // Refs
  const divScroll = React.useRef();
  const isScrolling = React.useRef(false);
  const waitingMsgsResponse = React.useRef(false);
  const waitingGuest = React.useRef(false);
  const guestDidLoad = React.useRef(false);
  const lastEmailMsg = React.useRef(null);
  // Snapshot - variables used for before rendering
  const snapshot = React.useRef({
    scrollYPos: 0,
    isFetchingMore: false,
    isNewGuest: false,
    forceScroll: false,
  });
  // State
  const [body, setBody] = React.useState("");
  const [emailSubject, setEmailSubject] = React.useState("");
  const [guestMsgs, setGuestMsgs] = React.useState(initialGuestMsgs);
  const [selectedChannel, setSelectedChannel] = React.useState(-1);
  const [showScrollDownBtn, setShowScrollDownBtn] = React.useState(false);
  const [loadingChat, setLoadingChat] = React.useState(false);
  const [guestEmail, setGuestEmail] = React.useState("");
  const [scheduledPanelOpen, setScheduledPanelOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [SMSHidden, setSMSHidden] = React.useState(false);
  const [airbnbHidden, setAirbnbHidden] = React.useState(true);
  const [templates, setTemplates] = React.useState([]);
  const [whatsappAccountId, setWhatsappAccountId] = React.useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = React.useState(null);
  const [allListingsDisabled, setAllListingsDisabled] = React.useState(false);
  const [AItext, setAItext] = React.useState("");
  const [displaySchMsgs, setDisplaySchMsgs] = React.useState(false);
  const [emailPreviews, setEmailPreviews] = React.useState({
    hasEmailMsgs: false,
    hasMoreMsgs: false,
    total: 0,
    loaded: 0,
  });
  const [orderedBookings, setOrderedBookings] = React.useState([]);
  const [loadingGuest, setLoadingGuest] = React.useState(false);
  const [predictedResponse, setPredictedResponse] = React.useState(null);
  // General
  let paramsGuestId =
    mobileChatMatch?.params?.guest_id ??
    mobileProfileMatch?.params?.guest_id ??
    match?.params?.guest_id;
  const useIconsOnly = containerResizeProps.width <= maxWidthForIconBtns;
  const classes = useMessageThreadStyles({isTabletView});
  const selectedGuestId = React.useMemo(() => {
    if (!!loadingAnimations) {
      return null;
    } else {
      return guestId ?? paramsGuestId ?? null;
    }
  }, [match, mobileChatMatch, mobileProfileMatch, loadingAnimations, guestId]);
  const selectedGuest = React.useMemo(
    () => guests[selectedGuestId] || {},
    [guests, selectedGuestId],
  );
  const {guest_email, guest_phone} = React.useMemo(
    () => getGuestContactInfo(selectedGuest),
    [selectedGuest],
  );
  const prevGuestId = usePrevious(selectedGuestId);
  let num_messages = guestMsgs.length;
  let userOnlyMsgs =
    guestMsgs?.filter(
      (m) =>
        !m.hasOwnProperty("notification_id") &&
        !m.hasOwnProperty("notification_type"),
    ) || [];
  let flags = useFlags();

  const hasAvailableChannels = React.useMemo(() => {
    return (
      !!selectedGuest.email_destination ||
      !!selectedGuest.phone ||
      !!selectedGuest.channels?.includes("Airbnb")
    );
  }, [selectedGuest]);

  const {scheduledMessages, schMsgsGroupsCount} = React.useMemo(() => {
    const newSchMsgs =
      Object.values(notifications_dict.guest_id[selectedGuestId] || {}).filter(
        (n) => messageChannelKeys.includes(n.action?.action_id),
      ) || [];
    const msgGroups = getScheduledMsgsData(newSchMsgs);

    return {
      scheduledMessages: newSchMsgs,
      schMsgsGroupsCount: msgGroups.length,
    };
  }, [notifications_dict, selectedGuestId]);
  const prevScheduledMsgs = usePrevious(scheduledMessages);
  const readOnly = user_profile.scopes?.messaging !== "write";

  useScrollPosition(
    ({prevPos, currPos}) => {
      if (!isScrolling.current) {
        isScrolling.current = true;
      }
      const scrollYPos = currPos.height - currPos.y;
      snapshot.current = {...snapshot.current, scrollYPos};
      const showBtn = scrollYPos >= 1500;
      if (showBtn !== showScrollDownBtn) setShowScrollDownBtn(showBtn);
      if (
        currPos.y <= 5 &&
        !messages[selectedGuestId]?.messages?.[0]?.start_reached &&
        !snapshot.current.isNewGuest &&
        !snapshot.current.isFetchingMore
      ) {
        loadMoreMessages();
      }
      if (prevPos.y > currPos.y && snapshot.current.isNewGuest) {
        snapshot.current.isNewGuest = false;
      } else if (
        prevPos.height < currPos.height &&
        snapshot.current.isNewGuest
      ) {
        scrollToPosition({bottom: true});
      }
    },
    [guestMsgs, showScrollDownBtn],
    ref,
    300,
  );

  React.useEffect(() => {
    guestDidLoad.current = false;
    lastEmailMsg.current = null;
  }, [selectedGuestId]);

  React.useEffect(() => {
    if (!isMobile) {
      return;
    }
    if (
      !selectedGuest?.metadata &&
      !!selectedGuestId &&
      !guestDidLoad.current
    ) {
      guestDidLoad.current = true;
      setLoadingGuest((prev) => true);
      dispatch(
        getGuest(
          selectedGuestId,
          () => setLoadingGuest((prev) => false),
          () => setLoadingGuest((prev) => false),
        ),
      );
    }
  }, [isMobile, selectedGuest, selectedGuestId]);

  React.useEffect(() => {
    if (!selectedGuest || !mobileChatMatch?.isExact) return;
    const newOrderedBkgs = orderBookings(
      selectedGuest,
      bookings_dict,
      selectedGuestId,
    );
    setOrderedBookings(newOrderedBkgs);
  }, [bookings_dict, selectedGuest]);

  React.useEffect(() => {
    if (!selectedGuest || !mobileChatMatch?.isExact) return;
    dispatch(setSelectedGuestBookings(orderedBookings));
  }, [orderedBookings]);

  React.useLayoutEffect(() => {
    if (isMobileView || !window.StonlyWidget) {
      return;
    }
    window.StonlyWidget("sendData", {
      guideData: {
        hasAutomessages: !!scheduledMessages.length,
      },
    });
  }, [scheduledMessages]);

  React.useEffect(() => {
    let timer = null;
    if (!prevScheduledMsgs || loadingNotifs) {
      return;
    }
    timer = setTimeout(() => {
      setDisplaySchMsgs((prev) => true);
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [scheduledMessages, loadingNotifs]);

  React.useLayoutEffect(() => {
    if (isMobileView) {
      setGuestMsgs((prev) => initialGuestMsgs);
    }
  }, [isMobileView, initialGuestMsgs]);

  React.useLayoutEffect(() => {
    let isMounted = true;
    sleep(THEME.transitions.mobileScreens + 250).then(() => {
      if (isMounted && !!loadingAnimations) {
        setLoadingAnimations(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [loadingAnimations]);

  React.useLayoutEffect(() => {
    if (!snapshot.current.isNewGuest) {
      return;
    }
    if (!isScrolling.current) {
      // If the initial list of messages does not scroll and there are more messages to load:
      if (
        !!ref.current?.scrollHeight &&
        ref.current?.scrollTop === 0 &&
        ref.current?.scrollHeight <= ref.current?.clientHeight &&
        !messages[selectedGuestId]?.messages?.[0]?.start_reached
      ) {
        loadMoreMessages();
      }
    }
    scrollToPosition({bottom: true});
  }, [height]);

  React.useLayoutEffect(() => {
    if (!num_messages) return;
    if (selectedChannel === 2)
      snapshot.current = {...snapshot.current, forceScroll: true};
    else if (snapshot.current.forceScroll) {
      snapshot.current = {...snapshot.current, forceScroll: false};
      scrollToPosition({bottom: true});
    }
  }, [selectedChannel]);

  React.useLayoutEffect(() => {
    if (!num_messages) {
      return;
    }
    if (snapshot.current.isNewGuest) {
      // If new thread is clicked
      if (!Object.keys(selectedGuest ?? {}).length) {
        waitingGuest.current = true;
      } else {
        scrollToPosition({bottom: true});
      }
    } else if (!!snapshot.current.isFetchingMore) {
      // When more messages are loaded when scrolling up
      snapshot.current = {...snapshot.current, isFetchingMore: false};
      scrollToPosition({position: snapshot.current.scrollYPos});
    }
    if (waitingMsgsResponse.current) {
      setSelectedChannel(getInitialChannel());
      waitingMsgsResponse.current = false;
    }
  }, [guestMsgs]);

  React.useEffect(() => {
    let timer = null;
    if (!emailPreviews.hasEmailMsgs) {
      return;
    }
    timer = setTimeout(() => {
      const allPreviewsLoaded = emailPreviews.total === emailPreviews.loaded;
      if (allPreviewsLoaded && !emailPreviews.hasMoreMsgs) {
        scrollToPosition({bottom: true});
        setGuestMsgs((prev) => [...prev]); // Force re-rendering
      } else if (!snapshot.current.isNewGuest) {
        scrollToPosition({position: snapshot.current.scrollYPos});
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [emailPreviews]);

  React.useLayoutEffect(() => {
    isScrolling.current = false;
    if (!selectedGuestId) return;
    snapshot.current = {
      ...snapshot.current,
      isFetchingMore: false,
      isNewGuest: !initialGuestMsgs.length,
    };
    if (!initialGuestMsgs.length) {
      waitingMsgsResponse.current = true;
    } else {
      setSelectedChannel(getInitialChannel());
    }
  }, [selectedGuestId]);

  React.useEffect(() => {
    if (
      !Object.keys(serviceAccounts?.accounts ?? {}).length ||
      !serviceAccounts?.accounts?.whatsapp
    ) {
      dispatch(
        getServiceAccounts({
          onSuccess: (r) => {
            handleWhatsappServiceAccount(r.accounts);
          },
        }),
      );
    } else {
      handleWhatsappServiceAccount(serviceAccounts?.accounts);
    }
  }, []);

  React.useEffect(() => {
    if (
      selectedChannel == 0 &&
      !user_profile?.connected_services?.includes("sms")
    ) {
      setDialogOpen(true);
    }
    setSelectedTemplateId(null);
  }, [selectedChannel]);

  React.useEffect(() => {
    let timer = null;
    if (!current_user || !selectedGuestId) {
      return;
    }
    if (prevGuestId !== selectedGuestId) {
      setScheduledPanelOpen((prev) => false);
    }

    if (!notifications_dict.guest_id[selectedGuestId]) {
      timer = setTimeout(() => {
        const params = {enso_key: current_user, guest_id: selectedGuestId};
        dispatch(getNotifications(qs.stringify(params), true));
      });
    }

    return () => clearTimeout(timer);
  }, [current_user, selectedGuestId]);

  React.useEffect(() => {
    if (selectedChannel === -1) {
      setSelectedChannel(getInitialChannel());
    }
    if (!!selectedGuest && waitingGuest.current) {
      scrollToPosition({bottom: true});
    }
  }, [selectedGuest]);

  React.useEffect(() => {
    if (!!selectedGuest && selectedChannel !== -1 && waitingGuest.current) {
      waitingGuest.current = false;
      scrollToPosition({bottom: true});
    }
  }, [selectedGuest, selectedChannel]);

  function addKnowledge(content, sent, onSuccess) {
    dispatch(
      createAction({
        trigger: {trigger: "now"},
        action: {action_id: "update_knowledge_base", content: content},
        guestId: selectedGuestId,
        messageSent: sent,
        onSuccess: onSuccess,
      }),
    );
  }

  React.useEffect(() => {
    if (!selectedGuestId) return;
    setEmailSubject((prev) => "");
    const timer = setTimeout(() => {
      const currMsgs = messages[selectedGuestId]?.messages;
      setShowScrollDownBtn((prev) => false);
      let newUserMsgs = [];
      let latestMsg = null;
      if (!currMsgs) {
        setEmailPreviews((prev) => ({
          hasEmailMsgs: false,
          hasMoreMsgs: false,
          total: 0,
          loaded: 0,
        }));
        setGuestMsgs((prev) => []);
        dispatch(
          getMessages({
            guestId: selectedGuestId,
            onSuccess: (response) => {
              newUserMsgs =
                response?.messages?.filter(
                  (m) =>
                    !m.hasOwnProperty("notification_id") &&
                    !m.hasOwnProperty("notification_type"),
                ) || [];
              latestMsg =
                newUserMsgs.length > 0 && newUserMsgs[newUserMsgs.length - 1];
              if (
                !!latestMsg?.predicted_responses?.length &&
                !!latestMsg.predicted_responses[0].response
              ) {
                setAItext((prev) => latestMsg.predicted_responses[0].response);
              }
            },
          }),
        );
      } else {
        setGuestMsgs((prev) => currMsgs);
        dispatch(
          getMessages({
            guestId: selectedGuestId,
            sync: true,
            onSuccess: (response) => {
              newUserMsgs =
                response?.messages?.filter(
                  (m) =>
                    !m.hasOwnProperty("notification_id") &&
                    !m.hasOwnProperty("notification_type"),
                ) || [];
              latestMsg =
                newUserMsgs.length > 0 && newUserMsgs[newUserMsgs.length - 1];
              if (
                !!latestMsg?.predicted_responses?.length &&
                !!latestMsg.predicted_responses[0].response
              ) {
                setAItext((prev) => latestMsg.predicted_responses[0].response);
              }
            },
          }),
        );
        const {hasEmails, totalEmails} = countEmailPreviews(currMsgs);
        if (hasEmails) {
          setEmailPreviews((prev) => ({
            hasEmailMsgs: true,
            hasMoreMsgs: false,
            total: totalEmails,
            loaded: 0,
          }));
        } else {
          setEmailPreviews((prev) => ({
            hasEmailMsgs: false,
            hasMoreMsgs: false,
            total: 0,
            loaded: 0,
          }));
        }
      }
    });
    return () => {
      setLoadingChat(false);
      clearTimeout(timer);
    };
  }, [selectedGuestId]);

  React.useEffect(() => {
    if (!selectedGuestId) return;
    const timer = setTimeout(() => {
      const currMsgs = messages[selectedGuestId]?.messages || [];
      const isEqual = _.isEqual(currMsgs, guestMsgs);
      if (!isEqual) {
        const {hasEmails, totalEmails} = countEmailPreviews(currMsgs);
        if (hasEmails) {
          setEmailPreviews((prev) => ({
            hasEmailMsgs: true,
            hasMoreMsgs: !!guestMsgs.length,
            total: totalEmails,
            loaded: prev.loaded,
          }));
        } else {
          setEmailPreviews((prev) => ({
            hasEmailMsgs: false,
            hasMoreMsgs: false,
            total: 0,
            loaded: 0,
          }));
        }
        setGuestMsgs((prev) => currMsgs);
      }
    });
    return () => {
      setLoadingChat(false);
      clearTimeout(timer);
    };
  }, [messages]);

  React.useEffect(() => {
    let allDisabled = checkListingsDisabled(houses_dict, guestBookings);
    setAllListingsDisabled(allDisabled);
    if (allDisabled && selectedChannel !== -1) {
      setSelectedChannel(-1);
    }
  }, [guestBookings, houses_dict]);

  React.useEffect(() => {
    const pmsEnabled = !!pmsConfig[selectedGuest?.integration]?.ab_msg;
    const directlyEnabled =
      user_profile?.connected_integrations?.includes("abnb") &&
      (guestBookings.some((db) => !!db.custom?.airbnb_confirmation_code) ||
        selectedGuest?.metadata?.custom?.airbnb_thread_id);
    const lodgifyFlagEnabled =
      selectedGuest?.integration == "lg" && !!flags.lodgifyMessaging;
    if (pmsEnabled || directlyEnabled || lodgifyFlagEnabled) {
      setAirbnbHidden((prev) => false);
    } else {
      setAirbnbHidden((prev) => true);
    }
  }, [selectedGuest, user_profile, guestBookings]);

  const countEmailPreviews = (messageArray) => {
    let hasEmails = false;
    let totalEmails = 0;
    _.each(messageArray, (m) => {
      if (["automessage", "email"].includes(m.channel)) {
        ++totalEmails;
        hasEmails = true;
      }
    });

    return {hasEmails, totalEmails};
  };

  const handleWhatsappServiceAccount = (servAccnts) => {
    let account = servAccnts.whatsapp?.[0];
    if (!!account) {
      setWhatsappAccountId(account.service_id);
      setTemplates(account.properties?.templates);
    }
  };

  const checkListingsDisabled = (listings, bookings) => {
    const allListingsDisabled =
      !Object.keys(listings ?? {}).length || !bookings?.length
        ? false
        : bookings.every(
            (b) =>
              (listings[b.listing_id] ?? {}).hasOwnProperty("active") &&
              listings[b.listing_id].active === false,
          );
    return allListingsDisabled;
  };

  const loadMoreMessages = () => {
    const currMsgs = messages[selectedGuestId];
    const lastSent = currMsgs?.start_key.sent;
    if (!!lastSent && !currMsgs.messages[0].start_reached) {
      setLoadingChat(true);
      snapshot.current = {...snapshot.current, isFetchingMore: true};
      dispatch(getMessages({guestId: selectedGuestId, lastSent}));
    }
  };

  const replaceTags = (field) =>
    replaceFields(field, selectedGuest, guestBookings[0]);
  const resetSelectedChannel = () => {
    selectedChannel === 2 && setSelectedChannel(getInitialChannel());
  };

  const replyEmail = (subject) => {
    setEmailSubject("Re: " + subject);
    setSelectedChannel(2);
  };

  const handleScroll = (e) => {
    if (!e.currentTarget || !!isScrolling.current) {
      return;
    }
    if (
      e.currentTarget.scrollTop <= 300 &&
      !messages[selectedGuestId]?.messages?.[0]?.start_reached &&
      !snapshot.current.isFetchingMore
    ) {
      snapshot.current = {
        ...snapshot.current,
        isNewGuest: false,
        scrollYPos: e.currentTarget.scrollHeight - e.currentTarget.scrollTop,
      };
      loadMoreMessages();
    }
  };

  const scrollToPosition = (args) => {
    const {bottom, position, behavior} = args;
    if (bottom && divScroll.current)
      divScroll.current.scrollIntoView({
        block: "end",
        behavior: behavior || "auto",
      });
    else {
      const scrollEl = ref.current;
      if (scrollEl) scrollEl.scrollTop = scrollEl.scrollHeight - position;
    }
  };

  const handleChannelSelect = (newChannel) => {
    if (newChannel === 2) {
      if (!!lastEmailMsg.current?.message?.subject) {
        setEmailSubject("Re: " + lastEmailMsg.current?.message?.subject);
      }
    }
    setSelectedChannel((prev) => newChannel);
  };

  const getInitialChannel = () => {
    setGuestEmail(guest_email);
    return selectDefaultChannel({
      guest: selectedGuest,
      userProfile: user_profile,
      guestPhone: guest_phone,
      lastGuestMessage: userOnlyMsgs?.pop(),
      airbnbHidden,
    });
  };

  const messagesList = React.useMemo(() => {
    let lastSender = "";
    let lastTime = null;
    let groupedNotifs = null;
    return guestMsgs.map((m, i) => {
      if (!m.id && !("start_reached" in m)) return null;
      let name = "";
      const assignedTo =
        user_profile?.enso_users?.find(
          (eu) => eu.enso_user_id === m.enso_user_id,
        ) ?? {};
      const assignedToName = (assignedTo.name || "").split(" ")[0];
      switch (m.sender_type) {
        case "user":
          name = assignedToName;
          break;
        case "other_user":
          name = selectedGuest.name;
          break;
        case "automessage":
          name = "Automessage";
          break;
        case "chatbot":
          name = `${assignedToName} using EnsoAI`;
          break;
        case "auto_chatbot":
          name = `EnsoAI`;
          break;
        default:
          break;
      }

      const showSentBy =
        !!m.sender_type &&
        m.sender_type !== "other_user" &&
        (["chatbot", "automessage"].includes(m.sender_type) ||
          (lastSender !== m.sender_type && lastSender !== name));

      if (!!m.sender_type && m.sender_type !== "other_user") {
        if (m.sender_type === "user") lastSender = name;
        else lastSender = m.sender_type;
      }

      if (
        i > 0 &&
        !!guestMsgs[i - 1] &&
        !guestMsgs[i - 1].hasOwnProperty("notification_id") &&
        !guestMsgs[i - 1].hasOwnProperty("notification_type")
      ) {
        lastTime = guestMsgs[i - 1].sent ?? lastTime;
      }

      const timeLabel =
        !!m.sent && isValid(m.sent) ? format(m.sent, "MMM d, p") : null;
      const sentByLabel = showSentBy
        ? `Sent by ${name} ${!!timeLabel ? ` @ ${timeLabel}` : ""}`
        : timeLabel;
      const categoryLabel = m?.tags?.question_type;

      if (m.hasOwnProperty("start_reached")) {
        lastTime = guestMsgs[i + 1]?.sent ?? lastTime;
        const firstTimeDivider = !!guestMsgs[i + 1] ? (
          <TimeDivider key="first-time-divider" m={guestMsgs[i + 1]} force />
        ) : null;
        if (m.start_reached)
          return (
            <React.Fragment key="start-conversation">
              <div className={classes.startText}>
                <Typography
                  variant="caption"
                  style={{color: THEME.subdued, marginTop: 10}}
                >
                  START OF CONVERSATION
                </Typography>
              </div>
              {firstTimeDivider}
            </React.Fragment>
          );
        else return firstTimeDivider;
      }

      const who = m.sender_type === "other_user" ? "them" : "me";
      const timeDivider =
        i > 0 ? <TimeDivider m={m} previousMsgTime={lastTime} /> : null;

      const isNotification =
        m.hasOwnProperty("notification_id") ||
        m.hasOwnProperty("notification_type");
      const isValidNotificationType = !!isNotification;
      const isNextMsgNotification =
        guestMsgs[i + 1]?.hasOwnProperty("notification_id") ||
        guestMsgs[i + 1]?.hasOwnProperty("notification_type");
      const isNextNotificationTimeValid =
        !!isNextMsgNotification &&
        getDayOfYear(guestMsgs[i + 1].sent) === getDayOfYear(m.sent);
      const isValidNextNotificationType =
        !!isNextMsgNotification && !!isNextNotificationTimeValid;
      if (isNotification) {
        if (isValidNotificationType && isValidNextNotificationType) {
          if (!!groupedNotifs) {
            groupedNotifs = {
              ...groupedNotifs,
              endInd: i + 1,
            };
            return null;
          } else {
            groupedNotifs = {
              timeDivider,
              startInd: i,
              endInd: i + 1,
            };
            return null;
          }
        } else {
          if (!!groupedNotifs) {
            const notifs = {
              items: guestMsgs.slice(
                groupedNotifs.startInd,
                groupedNotifs.endInd + 1,
              ),
              timeDivider: groupedNotifs.timeDivider,
            };
            groupedNotifs = null;
            return (
              <NotificationsGroup
                key={`${m.id}-notif-group`}
                notifications={notifs.items}
                timeDivider={notifs.timeDivider}
              />
            );
          } else {
            return (
              <Notification
                key={`${m.id}-${i}`}
                m={m}
                timeDivider={timeDivider}
                isRendered={!!isValidNotificationType}
              />
            );
          }
        }
      }

      if (m.channel === "email" && m.sender_type === "other_user") {
        lastEmailMsg.current = m;
      }
      if (
        !!lastEmailMsg.current &&
        m.channel === "email" &&
        m.sender_type === "user" &&
        lastEmailMsg.current.message?.subject?.includes(
          `Re: ${m.message?.subject}`,
        )
      ) {
        lastEmailMsg.current = null;
      }
      const onEmailLoad = () =>
        setEmailPreviews((prev) => ({...prev, loaded: prev.loaded + 1}));
      switch (m.channel) {
        case "automessage":
          return getAutoMsg(
            m,
            who,
            timeDivider,
            selectedGuest,
            sentByLabel,
            onEmailLoad,
          );
        case "email":
        case "vrbo":
        case "homeaway":
        case "booking_com":
          return (
            <GetEmail
              m={m}
              key={m.id}
              who={who}
              replyEmail={replyEmail}
              timeDivider={timeDivider}
              sentByLabel={sentByLabel}
              onEmailLoad={onEmailLoad}
            />
          );
        case "SMS":
        case "whatsapp":
        case "airbnb":
          return (
            <CommonChannelMsg
              key={`${m.id}-${i}`}
              m={m}
              who={who}
              addKnowledge={addKnowledge}
              timeDivider={timeDivider}
              sentByLabel={sentByLabel}
              categoryLabel={categoryLabel}
              flags={flags}
              language={user_profile?.language || "en"}
            />
          );
        default:
          return null;
      }
    });
  }, [guestMsgs]);

  return !guests_arr.length || !!loadingAnimations ? (
    <EmptyMessagesPanel
      fixedNumber={isMobileView ? 3 : null}
      loading={start_keys.guests === null || !!loadingAnimations}
      hideInput={start_keys.guests !== null && !loadingAnimations}
    />
  ) : (
    <div className={classes.root}>
      {loadingChat && (
        <CircularProgress
          color="secondary"
          className={clsx(classes.circleProgress, {mobile: isMobileView})}
        />
      )}
      {(!num_messages && selectedChannel !== 2) || loadingGuest ? (
        <EmptyMessagesPanel
          fixedNumber={isMobileView ? 3 : null}
          loading
          hideInput
        />
      ) : (
        selectedChannel !== 2 && (
          <div className={classes.collapse}>
            <div className="main-messenger-wrapper">
              <div className={classes.topGradient} />
              <div
                ref={ref}
                className={clsx("inner", classes.hideScrollbar, {
                  mobile: isMobileView,
                })}
                onScroll={handleScroll}
              >
                {messagesList}
                <IconButton
                  classes={{
                    root: clsx(classes.floatButton, {
                      [classes.floatButtonHidden]: !showScrollDownBtn,
                    }),
                    label: classes.floatButtonLabel,
                  }}
                  onClick={() =>
                    scrollToPosition({bottom: true, behavior: "smooth"})
                  }
                >
                  <ArrowDownIcon style={{marginBottom: -18}} />
                  <ArrowDownIcon />
                </IconButton>
                <div ref={divScroll} />
              </div>
            </div>
          </div>
        )
      )}
      {selectedGuest && (
        <div
          ref={containerResizeProps.ref}
          className={clsx(classes.inputSection, {
            [classes.emailView]: selectedChannel === 2,
          })}
        >
          {allListingsDisabled && !readOnly && (
            <div className={classes.disabledMessageBox}>
              All listings associated with this guests bookings are disabled.
              Please enable at least one listing to send messages
            </div>
          )}
          {!!scheduledPanelOpen ? (
            <ScheduledMessagePanel
              messages={scheduledMessages}
              guestId={selectedGuestId}
              edit={scheduledPanelOpen === "edit"}
              replaceFields={replaceTags}
              onCreate={() => setScheduledPanelOpen((prev) => "edit")}
              onClose={() => setScheduledPanelOpen((prev) => false)}
              templates={templates}
              whatsappAccountId={whatsappAccountId}
              selectedTemplateId={selectedTemplateId}
              setSelectedTemplateId={setSelectedTemplateId}
              airbnbHidden={airbnbHidden}
            />
          ) : (
            !readOnly && (
              <>
                <SendInput
                  latestMessage={
                    userOnlyMsgs.length > 0 &&
                    userOnlyMsgs[userOnlyMsgs.length - 1]
                  }
                  slc_channel={selectedChannel}
                  current_user={current_user}
                  user_profile={user_profile}
                  guest_email={guestEmail}
                  guest={selectedGuest}
                  subject={emailSubject}
                  setMessageBodyHook={(b) => setBody(b)}
                  AItext={AItext}
                  onMessageSent={onMessageSent}
                  onMessageError={onMessageError}
                  clearAIText={() => setAItext((prev) => "")}
                  openEmailEditor={() => setSelectedChannel(2)}
                  hasEmailChannel={!!selectedGuest.email_destination}
                  replaceFields={replaceTags}
                  resetSelectedChannel={resetSelectedChannel}
                  hideChatbot={true}
                  templates={templates}
                  whatsappAccountId={whatsappAccountId}
                  selectedTemplateId={selectedTemplateId}
                  setSelectedTemplateId={setSelectedTemplateId}
                />
                <div className={classes.channelSection}>
                  <MessageSelector
                    slc_channel={selectedChannel}
                    selectChannel={handleChannelSelect}
                    email={!!selectedGuest.email_destination}
                    SMS={!SMSHidden && !!selectedGuest.phone}
                    airbnb={
                      !airbnbHidden &&
                      !!selectedGuest.channels?.includes("Airbnb")
                    }
                    vrbo={isChannelAvailable("VRBO", selectedGuest)}
                    homeaway={isChannelAvailable("Home Away", selectedGuest)}
                    bookingcom={isChannelAvailable(
                      "Booking.com",
                      selectedGuest,
                    )}
                    whatsapp={!!whatsappAccountId && !!selectedGuest.phone}
                    disabled={allListingsDisabled}
                  />
                  <CustomDialog
                    fullWidth
                    maxWidth="sm"
                    open={dialogOpen}
                    title="SMS not yet enabled"
                    content="In order to send sms messages through enso, you must enable it."
                    onClose={() => {
                      setDialogOpen(false);
                      setSelectedChannel(getInitialChannel());
                    }}
                    labelConfirm="Learn more"
                    labelCancel="Hide icon"
                    actionCancel={() => {
                      setSelectedChannel(getInitialChannel());
                      setSMSHidden(true);
                      setDialogOpen(false);
                    }}
                    actionConfirm={() => {
                      history.push("/admin/settings/messaging");
                    }}
                  />
                  {!loadingNotifs && !!displaySchMsgs && (
                    <div
                      className={clsx(classes.scheduleRow, {
                        [classes.scheduleRowMobile]: !!isMobileView,
                      })}
                    >
                      {hasAvailableChannels && !isMobileView && (
                        <EnsoAIButton
                          handleGeneratedText={(text) =>
                            setAItext((prev) => text)
                          }
                          extra_context={body}
                          iconOnly={useIconsOnly}
                          type={contentTypeEnum.refine_chat}
                          guest={selectedGuest}
                          listing={houses_dict[selectedGuest?.listings?.[0]]}
                          latest_message={
                            userOnlyMsgs.length > 0 &&
                            userOnlyMsgs[userOnlyMsgs.length - 1]
                          }
                        />
                      )}
                      {hasAvailableChannels && !isMobileView && (
                        <PrimaryButton
                          id="schedule-message-btn"
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            resetSelectedChannel();
                            setScheduledPanelOpen((prev) => "edit");
                          }}
                          startIcon={
                            useIconsOnly ? (
                              <SvgIcon
                                component={CalendarIcon}
                                viewBox="0 0 16 16"
                              />
                            ) : null
                          }
                          classes={{
                            startIcon: classes.startIcon,
                            outlined: classes.btnLabel,
                          }}
                          label={useIconsOnly ? "" : "Schedule message"}
                        />
                      )}
                      {(!!scheduledMessages.length ||
                        (hasAvailableChannels && !!isMobileView)) &&
                        (useIconsOnly ? (
                          <Button
                            size="small"
                            id="scheduled-messages-btn"
                            disableRipple
                            disableElevation
                            startIcon={
                              <SvgIcon
                                component={CalendarIcon}
                                viewBox="0 0 16 16"
                                htmlColor="#FFF"
                              />
                            }
                            variant={"contained"}
                            color={"secondary"}
                            className={classes.filledBtn}
                            onClick={() =>
                              setScheduledPanelOpen((prev) => true)
                            }
                          >
                            {schMsgsGroupsCount}
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            id="scheduled-messages-btn"
                            disableRipple
                            disableElevation
                            className={classes.scheduleBtn}
                            onClick={() =>
                              setScheduledPanelOpen((prev) => true)
                            }
                          >
                            <Badge
                              max={99}
                              overlap="rectangular"
                              color="secondary"
                              classes={{badge: classes.badge}}
                              badgeContent={schMsgsGroupsCount}
                            />
                            {"Scheduled"}
                          </Button>
                        ))}
                    </div>
                  )}
                </div>
              </>
            )
          )}
        </div>
      )}
    </div>
  );
}

export default withLDConsumer()(MessagesPanel);
