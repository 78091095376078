import React from "react";
import {Typography, makeStyles} from "@material-ui/core";
import {gradientRgb} from "utilities/helperFunctions";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    maxHeight: 230,
    minHeight: 190,
    background: (props) =>
      `linear-gradient(180deg, rgba(255,255,255,0) 30%, ${gradientRgb(theme.palette.primary.contrast)} 95%), url("${props.img}") no-repeat center center / cover`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    width: "100%",
    alignItems: "center",
  },
  content: {
    width: "100%",
    maxWidth: 800,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 12,
  },
  title: {
    padding: theme.spacing(0, 2.5),
    "&.empty": {opacity: 0.5},
  },
}));

const SectionHeaderCard = ({
  img,
  title,
  actions,
  titleRef,
  disableGrayscale,
  placeholder = "",
}) => {
  const classes = useStyles({img, disableGrayscale});

  return (
    <div className={classes.container}>
      <div className={classes.content} ref={titleRef}>
        <Typography
          variant="h3"
          component={"div"}
          className={clsx(classes.title, {empty: !title})}
        >
          {!!title ? title : placeholder}
        </Typography>
        {!!actions && actions}
      </div>
    </div>
  );
};

export default SectionHeaderCard;
