import React from "react";
import {IconButton, makeStyles} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.primary.contrast,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-end",
    maxWidth: 800,
    width: "100%",
    overflow: "hidden",
  },
  content: {
    flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  closeBtn: {
    backgroundColor: `${theme.palette.primary.contrast + "a6"} !important`,
    padding: theme.spacing(1.5) + "!important",
    position: "absolute !important",
    top: theme.spacing(2.5),
    right: theme.spacing(0.5),
    width: "fit-content",
    zIndex: 2,
  },
  icon: {
    fontSize: "24px !important",
    color: theme.palette.primary.main,
  },
  stepInfoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  actionsSection: {
    padding: theme.spacing(2.5),
  },
}));

export default function CheckStepPage({
  disableActions,
  modal = null,
  actionPanel = null,
  onClose,
  contentRef = null,
  handleContentScroll = () => {},
  pageContent = null,
}) {
  const classes = useStyles();
  const actionsSectionRef = React.useRef(null);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {modal}
        <div
          ref={contentRef}
          onScroll={handleContentScroll}
          className={classes.content}
        >
          {!!onClose && (
            <IconButton onClick={onClose} className={classes.closeBtn}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          )}
          <div className={classes.stepInfoContainer}>{pageContent}</div>
        </div>
        {!disableActions && (
          <div ref={actionsSectionRef} className={classes.actionsSection}>
            {actionPanel}
          </div>
        )}
      </div>
    </div>
  );
}
