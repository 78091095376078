import React from "react";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";
// UI
import {Box, CardActionArea, Typography} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import useBookingHeaderPanelStyles from "styles/useBookingHeaderPanelStyles";
// Custom
import BookingChannelButton from "components/Misc/BookingChannelButton";
import Status from "components/TextFields/Status";
import InfoText from "components/Misc/InfoText";
import TagList from "components/Lists/TagList";
import Payment from "./Payment";
// Utils
import {PaymentStatusEnum} from "configuration/enums";
import infoTexts from "assets/infoTexts";
import {format} from "date-fns";
import clsx from "clsx";
import {getCurrencyFormat} from "../../../utilities/currencyUtil";

function ClickableContainer({onClick, title = null, children}) {
  return (
    <CardActionArea onClick={onClick} title={title} disableRipple>
      {children}
    </CardActionArea>
  );
}

const BookingHeaderPanel = ({booking, guest, openListingDetails}) => {
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  let house = houses[booking.listing_id];
  let houseName = house.nickname || house.name || "";
  let selectedGroup = listingGroups[house.group_id] ?? null;
  let tags = !!booking.tags?.length
    ? booking.tags.filter((t) => !["Verified", "Revoked"].includes(t))
    : [];
  const classes = useBookingHeaderPanelStyles({
    picture: house.picture,
    disabled: !house.active,
  });
  const bookingCheckTimes = React.useMemo(() => {
    const {
      checkin_date,
      checkin_time,
      checkout_date,
      checkout_time,
      checkin_raw,
      checkout_raw,
    } = booking;
    const newCheckTimes = {
      checkinTime: null,
      checkoutTime: null,
      isListingTimezone: false,
    };
    if (!!checkin_date || !!checkout_date) {
      if (!!checkin_date) {
        newCheckTimes.checkinTime = format(
          new Date(`${checkin_date} ${checkin_time || ""}`),
          "MMM d y, h:mm a",
        );
      }
      if (!!checkout_date) {
        newCheckTimes.checkoutTime = format(
          new Date(`${checkout_date} ${checkout_time || ""}`),
          "MMM d y, h:mm a",
        );
      }
      newCheckTimes.isListingTimezone = true;
    } else if (!!checkin_raw || !!checkout_raw) {
      if (!!checkin_raw) {
        newCheckTimes.checkinTime = format(checkin_raw, "MMM d y, h:mm a");
      }
      if (!!checkout_raw) {
        newCheckTimes.checkoutTime = format(checkout_raw, "MMM d y, h:mm a");
      }
    }
    return newCheckTimes;
  }, [booking]);

  const handleListingClick = () => {
    openListingDetails();
  };

  const getOccupants = () => {
    if (!booking.num_adult) {
      return null;
    }
    const adults = `Adults (${booking.num_adult})`;
    const children = !!booking.num_child
      ? `${!!booking.num_pets ? "," : "&"} Children (${booking.num_child})`
      : "";
    const pets = !!booking.num_pets ? `& Pets (${booking.num_pets})` : "";
    return (
      <div className={classes.row}>
        <Typography className={classes.white}>
          {adults} {children} {pets}
        </Typography>
      </div>
    );
  };
  const currencyFormat = getCurrencyFormat(booking.payment?.currency);
  const prefix = currencyFormat.prefix;
  const suffix = currencyFormat.suffix;

  return (
    <div className={clsx(classes.container, classes.column, classes.gap8)}>
      <div className={clsx(classes.row, classes.gap8)}>
        <Box flex={1}>
          <ClickableContainer onClick={handleListingClick} title={houseName}>
            <div className={classes.column}>
              <Typography
                variant="h1"
                className={clsx(classes.title, classes.white)}
              >
                {houseName}
              </Typography>
              <Typography variant="caption" className={classes.group}>
                {selectedGroup?.name ?? "No listing group"}
              </Typography>
            </div>
          </ClickableContainer>
        </Box>
        <div className={clsx(classes.row, classes.gap8, classes.buttons)}>
          <BookingChannelButton
            channel={booking.channel}
            PMSBkgId={booking.integration_booking_id}
          />
          <Status
            square
            status={booking?.status || "inquiry"}
            type="booking"
            disableTooltip
          />
        </div>
      </div>
      <div className={classes.row}>
        {(!!bookingCheckTimes.checkinTime ||
          !!bookingCheckTimes.checkoutTime) && (
          <Typography
            className={clsx(classes.row, classes.alignCenter, classes.white)}
          >
            {bookingCheckTimes.checkinTime ?? ""}
            {bookingCheckTimes.checkoutTime && (
              <>
                <ArrowForwardIcon className={classes.arrowIcon} />
                {bookingCheckTimes.checkoutTime}
              </>
            )}
            {bookingCheckTimes.isListingTimezone && (
              <InfoText dark text={infoTexts.listingtimezone} />
            )}
          </Typography>
        )}
      </div>
      {getOccupants()}
      <Typography
        component="div"
        className={clsx(classes.row, classes.white, classes.alignCenter)}
      >
        <NumberFormat
          value={booking.payment?.total_guest_paid ?? (booking.total_paid || 0)}
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator
          displayType="text"
          prefix={prefix}
          suffix={suffix}
        />
        {!!booking?.payment?.status && (
          <Box ml={1}>
            <Typography>
              {`- ${PaymentStatusEnum[booking?.payment?.status]}`}
            </Typography>
          </Box>
        )}
        {!!booking.payment && (
          <InfoText
            dark
            interactive
            showCustomContent
            text={<Payment booking={booking} metadata={guest?.metadata} />}
          />
        )}
      </Typography>
      {!!tags.length && (
        <div className={classes.tagsRow}>
          <TagList disabled tags={tags} light />
        </div>
      )}
    </div>
  );
};

export default BookingHeaderPanel;
