import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Button,
  Drawer,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
// Custom
import Action from "components/Panels/Experience/ExperienceContent/Actions/Action";
import DateTimePicker from "core/inputs/DateTimePicker";
import CustomCardHeader from "core/cards/CustomCardHeader";
import PrimaryButton from "core/buttons/PrimaryButton";
import ErrorPanel from "core/alerts/ErrorPanel";
// Actions
import {
  cancelAction,
  triggerAction,
  updateAction,
} from "redux/actions/experiencesActions";
import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
} from "redux/actions/settingsActions";
import {
  deleteNotification,
  getNotifications,
} from "redux/actions/notificationsActions";
// Utilities
import {formatScheduledMessageTitle} from "utilities/formatUtilities";
import {format} from "date-fns";
import _ from "lodash";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    width: "38%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  header: {paddingTop: theme.spacing(4)},
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  cancelBtn: {color: theme.palette.grey[500]},
  cancelBtnOutlined: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginRight: theme.spacing(4),
  },
  padding: {padding: theme.spacing(2, 4)},
  field: {padding: theme.spacing(2, 0, 4)},
  iconBtn: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  icon: {
    fontSize: 18,
    color: theme.palette.action.disabled,
  },
}));

export default function NotificationPanel({
  selectedNotification,
  guestId,
  setLoading,
  onClose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const [data, setData] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  const [validDate, setValidDate] = React.useState(true);
  const [timeEdit, setTimeEdit] = React.useState(false);
  const allowSendNow = React.useMemo(
    () => data.some((d) => !!d.meets_conditions),
    [data],
  );
  const errors = React.useMemo(() => {
    const newErrors = [];
    _.each(data, (d) => newErrors.push(...(d.errors || [])));
    return newErrors;
  }, [data]);

  React.useEffect(() => {
    resetData();
  }, [selectedNotification]);

  const handleSuccessAlertClose = () => dispatch(closeGeneralSuccessAlert());

  const onSaveSuccess = (type, index, isLastIndex, disableClose) => () => {
    if (!disableClose) {
      onClose();
    }
    if (isLastIndex) {
      dispatch(
        openGeneralSuccessAlert({
          open: true,
          message: `Scheduled experience ${type}`,
          onClose: handleSuccessAlertClose,
        }),
      );
      refreshNotifications();
      setLoading(null);
    }
    if (type === "cancelled") {
      dispatch(deleteNotification(selectedNotification.data[index]));
    }
  };

  const refreshNotifications = () => {
    const params = {enso_key: current_user, guest_id: guestId};
    dispatch(getNotifications(qs.stringify(params), true));
  };

  const resetData = () => {
    setData((prev) => selectedNotification?.data || []);
    setDate((prev) =>
      !!selectedNotification
        ? new Date(
            selectedNotification.data[0].sent ??
              selectedNotification.data[0].send_time,
          )
        : new Date(),
    );
    setTimeEdit((prev) => false);
  };

  const handleClose = () => {
    onClose();
    resetData();
  };

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    )
      return;
    handleClose();
  };

  const handleCancelAction = () => {
    setLoading("Cancelling scheduled message...");
    _.each(data, (d, i) => {
      const isLastIndex = i === data.length - 1;
      dispatch(
        cancelAction(d.id, onSaveSuccess("cancelled", i, isLastIndex), () =>
          !isLastIndex ? null : setLoading(null),
        ),
      );
    });
  };

  const handleSendNow = () => {
    setLoading("Sending scheduled message...");
    _.each(data, (d, i) => {
      const isLastIndex = i === data.length - 1;
      dispatch(
        triggerAction(d.id, onSaveSuccess("sent", i, isLastIndex), () =>
          !isLastIndex ? null : setLoading(null),
        ),
      );
    });
  };

  const handleDeleteAction = (index) => (actionId) => {
    setLoading("Cancelling scheduled message...");
    const actionToDelete = data[index];
    setData((prev) =>
      prev.filter(
        (action, ind) => ind !== index && action.action.action_id !== actionId,
      ),
    );
    dispatch(
      cancelAction(
        actionToDelete.id,
        onSaveSuccess("cancelled", index, true, data.length > 1),
        () => setLoading(null),
      ),
    );
  };

  const handleActionChange = (index) => (newAction) => {
    const newActionData = [...data];
    newActionData[index] = {
      ...data[index],
      action: {...data[index].action, ...newAction[0]},
    };
    setData((prev) => newActionData);
    dispatch(
      updateAction(data[index].id, date.getTime(), newActionData[index].action),
    );
  };

  const handleSaveTime = () => {
    const newData = data.map((d) => ({...d, sent: date.getTime()}));
    setData((prev) => newData);
    setTimeEdit((prev) => false);
    _.each(data, (d) => {
      dispatch(updateAction(d.id, date.getTime(), d.action));
    });
  };

  const handleCancelTimeEdit = () => {
    setTimeEdit((prev) => false);
    setDate((prev) => new Date(data[0].sent || data[0].send_time));
  };

  const onTimeEdit = () => setTimeEdit((prev) => true);

  const timePicker = (
    <>
      <DateTimePicker
        value={date}
        onChange={setDate}
        onInvalidChange={(isInvalid) => setValidDate((prev) => !isInvalid)}
      />
      <div className={classes.actions}>
        <Button
          className="mr-2"
          classes={{label: classes.cancelBtn}}
          size="small"
          onClick={handleCancelTimeEdit}
        >
          Cancel
        </Button>
        <PrimaryButton
          size="small"
          label="Done"
          className="mr-2"
          onClick={handleSaveTime}
        />
      </div>
    </>
  );

  return (
    <>
      <Drawer
        anchor="right"
        disableEnforceFocus
        open={!!data.length}
        onClose={toggleDrawer}
        classes={{paper: classes.root}}
      >
        <CustomCardHeader
          title="Notification Details"
          type="title"
          className={classes.header}
          action={
            <Tooltip title="Close">
              <IconButton onClick={handleClose} id={"btn-close-notif"}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
        />
        <div className={classes.padding}>
          <Button
            variant="outlined"
            disableElevation
            size="small"
            className={classes.cancelBtnOutlined}
            endIcon={<CancelScheduleSendIcon />}
            onClick={handleCancelAction}
            id={"btn-cancel-exp"}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            size="small"
            disabled={!allowSendNow}
            endIcon={<SendIcon />}
            onClick={handleSendNow}
            id={"btn-trigger-exp"}
          >
            Send Now
          </Button>
        </div>
        {!!data.length && (
          <div className={classes.padding}>
            {errors.map((err, i) => (
              <ErrorPanel key={`${err.title}-${i}`} message={err.message} />
            ))}
            <div className={classes.field}>
              <Typography style={{fontWeight: 500}} className="mb-2">
                Message
              </Typography>
              <Typography id={"notif-message"}>
                {formatScheduledMessageTitle(data[0].message)}
              </Typography>
            </div>
            <div className={classes.field}>
              <Typography style={{fontWeight: 500}}>Send Time</Typography>
              {timeEdit ? (
                timePicker
              ) : (
                <>
                  <div className={classes.row}>
                    <Typography id={"notif-time"}>
                      {format(data[0].sent || data[0].send_time, "MMM d, y")} at{" "}
                      {format(data[0].sent || data[0].send_time, "h:mmaaa")}
                    </Typography>
                    {!!data[0].action && data[0].status === "pending" && (
                      <IconButton
                        className={classes.iconBtn}
                        onClick={onTimeEdit}
                        id={"notif-edit-time"}
                      >
                        <EditIcon className={classes.icon} />
                      </IconButton>
                    )}
                  </div>
                </>
              )}
            </div>
            <Typography style={{fontWeight: 500}}>Actions</Typography>
            {data.map(
              (d, i) =>
                !!d.action && (
                  <div key={d.id} className={classes.field}>
                    <Action
                      disableDelete={!d.meets_conditions}
                      trigger={d.trigger?.trigger}
                      data={[d.action]}
                      hideEdit={d.status !== "pending" || !d.meets_conditions}
                      variant="default"
                      handleDelete={handleDeleteAction(i)}
                      setData={handleActionChange(i)}
                      removeNewService={() => null}
                      setActionEdit={() => null}
                    />
                  </div>
                ),
            )}
          </div>
        )}
      </Drawer>
    </>
  );
}
