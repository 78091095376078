import React from "react";
import {Redirect} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
// UI
import {Box, SvgIcon, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as EnsoIcon} from "assets/icons/enso_connect.svg";
import {ReactComponent as AIIcon} from "assets/icons/ai.svg";
import {ReactComponent as AutohostIcon} from "assets/icons/Autohost_Icon.svg";
import {ReactComponent as SuperhogIcon} from "assets/icons/Superhog_Icon.svg";
import {ReactComponent as ChekinIcon} from "assets/icons/Chekin_Icon.svg";
import {ReactComponent as SeamIcon} from "assets/icons/Seam_Icon.svg";
// Custom
import CustomDialog from "core/dialogs/CustomDialog";
import HTMLField from "components/TextFields/HTMLField";
import PaymentModal from "./PaymentModal";
// Utils
import {THEME} from "configuration/settings";
import clsx from "clsx";
import {getSubscriptionDetails} from "redux/actions/servicesActions";

const useStyles = makeStyles((theme) => ({
  AIIcon: {
    margin: 0,
    color: THEME.AI.color,
    "&.light": {color: "#FFF"},
  },
  dialogHeader: {
    background: (props) =>
      `url(${props.dialogBGImage}), lightgray no-repeat linear-gradient(0deg, ${props.dialogBGColors?.bg1 ?? "#FFF"} 0.26%, ${props.dialogBGColors?.bg2 ?? "#FFF"} 99.71%)`,
    backgroundPosition: "50% !important",
    backgroundSize: "cover !important",
    backgroundBlendMode: "multiply, normal",
    "&.ai": {
      background: (props) =>
        `url(${props.dialogBGImage}), lightgray no-repeat linear-gradient(180deg, ${props.dialogBGColors?.bg1 ?? "#FFF"} 0%, ${props.dialogBGColors?.bg2 ?? "#FFF"} 100%)`,
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
  },
  section: {whiteSpace: "pre-wrap"},
  bold: {fontWeight: 500},
  bolder: {fontWeight: 700},
  row: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(5),
  },
  column: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  ensoIconContainer: {
    display: "flex",
    flexDirection: "column",
    width: 240,
    padding: theme.spacing(3, 5),
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1),
    border: "1.5px solid #E5E5EA",
    background: "#FFF",
    borderRadius: 10,
    height: "fit-content",
  },
  includedServicesRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(3),
  },
  includedService: {
    background: "#F8F8F9",
    padding: theme.spacing(3),
    borderRadius: 5,
    minWidth: "fit-content",
  },
  costRow: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    padding: theme.spacing(3, 4),
    alignItems: "center",
    gap: theme.spacing(2),
    alignSelf: "stretch",
    background: "#F8F8F9",
    borderRadius: 5,
    width: "100%",
  },
  partnerLogo: {
    width: "100%",
    "&.light": {color: "#FFF"},
    color: theme.palette.text.primary,
  },
  imgIcon: {
    height: 60,
    width: "auto",
    objectFit: "contain",
    maxWidth: "100%",
  },
}));

export default function ServiceInfoContent({
  open,
  onClose,
  loading,
  service,
  headerIcon = null,
  dialogBGImage,
  dialogBGColors,
  handleEnable = () => {},
  btnLabel = "Enable",
  btnDisabled,
  contentOnly,
}) {
  const classes = useStyles({dialogBGImage, dialogBGColors});
  const dispatch = useDispatch();
  const [billingInfo, setBillingInfo] = React.useState(null);
  const [openBillingModal, setOpenBillingModal] = React.useState(false);
  const [allowRedirect, setAllowRedirect] = React.useState(false);
  const user_profile = useSelector(
    (state) => state.defaultReducer.user_profile,
  );
  const loadingBilling = useSelector(
    (state) => state.defaultReducer.loading,
  ).subscription;
  let redirect = open && user_profile.plan !== "paid";

  React.useEffect(() => {
    if (!!service && !billingInfo && !!open) {
      dispatch(
        getSubscriptionDetails(service["Subscription ID"], (res) =>
          setBillingInfo((prev) => res),
        ),
      );
    }
  }, [service, open]);

  React.useEffect(() => {
    if (!!redirect && !!open) {
      setOpenBillingModal((prev) => true);
    }
  }, [open]);

  function getHeaderIcon(prov) {
    let icon = null;
    let viewBox = "";
    let fontSize = 0;
    switch (prov) {
      case "autohost":
        icon = AutohostIcon;
        viewBox = "0 0 120 17";
        fontSize = 40;
        break;
      case "superhog":
        icon = SuperhogIcon;
        viewBox = "0 0 71 27";
        fontSize = 60;
        break;
      case "chekin":
        icon = ChekinIcon;
        viewBox = "0 0 71 27";
        fontSize = 60;
        break;
      case "enso":
        icon = EnsoIcon;
        viewBox = "0 0 110 46";
        fontSize = 40;
        break;
      case "seam":
        icon = SeamIcon;
        viewBox = "0 0 734 194";
        fontSize = 40;
        break;
      case "twilio":
        return <img className={classes.imgIcon} src={headerIcon} />;

      default:
        return null;
    }
    return (
      <SvgIcon
        component={icon}
        viewBox={viewBox}
        style={{fontSize}}
        className={clsx(classes.partnerLogo, "light")}
      />
    );
  }

  function getDialogContent() {
    return (
      <div className={classes.dialogContent}>
        <div className={classes.section}>
          <div className={classes.row}>
            <div className={classes.column}>
              <Box mb={2}>
                <Typography variant="h1">Details</Typography>
              </Box>
              <HTMLField inline content={service["Details"]} multiline />
            </div>
            <div className={classes.ensoIconContainer}>
              <Typography variant="subtitle1" style={{fontWeight: 400}}>
                {"Provided by"}
              </Typography>
              {service["Provider"] === "enso" ? (
                <SvgIcon
                  component={EnsoIcon}
                  viewBox="0 0 110 46"
                  style={{fontSize: 40}}
                  className={classes.partnerLogo}
                />
              ) : (
                <img className={classes.imgIcon} src={headerIcon} />
              )}
            </div>
          </div>
        </div>
        {!!service["Includes"] && (
          <div className={classes.section}>
            <Box mb={2}>
              <Typography variant="h1">Includes</Typography>
            </Box>
            <div className={classes.includedServicesRow}>
              {service["Includes"]?.map((s) => (
                <Typography
                  key={s.header}
                  variant="subtitle2"
                  component="div"
                  className={classes.includedService}
                >
                  {s.header}
                  {!!s.subheader && (
                    <Typography style={{maxWidth: 280}}>
                      {s.subheader}
                    </Typography>
                  )}
                </Typography>
              ))}
            </div>
          </div>
        )}
        <div className={classes.section}>
          <Box mb={2}>
            <Typography variant="h1">How Does it Work?</Typography>
          </Box>
          <Typography>{service["How it works"]}</Typography>
        </div>
        {(!!billingInfo || loadingBilling) && (
          <div className={classes.section}>
            <Box mb={2}>
              <Typography variant="h1">Cost</Typography>
            </Box>
            {!!loadingBilling ? (
              <Skeleton
                animation="wave"
                height={44}
                width={"100%"}
                style={{transform: "none"}}
              />
            ) : (
              <div className={classes.costRow}>
                <Typography style={{flex: 1}}>
                  {"Price per "}
                  {billingInfo?.unit}
                </Typography>
                <Typography className={classes.bolder}>
                  {billingInfo?.properties?.current_tier_unit_value}
                </Typography>
                <Typography variant="caption">
                  {"billed "}
                  {billingInfo?.interval}
                  {"ly"}
                </Typography>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  if (!!allowRedirect) {
    return <Redirect push to="/admin/settings/billing/:redirect" />;
  } else if (!!redirect) {
    if (user_profile?.scopes?.accounts === "hide") {
      return <p>Please contact an admin to access billing information.</p>;
    } else {
      return (
        <PaymentModal
          outsideOpen={openBillingModal}
          setOutsideOpen={setBillingInfo}
          onClose={onClose}
          confirmAction={() => setAllowRedirect((prev) => true)}
        />
      );
    }
  }

  if (contentOnly) {
    return getDialogContent();
  }
  return (
    <CustomDialog
      open={open}
      fullWidth
      headerLight
      maxWidth="sm"
      headerBGImage
      titleVariant="header"
      loadingAction={!!loading || loadingBilling}
      title={service["Name"]}
      headerClass={clsx(classes.dialogHeader, {
        ai: service["Provider"] === "enso",
      })}
      content={getDialogContent()}
      onClose={onClose}
      labelConfirm={btnLabel}
      actionConfirm={handleEnable}
      disableConfirm={!!loading || btnDisabled || loadingBilling}
      labelCancel={"Cancel"}
      actionCancel={onClose}
    />
  );
}
