import {capitalize} from "utilities/helperFunctions";
import * as types from "../actionTypes";
import * as API from "../api/listingsAPI";
import {addBooking} from "./bookingsActions";
import {addListingExperiences} from "./experiencesActions";
import {
  closeGeneralSuccessAlert,
  openGeneralSuccessAlert,
  setApiError,
  setApiStart,
  setApiSuccess,
  setStartKeys,
  setTotals,
  errorResponseAlert,
} from "./settingsActions";

export function setHouses(data) {
  return {type: types.SET_HOUSES, house_data: data};
}
export function addHouse(data) {
  return {type: types.ADD_HOUSE, house_data: data};
}
export function changeHouse(listing_id) {
  return {type: types.CHANGE_HOUSE, house: listing_id};
}
export function editHouse(house) {
  return {type: types.EDIT_HOUSE, house: house};
}
export function removeHouse(house_id) {
  return {type: types.DELETE_HOUSE, house_id};
}
export function addListingGroups(data) {
  return {type: types.ADD_LISTING_GROUP, listing_groups: data};
}
export function editListingGroup(listing_group) {
  return {type: types.EDIT_LISTING_GROUP, listing_group};
}
export function removeListingGroup(group_id) {
  return {type: types.DELETE_LISTING_GROUP, group_id};
}
export function setSelectedListingGroups(listing_groups) {
  return {type: types.SET_SELECTED_LISTING_GROUPS, listing_groups};
}

/**
 *
 * @param {string} params Params for the API call
 * @param {boolean | undefined} add True to add the results to the existing houses; otherwise, replace existing houses.
 * @param {Function | undefined} onSuccess A callback when API call succeeds
 * @param {Function | undefined} onError A callback when there's an error with the API call
 */

export function getListingResourcePresets(location, onSuccess) {
  return async (dispatch, getState) => {
    try {
      let params = `locations=${encodeURIComponent(JSON.stringify(location))}`;
      const presets = await API.getListingResourcePresets(params);
      onSuccess(presets.presets);
    } catch (error) {
      console.log("ERROR - GET LISTING RESOURCE PRESETS", error);
      errorResponseAlert(dispatch, null, null, error);
    }
  };
}

export function getHouses(params, add, onSuccess, onError) {
  return (dispatch) => {
    dispatch(setApiStart("listings"));
    API.getHouses(params)
      .then((response) => {
        let hits = !response.hits ? response : response.hits;
        const total = response.count ?? "";
        if (add) {
          dispatch(addHouse(hits));
        } else {
          dispatch(setHouses(hits));
          dispatch(setTotals("listings", total));
          dispatch(setStartKeys("listings", response.start_key));
        }
        onSuccess && onSuccess(response);
        dispatch(setApiSuccess("listings"));
      })
      .catch((err) => {
        console.log("ERROR - GET HOUSES", err);
        onError && onError();
        dispatch(setApiError("listings"));

        errorResponseAlert(dispatch, null, null, err);
      });
  };
}

// TO BE DEPRECATED
export function updateHouse(body, house, onSuccess, onError) {
  return (dispatch) => {
    API.patchHouse({body: body})
      .then((response) => {
        if (response) {
          if (response === 500) {
            console.log("ERROR - UPDATE HOUSE", response);
            onError && onError();
            return;
          }
          let new_house = {...house};
          if (response.listing_ranges)
            new_house.listing_ranges = response.listing_ranges;
          if (response.listing)
            new_house = Object.assign(new_house, response.listing);
          if (response.listing_content)
            new_house = {
              ...new_house,
              listing_ranges: response.listing_ranges,
              listing_content: response.listing_content,
              chatbot_props: response.listing_content[1],
            };
          dispatch(editHouse(new_house));
        }
        onSuccess && onSuccess(response);
      })
      .catch((err) => {
        console.log("ERROR - UPDATE HOUSE", err);
        onError && onError();

        errorResponseAlert(dispatch, null, null, err);
      });
  };
}

export function updateListing({
  body,
  listing,
  disableListingUpdate = false,
  option = "listing",
  onSuccess = () => null,
  onError = () => null,
}) {
  return async (dispatch) => {
    const payload = {update: body};
    if (option === "all") {
      payload.group_id = "ALL";
    } else if (!!option && option !== "listing") {
      payload.group_id = option;
    } else if (option !== null) {
      payload.listing_id = listing.listing_id;
    }

    try {
      if (!disableListingUpdate) {
        const previewListing = getUpdatedListing(listing, body);
        dispatch(editHouse(previewListing));
      }
      const response = await API.patchHouse({body: payload});
      if (response) {
        if (response === 500) {
          console.log("ERROR - UPDATE HOUSE", response);
          dispatch(editHouse(listing));
          errorResponseAlert(
            dispatch,
            "Failed to save changes",
            "Something went wrong, please try again later",
          );
          onError();
          return;
        }
        console.log("GOT UPDATED HOUSE ", response);
        let new_house = {...listing};
        if (response.listing_ranges)
          new_house.listing_ranges = response.listing_ranges;
        if (response.listing)
          new_house = Object.assign(new_house, response.listing);
        if (response.listing_content) {
          new_house = {
            ...new_house,
            listing_content: {
              ...new_house.listing_content,
              ...response.listing_content,
              guidebooks:
                response.guidebooks ?? listing.listing_content.guidebooks,
            },
          };
        }
        dispatch(editHouse(new_house));
        successResponseAlert(
          dispatch,
          "Changes saved",
          "Listing updated correctly",
        );
        onSuccess();
      }
    } catch (err) {
      console.log("ERROR - UPDATE HOUSE", err);
      errorResponseAlert(
        dispatch,
        "Failed to save changes",
        "Something went wrong, please try again later",
        err,
      );
      onError();
    }
  };
}

export function updateHouseRanges(body, house, onSuccess, onError) {
  return (dispatch) => {
    API.postHouseRanges({body: body})
      .then((response) => {
        if (response) {
          if (response === 500) {
            console.log("ERROR - UPDATE HOUSE RANGES", response);
            onError && onError();
            return;
          }
          let new_house = {...house};
          new_house.listing_ranges = response.listing_ranges;
          dispatch(editHouse(new_house));
        }
        onSuccess && onSuccess(response);
      })
      .catch((err) => {
        console.log("ERROR - UPDATE HOUSE RANGES", err);
        onError && onError();

        errorResponseAlert(dispatch, null, null, err);
      });
  };
}

export function createHouse(body, onSuccess, onError) {
  return (dispatch) => {
    API.postHouse({body: body})
      .then((response) => {
        if (response === 500 || response === 404) {
          onError && onError(response);
        } else {
          let new_house = {
            ...response.listing,
            listing_ranges: response.listing_ranges,
            listing_content: response.listing_content,
            chatbot_props: response.listing_chatbot,
            keycard_props: response.listing_keycard,
          };
          dispatch(addHouse(new_house));
          onSuccess && onSuccess(new_house);
        }
      })
      .catch((err) => {
        console.log("ERROR - CREATING LISTING", err);
        onError && onError(err);

        errorResponseAlert(dispatch, null, null, err);
      });
  };
}

export function deleteHouse(params, listing_id, onSuccess, onError) {
  return (dispatch) => {
    API.delHouse(params)
      .then((response) => {
        dispatch(removeHouse(listing_id));
        onSuccess && onSuccess();
      })
      .catch((err) => {
        console.log("ERROR - DELETE LISTING", err);
        onError && onError();

        let ensoError = err?.response?.data?.error?.[0];
        errorResponseAlert(dispatch, ensoError?.title, ensoError?.message);
      });
  };
}

// -------------- HOUSE CONTENT --------------
export function getHouseContent(listingId) {
  return async (dispatch, getState) => {
    dispatch(setApiStart("listing_content"));
    try {
      const response = await API.getHouseContent(`listing_id=${listingId}`);
      console.log("GOT HOUSE CONTENT ", response);
      const newHouse = {
        ...response.listing,
        listing_content: response.listing_content,
        listing_ranges: response.listing_ranges,
        listing_devices: response.devices,
        listing_experiences: response.listing_experiences,
      };

      if (!!response.guidebooks) {
        newHouse.listing_content.guidebooks = response.guidebooks;
      }

      if (!!response.listing_experiences?.length) {
        const newListingExperiences = {};
        response.listing_experiences.forEach((exp) => {
          if (!newListingExperiences[exp.experience_type]) {
            newListingExperiences[exp.experience_type] = [exp];
          } else {
            newListingExperiences[exp.experience_type].push(exp);
          }
        });
        newHouse.listing_experiences = newListingExperiences;
        dispatch(
          addListingExperiences(listingId, response.listing_experiences),
        );
      }
      if (!!response.bookings?.length) {
        dispatch(addBooking(response.bookings));
      }

      const houseExists =
        !!getState().defaultReducer.house_data_dict[listingId];
      dispatch(houseExists ? editHouse(newHouse) : addHouse(newHouse));
      dispatch(setApiSuccess("listing_content"));
    } catch (err) {
      console.log("ERROR - GET HOUSE CONTENT", err);
      dispatch(setApiError("listing_content"));

      errorResponseAlert(dispatch, null, null, err);
    }
  };
}

// -------------- HOUSE GROUPS --------------
export function getListingGroups() {
  return async (dispatch) => {
    dispatch(setApiStart("listing_groups"));
    try {
      const response = await API.getHouseGroups();
      if (!response.groups.some((g) => g.group_id === "all")) {
        response.groups.unshift({group_id: "ALL", name: "All listings"});
      }
      dispatch(addListingGroups(response.groups));
      console.log("RESPONSE - GET LISTING GROUPS", response);
      dispatch(setApiSuccess("listing_groups"));
    } catch (err) {
      console.log("ERROR - GET LISTING GROUPS", err);
      dispatch(setApiError("listing_groups"));

      errorResponseAlert(dispatch, null, null, err);
    }
  };
}

export function getListingGroupDetails({
  groupId,
  section,
  card,
  ignoreData,
  onSuccess = () => {},
}) {
  return async (dispatch, getState) => {
    try {
      dispatch(setApiStart("listing_group_details"));
      const selectedGroup =
        getState().defaultReducer.listing_groups_dict[groupId];
      const response = await API.getHouseGroup(groupId, section, card);
      dispatch(setApiSuccess("listing_group_details"));
      const newListingGroup = {...selectedGroup};
      if (!!response.sections && !!section && !!card) {
        if (!!selectedGroup.guest_journey) {
          newListingGroup.guest_journey.enabled = response.enabled;
          newListingGroup.guest_journey.progress[`${section}_${card}`] =
            response.progress[`${section}_${card}`];
          newListingGroup.section_progress =
            newListingGroup.guest_journey.progress;
          let newCardSection = {};
          if (!!ignoreData) {
            newCardSection = !!response.sections[section]?.cards[card]
              ? {
                  ...response.sections[section].cards[card],
                  data: selectedGroup.guest_journey.sections[section].cards[
                    card
                  ].data,
                }
              : selectedGroup.guest_journey.sections[section]?.cards[card];
          } else {
            newCardSection = !!response.sections[section]?.cards[card]
              ? {...response.sections[section].cards[card]}
              : selectedGroup.guest_journey.sections[section]?.cards[card];
          }
          newListingGroup.guest_journey.sections[section].cards[card] =
            newCardSection;
        } else {
          newListingGroup.guest_journey = {...response};
        }
        dispatch(editListingGroup(newListingGroup));
      } else if (!!response.sections) {
        newListingGroup.guest_journey = {...response};
        let currentCheckinSteps =
          selectedGroup.guest_journey?.sections?.ci?.cards?.checkin_steps
            ?.data || [];
        if (!!currentCheckinSteps.length) {
          newListingGroup.guest_journey.sections.ci.cards.checkin_steps.data =
            currentCheckinSteps;
        }
        dispatch(editListingGroup(newListingGroup));
      }
      onSuccess(response);
    } catch (err) {
      console.log("ERROR - GET LISTING GROUP DETAILS", err);
      dispatch(setApiError("listing_group_details"));

      errorResponseAlert(dispatch, null, null, err);
    }
  };
}

export function saveListingGroup({
  body,
  groupId,
  disableAlerts,
  onSuccess = () => null,
  onError = () => null,
  onUpdate,
}) {
  return async (dispatch, getState) => {
    const selectedGroup =
      getState().defaultReducer.listing_groups_dict[groupId];
    try {
      const response = await API.postHouseGroup({body}, groupId);
      console.log("RESPONSE - SAVE LISTING GROUP", response);
      if (!!response.success) {
        if (!!response.update_session?.payload && !!onUpdate) {
          onUpdate(response);
        } else if (!!response.listing_group) {
          const newGroup =
            !!selectedGroup && !!selectedGroup.guest_journey
              ? {
                  ...response.listing_group,
                  guest_journey: selectedGroup.guest_journey,
                }
              : response.listing_group;
          dispatch(
            !!groupId ? editListingGroup(newGroup) : addListingGroups(newGroup),
          );
          !disableAlerts &&
            successResponseAlert(
              dispatch,
              "Changes saved",
              `Listing group ${!!groupId ? "updated" : "created"} correctly`,
            );
          onSuccess(response);
        }
      } else if (!!response.sections) {
        const newGroup = {...selectedGroup, guest_journey: response};
        dispatch(editListingGroup(newGroup));
        onSuccess(response);
      } else {
        !disableAlerts &&
          errorResponseAlert(
            dispatch,
            "Failed to save listing group",
            "Something went wrong, please try again later",
          );
        onError();
      }
    } catch (err) {
      console.log("ERROR - SAVE LISTING GROUP", err);
      !disableAlerts &&
        errorResponseAlert(
          dispatch,
          "Failed to save listing group",
          "Something went wrong, please try again later",
          err,
        );
      onError();
    }
  };
}

export function deleteListingGroup({
  groupId,
  updateId,
  onSuccess = () => null,
  onError = () => null,
  onUpdate = () => null,
}) {
  return async (dispatch) => {
    try {
      const response = await API.delHouseGroup(groupId, updateId);
      console.log("RESPONSE - REMOVE LISTING GROUP", response);
      if (!!response.success) {
        if (!updateId) {
          onUpdate(response);
        } else {
          dispatch(removeListingGroup(groupId));
          successResponseAlert(dispatch, "Listing group deleted");
          onSuccess(response);
        }
      } else {
        errorResponseAlert(
          dispatch,
          "Failed to delete listing group",
          "Something went wrong, please try again later",
        );
        onError();
      }
    } catch (err) {
      console.log("ERROR - REMOVE LISTING GROUP", err);
      errorResponseAlert(
        dispatch,
        "Failed to delete listing group",
        "Something went wrong, please try again later",
        err,
      );
      onError();
    }
  };
}

// -------------- LISTING RESOURCES --------------
export function saveListingResource({
  body,
  type,
  disableListingUpdate,
  listing,
  onSuccess = () => null,
  onError = () => null,
}) {
  return async (dispatch) => {
    try {
      dispatch(setApiStart("listing_resource_action"));
      if (!disableListingUpdate) {
        dispatch(editHouse(listing));
      }
      const response = await API.postListingResource({body});
      console.log("RESPONSE - SAVE RESOURCES", response);
      successResponseAlert(dispatch, `${capitalize(type)} saved successfully`);
      dispatch(setApiSuccess("listing_resource_action"));
      onSuccess(response);
    } catch (err) {
      console.log("ERROR - SAVE RESOURCES", err);
      errorResponseAlert(
        dispatch,
        "Failed to save changes",
        "Something went wrong, please try again later",
        err,
      );
      dispatch(setApiError("listing_resource_action"));
      onError();
    }
  };
}

export function updateListingResource({
  body,
  disableListingUpdate,
  listing,
  onSuccess = () => null,
  onError = () => null,
}) {
  return async (dispatch) => {
    try {
      dispatch(setApiStart("listing_resource_action"));
      if (!disableListingUpdate) {
        dispatch(editHouse(listing));
      }
      const response = await API.patchListingResource({body});
      console.log("RESPONSE - UPDATE RESOURCES", response);
      successResponseAlert(dispatch, "Changes saved successfully");
      dispatch(setApiSuccess("listing_resource_action"));
      onSuccess(response);
    } catch (err) {
      console.log("ERROR - UPDATE RESOURCES", err);
      errorResponseAlert(
        dispatch,
        "Failed to save changes",
        "Something went wrong, please try again later",
        err,
      );
      dispatch(setApiError("listing_resource_action"));
      onError();
    }
  };
}

export function updateListingResourceOrder({
  body,
  newListing,
  onSuccess = () => null,
  onError = () => null,
}) {
  return async (dispatch) => {
    try {
      dispatch(setApiStart("listing_resource_action"));
      dispatch(editHouse(newListing));
      const response = await API.postListingResourceReorder({body});
      successResponseAlert(dispatch, "Changes saved successfully");
      dispatch(setApiSuccess("listing_resource_action"));
      onSuccess(response);
    } catch (err) {
      errorResponseAlert(
        dispatch,
        "Failed to save changes",
        "Something went wrong, please try again later",
        err,
      );
      dispatch(setApiError("listing_resource_action"));
      onError();
    }
  };
}

export function deleteListingResource({
  type,
  resource,
  disableListingUpdate,
  resource_id,
  listing,
  onSuccess = () => null,
  onError = () => null,
}) {
  return async (dispatch) => {
    try {
      dispatch(setApiStart("listing_resource_action"));
      if (!disableListingUpdate) {
        dispatch(editHouse(listing));
      }
      const response = await API.delListingResource(resource, resource_id);
      console.log("RESPONSE - DELETE RESOURCE", response);
      if (!!response.success) {
        successResponseAlert(
          dispatch,
          `${capitalize(type)} deleted successfully`,
        );
        dispatch(setApiSuccess("listing_resource_action"));
        onSuccess();
      } else {
        errorResponseAlert(
          dispatch,
          "Failed to delete",
          "Something went wrong, please try again later",
        );
        dispatch(setApiError("listing_resource_action"));
        onError();
      }
    } catch (err) {
      console.log("ERROR - DELETE RESOURCE", err);
      errorResponseAlert(
        dispatch,
        "Failed to delete",
        "Something went wrong, please try again later",
        err,
      );
      onError();
    }
  };
}

// HELPER FUNCTIONS
export const handleUpdateGroup = ({
  action,
  group,
  section,
  card,
  listingResource,
  dispatch,
}) => {
  let newGroup = {...group};
  let newGroupData =
    newGroup?.guest_journey?.sections?.[section]?.cards[card]?.data ?? [];
  switch (action) {
    case "add":
      newGroupData.push(listingResource);
      newGroup.guest_journey.sections[section].cards[card].data = newGroupData;
      break;
    case "update":
      newGroupData = newGroupData.map((gb) =>
        gb.resource_id === listingResource.resource_id ? listingResource : gb,
      );
      newGroup.guest_journey.sections[section].cards[card].data = newGroupData;
      break;
    case "delete":
      newGroupData = newGroupData.filter(
        (gb) => gb.resource_id !== listingResource.resource_id,
      );
      newGroup.guest_journey.sections[section].cards[card].data = newGroupData;
      break;
  }

  dispatch(editListingGroup(newGroup));
};

function handleSuccessAlertClose(dispatch) {
  dispatch(closeGeneralSuccessAlert());
}

function successResponseAlert(dispatch, message = "Success!", subtitle = null) {
  dispatch(
    openGeneralSuccessAlert({
      message,
      subtitle,
      open: true,
      onClose: () => handleSuccessAlertClose(dispatch),
    }),
  );
}

function getUpdatedListing(currentListing, newData) {
  const newListing = {...currentListing};
  const newListingContent = {...currentListing.listing_content};

  // Listing
  if (!!newData.name) {
    newListing.name = newData.name;
  }
  if (!!newData.tags) {
    newListing.tags = newData.tags;
  }
  if (!!newData.space) {
    newListing.space = newData.space;
  }
  if (!!newData.picture) {
    newListing.picture = newData.picture;
  }
  if (!!newData.address) {
    newListing.address = newData.address;
  }
  if (!!newData.nickname) {
    newListing.nickname = newData.nickname;
  }
  if (!!newData.currency) {
    newListing.currency = newData.currency;
  }
  if (!!newData.max_guests) {
    newListing.max_guests = newData.max_guests;
  }
  if (newData.hasOwnProperty("active")) {
    newListing.active = newData.active;
  }
  if (!!newData.property_type || !!newData.type) {
    newListing.type = newData.property_type ?? newData.type;
  }
  if (!!newData.check_times) {
    newListing.check_times = {
      ...currentListing.check_times,
      ...newData.check_times,
    };
  }
  // Listing Content
  if (!!newData.names) {
    newListingContent.names = newData.names;
  }
  if (!!newData.rooms) {
    newListingContent.rooms = newData.rooms;
  }
  if (!!newData.rules) {
    newListingContent.rules = newData.rules;
  }
  if (!!newData.custom) {
    newListingContent.custom = newData.custom;
  }
  if (!!newData.policy) {
    newListingContent.policy = newData.policy;
  }
  if (!!newData.pictures) {
    newListingContent.pictures = newData.pictures;
  }
  if (!!newData.knowledge) {
    newListingContent.knowledge = newData.knowledge;
  }
  if (!!newData.amenities) {
    newListingContent.amenities = newData.amenities;
  }
  if (!!newData.description) {
    newListingContent.description = newData.description;
  }
  if (!!newData.access_method) {
    newListingContent.access_method = newData.access_method;
  }
  if (!!newData.short_description) {
    newListingContent.short_description = newData.short_description;
  }

  newListing.listing_content = newListingContent;
  return newListing;
}
