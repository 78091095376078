import React, {useState} from "react";
import {useSelector} from "react-redux";
import {
  Box,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import clsx from "clsx";
import CustomDialog from "core/dialogs/CustomDialog";
import VirtualizedList from "components/Lists/VirtualizedList";
import {makeStyles} from "@material-ui/core/styles";
import {FIXED_SIZES} from "configuration/settings";
import SearchBar from "../../core/bars/SearchBar";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  checkbox: {
    padding: theme.spacing(2),
  },
  listContainer: {
    height: "200px",
    width: "600px",
    paddingLeft: "20px",
    paddingRight: "20px",
    backgroundColor: theme.palette.background.paper,
    overflowY: "auto",
  },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  title: {
    fontWeight: 600,
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  searchBarContainer: {
    width: "50%",
    marginBottom: theme.spacing(1),
  },
  disabledItem: {
    color: theme.palette.text.disabled,
    opacity: 0.3,
    pointerEvents: "none",
  },
}));

export default function ScopedListingGroupsModal({
  open: {isOpen, typed},
  onClose,
  onConfirm,
  selectedListings,
  setSelectedListings,
}) {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState("");
  const groups = useSelector((state) => state.defaultReducer?.listing_groups);
  const listing_groups = groups.filter((g) => g?.group_id !== "ALL");

  const filteredListingGroups = listing_groups?.filter((group) =>
    group.name.toLowerCase().includes(searchQuery?.toLowerCase()),
  );

  function displayRowItem({index}) {
    const rowItem = filteredListingGroups[index];

    let isService = false;

    if (typed !== "new_item") {
      isService = "service" in typed;
    }

    const connectedIds = isService
      ? rowItem.connected_ids?.service_account
      : rowItem.connected_ids?.branding;
    const sameEntry = connectedIds?.includes(typed?.id);
    const entryPopulated = connectedIds?.length;

    const isDisabled = entryPopulated ? !sameEntry : false;

    const handleOnClick = () => {
      if (!isDisabled) {
        setSelectedListings((prev) => {
          const isSelected = prev.includes(rowItem.group_id);
          const updatedListings = isSelected
            ? prev.filter((item) => item !== rowItem.group_id)
            : [...prev, rowItem.group_id];

          if (isSelected) {
            if (isService) {
              rowItem.connected_ids.service_account = connectedIds.filter(
                (id) => id !== typed.id,
              );
            } else {
              rowItem.connected_ids.branding = connectedIds.filter(
                (id) => id !== typed.id,
              );
            }
          } else {
            if (isService) {
              (rowItem.connected_ids?.service_account || []).push(typed.id);
            } else {
              (rowItem.connected_ids?.branding || []).push(typed.id);
            }
          }

          return updatedListings;
        });
      }
    };

    return (
      <ListItem
        disableRipple
        divider
        dense
        className={clsx(classes.item, {[classes.disabledItem]: isDisabled})}
        button
        ContainerComponent="div"
        selected={selectedListings.includes(rowItem.group_id)}
        onClick={handleOnClick}
      >
        <ListItemIcon className={classes.itemIcon}>
          <Checkbox
            edge="start"
            size="small"
            checked={selectedListings.includes(rowItem.group_id)}
            disableRipple
            className={classes.checkbox}
            disabled={isDisabled}
          />
        </ListItemIcon>
        <ListItemText
          primary={rowItem.name}
          primaryTypographyProps={{
            variant: "h1",
            className: clsx(classes.ellipsis, classes.title),
          }}
          secondary={
            rowItem.connected_to?.ids?.length === 1
              ? `${rowItem.connected_to?.ids?.length} Listing included`
              : `${rowItem.connected_to?.ids?.length} Listings included`
          }
          secondaryTypographyProps={{
            variant: "body1",
            className: clsx(classes.ellipsis, classes.subtitle),
          }}
        />
      </ListItem>
    );
  }

  const content = (
    <div className={classes.listContainer}>
      <VirtualizedList
        getRowItem={displayRowItem}
        rowHeight={FIXED_SIZES.connected_channel_accounts}
        totalRows={filteredListingGroups.length}
      />
    </div>
  );

  return (
    <CustomDialog
      title="Edit Listing Groups"
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      content={
        <>
          <Box className={classes.searchBarContainer}>
            <SearchBar
              style={{marginLeft: "0px"}}
              handleSearchInput={(val) => setSearchQuery((prev) => val)}
            />
          </Box>
          {content}
        </>
      }
      labelConfirm={
        typed === "new_item" ? "Close" : "service" in typed ? "Save" : "Close"
      }
      actionConfirm={onConfirm}
    />
  );
}
