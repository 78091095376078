import React from "react";
import axios from "axios";
import {Redirect} from "react-router";
import {useSelector} from "react-redux";
// UI
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  List,
  ListItem,
  ListItemText,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/ExpandMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutline";
import useTemplateCardStyles from "styles/useTemplateCardStyles";
// Custom
import VerificationServiceCard from "components/Cards/VerificationServiceCard";
import PrimaryButton from "core/buttons/PrimaryButton";
import HTMLField from "components/TextFields/HTMLField";
import Payment from "components/Panels/Booking/Payment";
// Utils
import {addonServices} from "configuration/enums";

export default function TemplateCard({
  type,
  template,
  title,
  bgImage,
  urlIcon,
  icon,
  expandable,
  expanded,
  currency,
  subheader = null,
  bgPosition = "center",
  loading,
  connectedTo,
  onClick = () => null,
  expandItem = () => null,
  onInfoClick,
}) {
  const classes = useTemplateCardStyles({bgImage, bgPosition});
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const [redirectPage, setRedirectPage] = React.useState(false);
  const [displayURLIcon, setDisplayURLIcon] = React.useState(false);
  const [product, setProduct] = React.useState(null);
  const [addonServ, setAddonServ] = React.useState({required: [], missing: []});

  let custom_icon = !!product && (
    <SvgIcon
      viewBox={product.viewBox}
      style={{fontSize: product.fontSize || 60, color: "#202223"}}
    >
      <path d={product.path} />
    </SvgIcon>
  );

  React.useEffect(() => {
    let requiredServices =
      template?.addon_services?.map((addServ) => addServ.subscription_id) || [];
    if (!template.addon_services?.length) {
      setAddonServ((prev) => ({required: [], missing: []}));
    } else {
      let missingServices = [];
      template.addon_services.forEach((addServ) => {
        let servAccProperties =
          serviceAccounts?.accounts?.[addServ.provider]?.[0]?.properties
            ?.group_ids || {};
        let allServices = servAccProperties["ALL"] || [];
        let groupServices = servAccProperties[connectedTo] || [];
        if (
          !allServices.includes(addServ.subscription_id) &&
          !groupServices.includes(addServ.subscription_id)
        ) {
          missingServices.push(addServ.subscription_id);
        }
      });
      setAddonServ((prev) => ({
        required: requiredServices,
        missing: missingServices,
      }));
    }
  }, [template, serviceAccounts]);

  React.useEffect(() => {
    validateURLIcon();
  }, [urlIcon]);

  React.useEffect(() => {
    let isMounted = true;
    if (!["fees", "upsells"].includes(type)) {
      return;
    }
    if (!template.upsellId) {
      return;
    }
    if (!product && !icon) {
      axios
        .get(
          `https://enso-image-assets.s3.amazonaws.com/products/${template.upsellId}_product.json`,
        )
        .then((r) => {
          if (isMounted) {
            setProduct(r.data);
          }
        })
        .catch((e) => console.log(e));
    }

    return () => {
      isMounted = false;
    };
  }, [template]);

  const handleServiceEnable = (opt) => {
    setAddonServ((prev) => ({
      ...prev,
      missing: prev.missing.filter(
        (missServ) => missServ !== opt["Subscription ID"],
      ),
    }));
  };

  function validateURLIcon() {
    if (!!urlIcon) {
      let image = new Image();
      image.onload = function () {
        setDisplayURLIcon((prev) => true);
      };
      image.onerror = function () {
        setDisplayURLIcon((prev) => false);
      };
      image.src = urlIcon;
    } else {
      setDisplayURLIcon((prev) => false);
    }
  }

  function getCardContent() {
    switch (type) {
      case "experience":
        return (
          <>
            {!!template.description && (
              <div className={classes.section}>
                <Typography variant="h1">Description</Typography>
                <Typography>{template.description}</Typography>
              </div>
            )}
            {!!template.requirements?.length && (
              <div className={classes.section}>
                <Typography variant="h1">Requirements</Typography>
                <List dense>
                  {template.requirements.map((req, ind) => (
                    <ListItem
                      divider
                      key={`${req.spec?.service_type}-${ind}`}
                      className={classes.listItem}
                      classes={{divider: classes.listDivider}}
                    >
                      <ListItemText
                        primary={req.message}
                        primaryTypographyProps={{variant: "h1"}}
                      />
                      {req.satisfied ? (
                        <CheckCircleIcon className={classes.satisfiedIcon} />
                      ) : (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            setRedirectPage(req.path_to_resolution)
                          }
                        >
                          {req.button_message}
                        </Button>
                      )}
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            {!!template.how_does_it_work && (
              <div className={classes.section}>
                <Typography variant="h1">How Does it Work?</Typography>
                <Typography>{template.how_does_it_work}</Typography>
              </div>
            )}
            {!!template.cost && (
              <div className={classes.section}>
                <Typography variant="h1">Cost</Typography>
                <div className={classes.row}>
                  <Typography className={classes.bold}>
                    {template.cost}
                  </Typography>
                  {template.cost === "FREE" && (
                    <Typography>
                      {
                        " — There are no additional Enso fee’s incurred to enable this experience."
                      }
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </>
        );
      case "fees":
      case "upsells":
        return (
          <>
            <div className={classes.section}>
              <Typography variant="h1">Description</Typography>
              <HTMLField content={template.description} multiline />
            </div>
            {template.skus.map((s, i) => {
              return (
                <div className={classes.section} key={s.sku_id ?? i}>
                  <Typography variant="h1">{`${!!s.name ? `${s.name} p` : "P"}ayment summary`}</Typography>
                  <Payment
                    hideSymbol
                    sku={{...s, is_fee: type === "fees", currency: currency}}
                  />
                </div>
              );
            })}
          </>
        );
      case "guidebooks":
        return (
          <div className={classes.section}>
            <Typography>{template.preview}</Typography>
          </div>
        );
      case "keycard":
        return (
          <>
            <div className={classes.section}>
              <Typography variant="h1">Description</Typography>
              <Box mb={3}>
                <Typography>{template.description}</Typography>
              </Box>
              <HTMLField inline multiline content={template.how_does_it_work} />
            </div>
            {!!template.addon_services?.length && (
              <div className={classes.addonServicesContainer}>
                <Typography variant="h1">Required services</Typography>
                {template.addon_services.map((addServ) => {
                  let opt = addonServices[addServ.provider]?.find(
                    (serv) =>
                      serv["Subscription ID"] === addServ.subscription_id,
                  );
                  if (!opt) {
                    return null;
                  }
                  return (
                    <VerificationServiceCard
                      key={addServ.subscription_id}
                      connectedTo={connectedTo}
                      option={opt}
                      disableImg
                      groupId={connectedTo}
                      onEnable={handleServiceEnable}
                      selectedVerifier={
                        !addonServ.missing.includes(addServ.subscription_id)
                          ? addServ.verification_service
                          : null
                      }
                    />
                  );
                })}
              </div>
            )}
          </>
        );

      default:
        return null;
    }
  }

  if (!!redirectPage) {
    if (redirectPage.slice(0, 4) === "http") {
      window.location.href = redirectPage;
    } else {
      return <Redirect push to={`/admin/${redirectPage}`} />;
    }
  }
  return (
    <Card className={classes.card}>
      <CardActionArea disableRipple disabled={!expandable} onClick={expandItem}>
        <CardHeader
          title={
            <div className={classes.titleRow}>
              {!!custom_icon || !!urlIcon ? (
                <div className={classes.iconsRow}>
                  <Box
                    flex={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                  >
                    {displayURLIcon && (
                      <img
                        src={urlIcon}
                        alt={`${title} icon`}
                        className={classes.icon}
                      />
                    )}
                  </Box>
                  {!!expandable && <ArrowIcon />}
                </div>
              ) : (
                <div className={classes.iconsRow}>
                  <Box
                    flex={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                  >
                    {icon}
                  </Box>
                  {!!expandable && <ArrowIcon />}
                </div>
              )}
              {title}
            </div>
          }
          subheader={subheader}
          subheaderTypographyProps={{className: classes.subheader}}
          className={classes.cardHeader}
          titleTypographyProps={{variant: "h2"}}
        />
      </CardActionArea>
      <Collapse in={!expandable || !!expanded}>
        <CardContent className={classes.cardContent}>
          {getCardContent()}
        </CardContent>
        <CardActions disableSpacing className={classes.cardActions}>
          {!!onInfoClick && (
            <Box mr={2}>
              <PrimaryButton
                subdued
                variant="text"
                label="More info"
                onClick={onInfoClick}
              />
            </Box>
          )}
          <PrimaryButton
            disabled={loading || !!addonServ.missing.length}
            label={type === "keycard" ? "Enable" : "Get started"}
            color="secondary"
            onClick={onClick}
          />
        </CardActions>
      </Collapse>
    </Card>
  );
}
