import React, {useEffect, useState, Fragment} from "react";
import {Storage} from "aws-amplify";
// UI
import {Button, makeStyles, Typography} from "@material-ui/core";
import UploadIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
  dashed: {
    border: `2px dashed #d2d2d2`,
    borderRadius: 10,
    width: "100%",
    maxWidth: 300,
    height: 200,
    display: "grid",
    placeContent: "center",
    backgroundColor: "#F6F9FF",
  },
  icon: {
    fontSize: 60,
    justifySelf: "center",
    marginBottom: 10,
  },
}));

export default function DragAndDropFile({setFile, label = "Select Image"}) {
  const inputRef = React.useRef(null);
  const classes = useStyles();

  const dragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };

  const filesUploaded = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div
      id="branding-logo"
      className={classes.dashed}
      onDragOver={dragOver}
      onDragEnter={dragOver}
      onDragLeave={dragOver}
      onDrop={fileDrop}
    >
      <input
        id="pictureInput"
        ref={inputRef}
        style={{display: "none"}}
        type="file"
        multiple
        accept="image/*"
        onChange={filesUploaded}
      />
      <UploadIcon color="primary" className={classes.icon} />
      <Button variant="contained" color="primary" onClick={handleClick}>
        {label}
      </Button>
      <Typography style={{marginTop: 5, justifySelf: "center"}}>
        - OR -
      </Typography>
      <Typography>Drag & drop to upload</Typography>
    </div>
  );
}
