import React, {useEffect} from "react";
// UI
import {
  Button,
  ListItem,
  ListItemIcon,
  List,
  ListItemText,
  Radio,
  Typography,
  darken,
  Box,
  makeStyles,
} from "@material-ui/core";
// Custom
import LoadingSpinner from "components/Misc/LoadingSpinner";
import HTMLContent from "./HTMLContent";
import ScrollablePage from "./ScrollablePage";
import SectionHeaderCard from "./SectionHeaderCard";
// Utils
import defaultBGImg from "assets/img/article_placeholder_image.webp";
import useRefDimensions from "utilities/useRefDimensions";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 50,
    marginBottom: 30,
  },
  sku_box: {
    marginBottom: 12,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5, 2.5, 0),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
    "& .ql-editor": {
      padding: "0 !important",
      "& img": {maxWidth: "100%"},
    },
  },
  btn: {
    flex: 1,
    fontWeight: "500 !important",
    fontSize: "16px !important",
    boxShadow: "none !important",
    borderRadius: "30px !important",
    fontFamily: '"Montserrat", serif !important',
    color: theme.palette.primary.contrast,
    backgroundColor: theme.palette.primary.main + "!important",
  },
  sku_item: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: theme.spacing(0.5, 1, 0.5, 1.5),
    "&.disabled": {
      border: "none",
      background: darken(theme.palette.primary.contrast, 0.06),
    },
    "&.selected": {background: darken(theme.palette.primary.contrast, 0.07)},
  },
  itemIcon: {
    minWidth: "0px !important",
    marginRight: theme.spacing(1.5),
  },
  radioBtn: {
    padding: "0px !important",
    color: theme.palette.primary.main + "!important",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  price: {
    borderRadius: 5,
    padding: theme.spacing(0.5, 1),
    background: theme.palette.primary.main,
    color:
      theme.palette.getContrastText(theme.palette.primary.main) + "!important",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  itemDescription: {
    flex: 1,
    paddingRight: theme.spacing(1),
  },
  simpleHeadercontainer: {
    width: "100%",
    maxWidth: 800,
    paddingTop: theme.spacing(7.5),
  },
}));

function findProduct(components, product_id) {
  let product = components?.fees?.find((f) => f.product_id === product_id);
  if (!!product) {
    return product;
  } else {
    return components?.upsells?.find((f) => f.product_id === product_id);
  }
}

export default function ProductPage({
  product_id,
  components,
  disableClose,
  closePage,
}) {
  const classes = useStyles();
  const {t} = useTranslation();
  const contentRef = React.useRef(null);
  const product = findProduct(components, product_id);
  const multiple_skus = product?.skus?.length > 1;
  const [selectedSku, setSelectedSku] = React.useState(
    multiple_skus ? null : 0,
  );
  const [visibleBottom, setVisibleBottom] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const contentDimensions = useRefDimensions(contentRef);
  const productTotal =
    selectedSku === null
      ? ""
      : !product?.skus?.[selectedSku].value
        ? ""
        : `${product?.skus?.[selectedSku].formatted_value} - `;
  const purchaseWord =
    selectedSku === null
      ? t("make_your_selection")
      : product?.skus[selectedSku].charge_card === false
        ? t("place_hold")
        : !product?.skus[selectedSku].value
          ? t("request")
          : t("purchase");

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoading((prev) => false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (contentDimensions.width === 1 && contentDimensions.height === 2) {
      return;
    }
    if (
      contentDimensions.height === contentRef.current?.scrollHeight &&
      !visibleBottom
    ) {
      setVisibleBottom((prev) => true);
    } else if (
      contentDimensions.height < contentRef.current?.scrollHeight &&
      !!visibleBottom
    ) {
      setVisibleBottom((prev) => false);
    }
  }, [product, contentDimensions]);

  const scrollToBottom = () => {
    if (contentRef.current) {
      const el = contentRef.current;
      el.scrollTo({
        top: contentRef.current?.scrollHeight ?? 0,
        behavior: "smooth",
      });
    }
  };

  function getSku(ind) {
    let curr_sku = product.skus[ind];
    return (
      <ListItem
        key={`sku-${ind}`}
        dense
        button
        disableGutters
        onClick={() => setSelectedSku(ind)}
        className={classes.sku_item}
      >
        <ListItemIcon className={classes.itemIcon}>
          <Radio
            checked={ind === selectedSku}
            tabIndex={-1}
            className={classes.radioBtn}
            value={`skuselect-${ind}`}
          />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <div className={classes.itemContainer}>
              <Typography className={classes.itemDescription}>
                {curr_sku.name}
              </Typography>
              <Typography className={classes.price}>
                {curr_sku.formatted_value}
              </Typography>
            </div>
          }
        />
      </ListItem>
    );
  }

  const handlePurchase = () => {
    if (selectedSku === null) {
      scrollToBottom();
      return;
    }
  };

  const getPurchaseButtonContent = () => {
    if (loading) {
      return <LoadingSpinner />;
    }
    return (
      <span>
        {selectedSku !== null && productTotal}
        {purchaseWord}
      </span>
    );
  };

  const handleContentScroll = (e) => {
    const el = e.target;
    if (!!el) {
      let scrollBottom = el.scrollHeight - el.scrollTop - el.clientHeight;
      if (scrollBottom === 0 && !visibleBottom) {
        setVisibleBottom((prev) => true);
      } else if (scrollBottom > 0 && !!visibleBottom) {
        setVisibleBottom((prev) => false);
      }
    }
  };

  const header = !!product?.header_image ? (
    <SectionHeaderCard
      disableGrayscale
      title={product?.name ?? ""}
      img={product?.header_image ?? defaultBGImg}
    />
  ) : (
    <div className={classes.simpleHeadercontainer}>
      <Box px={2.5}>
        <Typography variant="h3">{product?.name}</Typography>
      </Box>
    </div>
  );

  const productContent = (
    <div className={classes.content}>
      <HTMLContent v={product?.description} />
      {multiple_skus && (
        <List className={classes.sku_box}>
          {product?.skus?.map((sku, i) => getSku(i))}
        </List>
      )}
    </div>
  );

  const actionPanel = (
    <div className={classes.actionsContainer}>
      <ListItem
        dense
        disableGutters
        className={`${classes.sku_item} ${selectedSku === null ? "disabled" : "selected"}`}
      >
        <ListItemText
          disableTypography
          primary={
            <div className={classes.itemContainer}>
              <Typography
                className={classes.itemDescription}
                align={selectedSku === null ? "center" : "left"}
              >
                {product?.skus?.[selectedSku]?.name ?? t("nothing_selected")}
              </Typography>
              {selectedSku !== null && (
                <Typography className={classes.price}>
                  {product?.skus?.[selectedSku]?.formatted_value}
                </Typography>
              )}
            </div>
          }
        />
      </ListItem>
      <Button
        disableRipple
        disabled={loading}
        className={classes.btn}
        onClick={handlePurchase}
        fullWidth
        size="large"
        variant="contained"
      >
        {getPurchaseButtonContent()}
      </Button>
    </div>
  );

  return (
    <ScrollablePage
      onClose={closePage}
      actionPanel={actionPanel}
      contentRef={contentRef}
      contentType="product"
      disableClose={disableClose}
      handleContentScroll={handleContentScroll}
      header={header}
      pageContent={productContent}
    />
  );
}
