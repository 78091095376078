import React from "react";
import {useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
// Custom
import CreationButton from "components/MultiOption/CreationButton";
import ExperienceList from "components/Lists/ExperienceList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 0),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing(0, 5),
    justifyContent: "flex-end",
  },
}));

export default function ListingExperiencePanel({
  listingId,
  onExperienceSelect,
  loading,
  selectedExperience,
  closeOpenExperience,
  disableEdit,
  createFromExisting = () => null,
}) {
  const classes = useStyles();
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const loadingContent = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_content;
  const listing = React.useMemo(() => houses[listingId], [houses, listingId]);

  const onCreateFromScratch = () => {
    createExperience();
  };
  const onCreateFromExisting = () => {
    createFromExisting();
  };

  const createExperience = (experience) => {
    if (!experience) {
      onExperienceSelect({
        connected_to: {object: "listings", ids: [listing.listing_id]},
        actions: [{actions: null, trigger: "cin"}],
        enabled: false,
        name: "",
      });
    } else {
      const newExperience = {...experience};
      delete newExperience.id;
      delete newExperience.experience_id;
      onExperienceSelect(newExperience);
    }
  };

  return (
    <div className={classes.root}>
      {!disableEdit && (
        <div className={classes.row}>
          <CreationButton
            type="experience"
            disabled={loading}
            onScratch={onCreateFromScratch}
            onExisting={onCreateFromExisting}
          />
        </div>
      )}
      <ExperienceList
        noTitle
        extraGutters
        disableEdit={disableEdit}
        isParentLoading={loading || loadingContent}
        layoutType="list"
        closeOpenExperience={closeOpenExperience}
        experienceType="your_experiences"
        listingId={listing?.listing_id}
        createExperience={createExperience}
        selectedExperience={selectedExperience}
        setSelectedExperience={onExperienceSelect}
      />
    </div>
  );
}
