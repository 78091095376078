import React from "react";
import {Checkbox, Chip, ListItemText, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FilledSelect from "core/selects/FilledSelect";
import SearchBar from "core/bars/SearchBar";
import SimpleField from "../../../components/TextFields/SimpleField";
import isEqual from "lodash/isEqual";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: "rgba(67,84,96,0.07)",
    color: "rgba(67,84,96,0.8)",
    fontSize: 12,
    height: 20,
  },
}));

export default function GenericMultiSelect({
  options = [],
  enableSearch = false,
  edit,
  value = [],
  multiselect = true,
  onValueChange,
  label = "Select Options",
}) {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = React.useState(
    initSelectedOptions(value),
  );
  const [searchText, setSearchText] = React.useState("");

  function initSelectedOptions(v) {
    if (multiselect) {
      return Array.isArray(v) ? v : [v];
    }
    return v;
  }

  React.useEffect(() => {
    if (edit && !isEqual(value, selectedOptions)) {
      setSelectedOptions(initSelectedOptions(value));
    }
  }, [value, multiselect]);

  React.useEffect(() => {
    if (edit && !isEqual(value, selectedOptions)) {
      onValueChange(selectedOptions);
    }
  }, [selectedOptions]);

  const handleOptionChange = (e) => {
    let val = e.target.value;
    if (multiselect && !Array.isArray(val)) {
      val = [val];
    } else if (!multiselect && Array.isArray(val)) {
      val = val[0] || "";
    }
    setSearchText("");
    setSelectedOptions(val);
  };

  const availableOptions = React.useMemo(() => {
    return enableSearch
      ? options.filter((option) =>
          option.toString().toLowerCase().includes(searchText.toLowerCase()),
        )
      : options;
  }, [options, searchText, enableSearch]);

  if (edit) {
    return (
      <div className={classes.root}>
        <FilledSelect
          fullWidth
          label={label}
          value={
            Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions]
          }
          onChange={handleOptionChange}
          multiple={multiselect}
          style={{minWidth: 150}}
          renderValue={(selected) => {
            if (multiselect) {
              return Array.isArray(selected)
                ? selected.filter((item) => item).join(", ")
                : "";
            }
            return Array.isArray(selected) ? selected[0] || "" : selected;
          }}
        >
          <MenuItem value="" disabled>
            {label}
          </MenuItem>
          {enableSearch && (
            <SearchBar
              stopPropagation
              searchInput={searchText}
              handleSearchInput={(val) => setSearchText(val)}
            />
          )}
          {availableOptions.length === 0 && (
            <MenuItem disabled>No results</MenuItem>
          )}
          {availableOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {multiselect && (
                <Checkbox checked={selectedOptions.includes(option)} />
              )}
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </FilledSelect>
      </div>
    );
  } else {
    if (!value || value.length === 0) {
      return <SimpleField value="-" />;
    }
    return (
      <div className={classes.root}>
        {Array.isArray(value) ? (
          value.map((val) => (
            <Chip key={val} size="small" label={val} className={classes.chip} />
          ))
        ) : (
          <Chip size="small" label={value} className={classes.chip} />
        )}
      </div>
    );
  }
}
