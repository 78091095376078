import React from "react";
// UI
import {Box, SvgIcon, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as ConditionsIcon} from "assets/icons/shuffle.svg";
// Custom
import CustomCondition from "./CustomCondition";
import PrimaryButton from "core/buttons/PrimaryButton";
// Utilities
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerTitleSection: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  expandBtn: {height: "fit-content"},
  description: {
    color: "#979797",
    "&.standalone": {color: theme.palette.text.primary},
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(1),
    "& > .icon": {
      width: 16,
      height: 16,
    },
  },
}));

export default function Conditions({
  disableEdit,
  disableAddCondition,
  data = [],
  setData,
  standalone = false,
  setConditionEdit = () => null,
  title = "Condition",
  label = "Define what conditions must be met to start this experience.",
  hideTitle,
  viewOnly,
  disableBottomPadding,
  saveBtnProps = {label: "Done", color: "primary"},
  additionalObjectsVariables = [],
}) {
  const classes = useStyles();
  const [conditions, setConditions] = React.useState([]);
  const [editStates, setEditStates] = React.useState([]);
  const [selectedCondition, setSelectedCondition] = React.useState(null);
  const prevData = usePrevious(data);

  React.useEffect(() => {
    if (!_.isEqual(prevData, data)) {
      resetData();
    }
  }, [data]);

  React.useEffect(() => {
    setConditionEdit && setConditionEdit(editStates.some((s) => !!s));
  }, [editStates]);

  const resetCondition = () => setSelectedCondition((prev) => null);

  const addNewCondition = () => {
    const newCond = {c_type: "bool", field: "", operator: "", val: ""};
    setConditions((prev) => [...prev, newCond]);
    setEditStates((prev) => [...prev, true]);
  };

  const resetData = () => {
    const newEditStates = [];
    _.each(data, (d) => newEditStates.push(false));
    setConditions((prev) => data);
    setEditStates((prev) => newEditStates);
  };

  const onSave = (index) => (newCondition) => {
    const newConditions = [...conditions];
    newConditions[index] = newCondition;
    setData(newConditions);
    resetCondition();
  };

  const onCancel = () => {
    resetCondition();
    resetData();
  };

  const onDelete = (index) => () => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setData(newConditions);
  };

  const onEditStateChange = (index) => (val) => {
    const newEditStates = [...editStates];
    newEditStates[index] = val;
    setEditStates((prev) => newEditStates);
  };

  const onConditionChange = () => {
    setSelectedCondition((prev) => "custom");
    addNewCondition();
  };

  function getContent(condition, ind) {
    return (
      <CustomCondition
        index={ind}
        disableEdit={disableEdit}
        data={condition}
        saveBtnProps={saveBtnProps}
        viewOnly={viewOnly}
        edit={editStates[ind]}
        onEditChange={onEditStateChange(ind)}
        onSave={onSave(ind)}
        onCancel={onCancel}
        onDelete={onDelete(ind)}
        conditions={conditions}
        additionalObjectsVariables={additionalObjectsVariables}
      />
    );
  }

  return (
    <div>
      {!!standalone && !hideTitle && (
        <div className={classes.sectionTitle}>
          <SvgIcon
            className="icon"
            viewBox="0 0 16 17"
            component={ConditionsIcon}
          />
          <Typography className="title" variant="h1">
            {title}
          </Typography>
        </div>
      )}
      <Box
        className={classes.header}
        pb={disableBottomPadding && editStates.some((v) => !!v) ? 0 : 2}
      >
        {!hideTitle && (
          <div className={classes.headerTitleSection}>
            {!standalone && (
              <Typography variant="subtitle1" className={classes.title}>
                {title}
              </Typography>
            )}
            <Typography
              className={clsx(classes.description, {standalone: !!standalone})}
            >
              {label}
            </Typography>
          </div>
        )}
        {!viewOnly && (
          <PrimaryButton
            label="Add Condition"
            size="small"
            style={{minWidth: 113}}
            disabled={disableEdit || disableAddCondition}
            className={classes.expandBtn}
            onClick={onConditionChange}
          />
        )}
      </Box>
      {(!!data.length || !!selectedCondition) &&
        conditions.map((c, i) => (
          <React.Fragment key={!!c.c_type ? `${c.c_type}-${i}` : i}>
            {getContent(c, i)}
          </React.Fragment>
        ))}
    </div>
  );
}
