import React from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {SvgIcon, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as BusinessIcon} from "assets/icons/Icon_Buildings.svg";
import {ReactComponent as ListingIcon} from "assets/icons/Icon_House.svg";
import HouseGroupIcon from "@material-ui/icons/HomeWork";
import {ReactComponent as HomeIcon} from "assets/icons/home.svg";
// Custom
import ListingConnection from "components/MultiOption/ListingConnection";
import MultiButtonBar from "core/buttons/MultiButtonBar";
// Actions
import {getHouses} from "redux/actions/listingsActions";
// Utils
import {getEncodedFilters} from "utilities/helperFunctions";
import {FIXED_SIZES} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  listingsContainer: {
    display: "flex",
    "&.-open": {
      minHeight: 0,
      height: (props) =>
        !!props.totalListingsHeight ? props.totalListingsHeight + 92 : 92,
      maxHeight: 355,
    },
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0),
    "& > .icon": {
      width: 16,
      height: 16,
    },
  },
}));

export const icons = {
  all: (
    <SvgIcon
      viewBox="0 0 18 16"
      className="-button-icon"
      component={BusinessIcon}
    />
  ),
  listing_group: <HouseGroupIcon />,
  listing: (
    <SvgIcon
      viewBox="0 0 18 16"
      className="-button-icon"
      component={ListingIcon}
    />
  ),
};

export default function ConnectedToButton({
  listing_id,
  group_id,
  data,
  editData,
  disabled = false,
  disabledOptions = [],
  specificListings = null,
  listingsOpen,
  setListingsOpen = () => null,
  onlyOneSelection,
  setEmptyListings = () => null,
  showSectionTitle = false,
  type = null,
  title = "Connected Listings",
  isGroupView = false,
  listing,
  externalListings = [],
}) {
  const classes = useStyles({
    totalListingsHeight:
      ((specificListings?.length || 0) + externalListings.length) *
      FIXED_SIZES.listings_dense,
  });
  const dispatch = useDispatch();
  const isLoadingMissingListings = React.useRef(false);
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const buttonOptions = React.useMemo(() => {
    let buttons = ["all"];
    if (!!group_id) {
      buttons.push("group");
    }
    if (!!(listing_id || specificListings?.length)) {
      buttons.push("ids");
    }
    return buttons.filter((b) => !disabledOptions.includes(b));
  }, [group_id]);
  const buttonLabels = React.useMemo(() => {
    let labels = disabledOptions.includes("all") ? [] : ["All listings"];
    if (!!group_id && !disabledOptions.includes("group")) {
      labels.push("Current Listing Group");
    }
    if (!disabledOptions.includes("ids")) {
      if (!!specificListings?.length) {
        labels.push("Specific Listings");
      } else if (!!listing_id) {
        labels.push("This listing");
      }
    }
    return labels;
  }, [group_id, specificListings]);
  const [listingConnectionHelper, setListingConnectionHelper] = React.useState({
    listings: [],
    groupId: null,
    connection_type: data?.connection_type ?? "all",
  });
  const [connectionData, setConnectionData] = React.useState({
    connected_ids: {},
    group_ids: [],
    connection_type: "all",
    connected_object: "listing",
  });

  const resetConnectionData = () => {
    let newConnectionData = {
      connected_ids:
        data?.connected_ids ?? (isGroupView ? {} : {listing: [listing?.id]}),
      group_ids: data?.group_ids ?? (isGroupView ? [group_id] : []),
      connection_type: data?.connection_type ?? (isGroupView ? "group" : "ids"),
      connected_object: "listing",
    };
    setConnectionData((prev) => newConnectionData);
    if (!data?.connection_type) {
      editData({...data, ...newConnectionData});
    }
  };

  const loadMissingListings = () => {
    if (!group_id) {
      return;
    }
    const params = `enso_key=${current_user}&start=0&query_all=true&filters=${getEncodedFilters([{path: "listing_group_id", value: group_id}])}`;
    dispatch(
      getHouses(params, true, (response) => {
        isLoadingMissingListings.current = false;
      }),
    );
  };

  React.useEffect(() => {
    if (connectionData.connection_type === "ids") {
      setEmptyListings(!connectionData.connected_ids.listing?.length);
    } else {
      setEmptyListings(false);
    }
    setListingConnectionHelper((prev) => ({
      ...prev,
      connection_type: connectionData.connection_type,
    }));
  }, [connectionData]);

  React.useEffect(() => {
    let initialConnectionType =
      data?.connection_type ?? (isGroupView ? "group" : "ids");
    resetConnectionData();
    if (!!specificListings) {
      if (
        !!data?.connected_ids?.listing?.length &&
        initialConnectionType === "ids"
      ) {
        setListingConnectionHelper((prev) => ({
          ...prev,
          listings: data.connected_ids.listing,
        }));
      } else if (!!listing_id) {
        setListingConnectionHelper((prev) => ({
          ...prev,
          listings: [`l#${listing_id}`],
        }));
      } else if (!!group_id) {
        setListingConnectionHelper((prev) => ({
          ...prev,
          listings: [`l#${specificListings[0]}`],
        }));
      }
    }
  }, []);

  React.useEffect(() => {
    if (!specificListings?.length) {
      return;
    }
    const hasMissingIds = specificListings.some((h) => !houses[h]);
    if (!isLoadingMissingListings.current && !!hasMissingIds) {
      isLoadingMissingListings.current = true;
      loadMissingListings();
    }
  }, [specificListings]);

  const handleConnectionChange = (option) => {
    let newConnectionData = {...connectionData};
    if (option == "ids") {
      // Specific listings
      const listingSelected =
        !!listing_id && !!specificListings
          ? (specificListings.find((l) => l === listing_id) ??
            specificListings[0])
          : !!specificListings
            ? specificListings[0]
            : null;

      newConnectionData.connection_type = "ids";
      newConnectionData.connected_ids = !!listingSelected
        ? {
            listing: listingConnectionHelper.listings.map((l) =>
              l.startsWith("l#") ? l : `l#${l}`,
            ),
          }
        : !!listing_id
          ? {listing: [houses[listing_id]?.id]}
          : {};
      newConnectionData.group_ids = [];
    } else if (option == "group") {
      newConnectionData.connection_type = "group";
      newConnectionData.connected_ids = {};
      newConnectionData.group_ids = [group_id];
      setListingsOpen(false);
    } else {
      // All listings
      newConnectionData.connection_type = "all";
      newConnectionData.connected_ids = {};
      newConnectionData.group_ids = [];
      setListingsOpen(false);
    }
    editData({...data, ...newConnectionData});
    setConnectionData((prev) => newConnectionData);
    setListingConnectionHelper((prev) => ({
      ...prev,
      connection_type: newConnectionData.connection_type,
    }));
  };

  const handleDataChange = (newData) => {
    let newConnectionData = {
      ...connectionData,
      connection_type: "ids",
      connected_ids: {
        listing: newData.listings.map((l) =>
          l.startsWith("l#") ? l : `l#${l}`,
        ),
      },
      group_ids: [],
    };
    setListingConnectionHelper((prev) => newData);
    setConnectionData((prev) => newConnectionData);
    editData({...data, ...newConnectionData});
  };

  return (
    <div className={classes.section}>
      {!!showSectionTitle && (
        <>
          <div className={classes.sectionTitle}>
            <SvgIcon
              className="icon"
              viewBox="0 0 18 17"
              component={HomeIcon}
            />
            <Typography className="title" variant="h1">
              {title}
            </Typography>
          </div>
          <Typography>
            {`Choose whether to add your ${type} to all your listings${
              !!group_id ? ", your current listing group," : ""
            }${
              !!specificListings?.length
                ? " or specific listings."
                : " or this current listing."
            }`}
          </Typography>
        </>
      )}
      <MultiButtonBar
        thin
        dividers
        disabled={disabled}
        value={connectionData.connection_type}
        onChange={handleConnectionChange}
        options={buttonOptions}
        icons={[icons.all, icons.listing_group, icons.listing]}
        labels={buttonLabels}
      />
      {!!specificListings && connectionData.connection_type === "ids" && (
        <div
          className={clsx(classes.listingsContainer, {"-open": listingsOpen})}
        >
          <ListingConnection
            data={listingConnectionHelper}
            disableSearch
            type={type}
            listingKey="id"
            disableConnectionTypes
            disableEdit={disabled}
            onlyOneSelection={onlyOneSelection}
            onlyHouses={specificListings}
            extraListings={externalListings}
            showHouseList={listingsOpen}
            setShowHouseList={setListingsOpen}
            setData={handleDataChange}
          />
        </div>
      )}
    </div>
  );
}
