import {makeStyles} from "@material-ui/core";
import {THEME} from "configuration/settings.js";

const useGuidebooksStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(4),
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  header: {
    padding: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.primary.main + "0D"}`,
  },
  dialogHeader: {
    background: (props) =>
      `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0.26%, rgba(0, 0, 0, 0.28) 99.71%), url(${props.dialogBGImage}), lightgray 0px -643.099px / 100% 500% no-repeat`,
    backgroundPosition: "50% !important",
    backgroundSize: "cover !important",
  },
  subtitle: {marginBottom: theme.spacing(3)},
  icon: {
    color: THEME.grey,
    fontSize: 24,
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  //editorContainer: { margin: theme.spacing(5, 0, 0) },
  title: {...THEME.customTypography.title2},
  itemTitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    maxWidth: "70%",
    WebkitLineClamp: 1,
  },
  buttonRow: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(4),
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: theme.spacing(2),
  },
  connectedText: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.disabled,
    flex: 1,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    wordBreak: "break-all",
    lineClamp: 1,
  },
  divider: {
    margin: theme.spacing(4, 0),
    backgroundColor: "#F0F0F0",
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(3),
    "& > .icon": {
      width: 16,
      height: 16,
    },
  },
  templateContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    "&::-webkit-scrollbar": {width: "0!important"},
  },
  templateIcon: {
    width: 28,
    height: 28,
    objectFit: "contain",
    position: "absolute",
    top: 16,
    left: 20,
    "&.-lg": {
      width: 38,
      height: 38,
      top: 12,
    },
  },
}));

export default useGuidebooksStyles;
