import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
// UI
import {makeStyles} from "@material-ui/core/styles";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/ArrowForwardIos";
import {ReactComponent as SuperhogIcon} from "assets/icons/Superhog_Icon.svg";
import {ReactComponent as ChekinIcon} from "assets/icons/Chekin_Icon.svg";
import {ReactComponent as AutohostIcon} from "assets/icons/Autohost_Icon.svg";
import {ReactComponent as ParteeIcon} from "assets/icons/partee_icon.svg";
// Custom
import ServiceInfoContent from "components/Dialogs/ServiceInfoContent";
import PrimaryButton from "core/buttons/PrimaryButton";
// Actions
import {
  createService,
  setServiceAccounts,
  updateService,
} from "redux/actions/servicesActions";
// Utils
import {THEME} from "configuration/settings";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    width: (props) => (!!props.fitContent ? "fit-content" : "100%"),
    boxShadow: "none",
    overflow: "visible",
    borderRadius: 10,
    padding: theme.spacing(4),
    border: (props) => (!!props.bgImage ? "none" : "1px solid #E6E6E6"),
    background: (props) =>
      !!props.bgImage
        ? `linear-gradient(0deg, ${THEME[props.provider].bg1} 0.26%, ${THEME[props.provider].bg2} 99.71%), url(${props.bgImage}), lightgray -48.739px 0px / 140.078% 100% no-repeat`
        : theme.palette.common.white,
    backgroundPosition: "50% !important",
    backgroundSize: "cover !important",
    backgroundBlendMode: "multiply, normal",
    marginBottom: (props) => theme.spacing(props.mb),
    "&.-expanded": {borderRadius: "10px 10px 0 0"},
    "&.-selected": {
      border: (props) =>
        !!props.bgImage ? "none" : `2px solid ${theme.palette.secondary.main}`,
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(3),
    padding: 0,
    "&.centered": {alignItems: "center"},
    "&:last-child": {paddingBottom: 0},
  },
  verifierIcon: {
    fontSize: 150,
    height: 30,
    color: (props) =>
      !!props.bgImage ? theme.palette.common.white : "#979797",
    "&.-selected": {
      color: (props) =>
        `${!!props.bgImage ? theme.palette.common.white : theme.palette.secondary.main} !important`,
    },
    "&.-disabled": {opacity: 0.3},
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: (props) =>
      !!props.bgImage ? theme.palette.common.white : theme.palette.text.primary,
    "&.-selected": {
      color: (props) =>
        !!props.bgImage
          ? theme.palette.common.white
          : theme.palette.secondary.main,
    },
  },
  arrowIcon: {
    fontSize: 16,
    transition: "0.15s",
    color: (props) =>
      !!props.bgImage ? theme.palette.common.white : "#979797",
    "&.-selected": {transform: "rotate(90deg)"},
  },
  expandedCard: {
    marginTop: -theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  expandedContent: {
    padding: theme.spacing(4),
    background: "#F5F5F5",
  },
  expandedActions: {
    padding: theme.spacing(4),
    textAlign: "end",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)",
    borderRadius: "0 0 10px 10px",
  },
}));

const verifierIcons = {
  chekin: {icon: ChekinIcon, viewBox: "0 0 120 27"},
  superhog: {icon: SuperhogIcon, viewBox: "0 0 120 27"},
  autohost: {icon: AutohostIcon, viewBox: "0 0 125 17"},
  partee: {icon: ParteeIcon, viewBox: "300 0 512 242"},
};

const VerificationServiceCard = ({
  option,
  selectedVerifier,
  disableImg,
  btnLabel = "More info",
  disableModal,
  mb = 0,
  fitContent,
  onClick = () => {},
  onEnable = () => {},
  connectedTo = "",
  onExpand = null,
  expanded = false,
  isPreviewing,
}) => {
  const classes = useStyles({
    mb,
    fitContent,
    provider: disableImg ? null : option["Provider"],
    bgImage: disableImg ? null : option.cover_photo_url,
  });
  const dispatch = useDispatch();
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const groups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let selected = selectedVerifier === option["Verifier"] && !isPreviewing;
  let connectedToLabel = !connectedTo
    ? ""
    : connectedTo === "ALL"
      ? "all listings"
      : `${groups[connectedTo]?.name} group`;
  let serviceAccountProperties =
    serviceAccounts?.accounts?.[option["Provider"]]?.[0]?.properties
      ?.group_ids || {};
  let isServiceConnected =
    (serviceAccountProperties?.["ALL"] || []).includes(
      option["Subscription ID"],
    ) ||
    (serviceAccountProperties?.[connectedTo] || []).includes(
      option["Subscription ID"],
    );

  const closeModal = () => {
    setOpenModal((prev) => false);
  };
  const handleEnable = () => {
    subscribe();
  };

  const handleClick = () => {
    if (!disableModal) {
      setOpenModal((prev) => true);
    }
    onClick();
  };

  const subscribe = () => {
    if (isServiceConnected) {
      // Service enabled
      onEnable(option);
      closeModal();
    } else if (!!serviceAccounts?.accounts?.[option["Provider"]]) {
      // Service not enabled but service account exists
      setLoading((prev) => true);
      dispatch(
        updateService({
          service_id:
            serviceAccounts?.accounts?.[option["Provider"]]?.[0]?.service_id,
          provider: option["Provider"],
          options: {
            verifier: option["Verifier"],
            add_group_ids: [connectedTo],
          },
          onSuccess: (r) => {
            dispatch(setServiceAccounts(r));
            onEnable(option);
            setLoading((prev) => false);
            closeModal();
          },
          onFailure: () => {
            setLoading((prev) => false);
          },
        }),
      );
    } else {
      // Service not enabled & service account doesn't exist
      setLoading((prev) => true);
      dispatch(
        createService({
          verifier: option["Verifier"],
          group_id: connectedTo,
          provider: option["Provider"],
          onSuccess: (res) => {
            dispatch(setServiceAccounts(res));
            setLoading((prev) => false);
            onEnable(option);
            closeModal();
          },
          onError: () => {
            setLoading((prev) => false);
          },
        }),
      );
    }
  };

  return (
    <>
      <ServiceInfoContent
        open={openModal}
        service={option}
        loading={loading}
        btnDisabled={selected}
        headerIcon={option?.icon_url ?? null}
        dialogBGImage={option?.cover_photo_url}
        btnLabel={selected ? "Enabled" : `Enable for ${connectedToLabel}`}
        dialogBGColors={THEME[option?.["Provider"]] ?? null}
        onClose={() => closeModal()}
        handleEnable={handleEnable}
      />
      <Card
        key={option["Verifier"] + "new"}
        className={clsx(classes.card, {
          "-selected": selected,
          "-expanded": !!expanded,
        })}
      >
        <CardActionArea disableRipple onClick={onExpand ?? handleClick}>
          <CardContent
            className={clsx(classes.cardContent, {centered: !!onExpand})}
          >
            <Box flex={1} className={classes.col}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{gap: THEME.spacing.md}}
              >
                <SvgIcon
                  className={clsx(classes.verifierIcon, {
                    "-selected": selected,
                    "-disabled": option.disabled,
                  })}
                  component={verifierIcons[option["Provider"]].icon}
                  viewBox={verifierIcons[option["Provider"]].viewBox}
                />
              </Box>
              <Typography
                variant="h1"
                className={clsx(classes.title, {
                  "-selected": selected,
                })}
              >
                {option["Name"]}
                {selected && " - ENABLED"}
              </Typography>
            </Box>
            {!!onExpand && (
              <ArrowIcon
                className={clsx(classes.arrowIcon, {"-selected": !!expanded})}
              />
            )}
            {!selected && !onExpand && (
              <Box>
                <PrimaryButton
                  size="small"
                  light={!disableImg}
                  label={btnLabel}
                  onClick={handleClick}
                />
              </Box>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
      {!!expanded && (
        <div className={classes.expandedCard}>
          <div className={classes.expandedContent}>
            <ServiceInfoContent
              open
              contentOnly
              service={option}
              loading={loading}
              headerIcon={option?.icon_url ?? null}
            />
          </div>
          <div className={classes.expandedActions}>
            <PrimaryButton
              size="small"
              color="secondary"
              disabled={selected}
              label={selected ? "Enabled" : `Enable for ${connectedToLabel}`}
              onClick={handleEnable}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VerificationServiceCard;
