import {
  // Account
  SET_USER,
  SET_USER_PROFILE,
  EDIT_ENSO_USER,
  SET_START_KEYS,
  SET_TOTALS,
  SET_KEY,
  SET_BKP_URL,
  FORCE_BILLING,
  SET_SERVICE_ACCOUNTS,

  // Houses
  SET_HOUSES,
  ADD_HOUSE,
  CHANGE_HOUSE,
  EDIT_HOUSE,
  DELETE_HOUSE,
  ADD_LISTING_GROUP,
  EDIT_LISTING_GROUP,
  DELETE_LISTING_GROUP,
  SET_SELECTED_LISITNG_GROUPS,

  // Guests
  SET_GUESTS,
  ADD_GUESTS,
  EDIT_GUEST,
  SET_INBOX_DATA,

  // Devices
  SET_DEVICES,
  EDIT_DEVICE,

  // Bookings
  SET_BOOKINGS,
  ADD_BOOKING,
  EDIT_BOOKING,
  SET_SELECTED_BOOKING,
  SET_SELECTED_GUEST_BOOKINGS,

  // Chatbot
  ADD_CHATBOT_RESPONSES,
  EDIT_CHATBOT,

  // Notifications
  SET_NOTIFICATIONS,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  ADD_ATTACHED_OBJECT,
  UPDATE_ATTACHED_OBJECT,

  // App
  SET_LOADED,
  SET_DEVICE_TYPE,
  SET_NAVBAR_VISIBILITY,
  ADD_LOADED_EVENTS,
  SET_API_START,
  SET_API_SUCCESS,
  SET_API_ERROR,
  OPEN_ALERT,
  CLOSE_ALERT,
  RESET_STORE,
  SET_LANGUAGE,
  OPEN_GENERAL_ERROR,
  CLOSE_GENERAL_ERROR,
  OPEN_GENERAL_ALERT_SUCCESS,
  CLOSE_GENERAL_ALERT_SUCCESS,
  SET_ERROR_PATH_BLOCKED,
  SET_SELECTED_VIEW_ITEM,
  SET_SELECTED_LISTING_GROUPS,
} from "../actionTypes";
import _ from "lodash";
import {
  stateCollectionAdd,
  stateCollectionDelete,
  stateCollectionEdit,
  stateCollectionSet,
} from "../../utilities/reducerUtilities";

const initial_state = {
  forceBilling: false,
  deviceType: "desktop",
  hideNavbar: false,
  guests: [],
  devices: [],
  devices_dict: [],
  guests_dict: {},
  chatbot_responses: [],
  house_data: [],
  house_data_dict: {},
  listing_groups: [],
  selected_listing_groups: {},
  listing_groups_dict: {},
  inbox_data: {},
  current_user: "",
  auth_key: "",
  notifications: [],
  notifications_dict: {
    id: {},
    guest_id: {},
    listing_id: {},
  },
  user_profile: {},
  bookings: [],
  bookings_dict: {
    booking_id: {},
    listing_id: {},
    guest_id: {},
  },
  service_accounts: {},
  bkpWizardUrl: "",
  alert: {
    open: false,
    type: "",
    message: "",
    id: "",
  },
  start_keys: {
    bookings: null,
    guests: null,
    listings: null,
    messages: null,
    notifications: null,
    connected_channel_accounts: null,
    reports: null,
  },
  loading: {
    reports: false,
    messages: false,
    listings: true,
    listing_content: false,
    guests: true,
    bookings: false,
    experiences: true,
    configured_experience: false,
    booking_checkinouts: true,
    devices: true,
    subscription: true,
    connected_channel_accounts: true,
    send_message: false,
  },
  errors: {
    reports: {hasError: false, message: ""},
    messages: {hasError: false, message: ""},
    listings: {hasError: false, message: ""},
    listing_content: {hasError: false, message: ""},
    guests: {hasError: false, message: ""},
    bookings: {hasError: false, message: ""},
    experiences: {hasError: false, message: ""},
    configured_experience: {hasError: false, message: ""},
    booking_checkinouts: {hasError: false, message: ""},
    devices: {hasError: false, message: ""},
    connected_channel_accounts: {hasError: false, message: ""},
  },
  errorPathBlocked: false,
  language: "en",
  general_error: {
    open: false,
    title: "",
  },
  general_alert_success: {
    open: false,
    title: "",
  },
  totals: {
    listings: "",
    guests: "",
    bookings: "",
  },
  // Selected
  selected_view_items: {
    dashboard: {
      item: null,
      props: {
        filter: "all",
        groups: [],
        upcomingRange: null,
        revenueStatsRange: null,
        BPStatsRange: null,
        guestsStatsRange: null,
        bookingStatsRange: null,
      },
    },
    messages: {item: null, props: {}},
    listings: {
      item: null,
      props: {
        isGroup: false,
        listingSection: null,
        selectedGroup: null,
        tab: null,
      },
    },
    settings: {
      item: null,
      props: {
        selectedDevice: null,
        selectedUser: null,
        connectedAccount: {
          account: null,
          accountType: null,
          integrationSpec: null,
        },
      },
    },
  },
  selected_booking_id: null,
  selected_guest_bookings: [],
};

function defaultReducer(state = initial_state, action) {
  switch (action.type) {
    case RESET_STORE:
      return initial_state;
    //Start Keys (for dynamoDB list pagination)
    case SET_START_KEYS:
      return {
        ...state,
        start_keys: {
          ...state.start_keys,
          [action.key]: action.val,
        },
      };
    case SET_TOTALS:
      return {
        ...state,
        totals: {...state.totals, [action.totalType]: action.total},
      };

    //Alerts
    case OPEN_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          open: true,
          type: action.alert_type,
          message: action.message,
          id: action.id,
        },
      };
    case CLOSE_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          open: false,
        },
      };

    // Manage state of API calls
    case SET_API_START:
      return {
        ...state,
        loading: {...state.loading, [action.objType]: true},
        errors: {
          ...state.errors,
          [action.objType]: {hasError: false, message: ""},
        },
      };
    case SET_API_SUCCESS:
      return {
        ...state,
        loading: {...state.loading, [action.objType]: false},
        errors: {
          ...state.errors,
          [action.objType]: {hasError: false, message: ""},
        },
      };
    case SET_API_ERROR:
      return {
        ...state,
        loading: {...state.loading, [action.objType]: false},
        errors: {
          ...state.errors,
          [action.objType]: {hasError: true, message: action.message || ""},
        },
      };

    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };

    //Houses
    case CHANGE_HOUSE:
      return {
        ...state,
        selected_house: action.house,
      };
    case SET_HOUSES:
      return stateCollectionSet({
        state,
        actionData: action.house_data,
        name: "house_data",
        keyField: "listing_id",
      });
    case ADD_HOUSE:
      return stateCollectionAdd({
        state,
        actionData: action.house_data,
        name: "house_data",
        keyField: "listing_id",
      });
    case EDIT_HOUSE:
      return stateCollectionEdit({
        state,
        actionData: action.house,
        name: "house_data",
        keyField: "listing_id",
      });
    case DELETE_HOUSE:
      return stateCollectionDelete({
        state,
        keyValue: action.house_id,
        name: "house_data",
        keyField: "listing_id",
      });
    case ADD_LISTING_GROUP:
      return stateCollectionAdd({
        state,
        actionData: action.listing_groups,
        name: "listing_groups",
        keyField: "group_id",
      });
    case EDIT_LISTING_GROUP:
      return stateCollectionEdit({
        state,
        actionData: action.listing_group,
        name: "listing_groups",
        keyField: "group_id",
      });
    case DELETE_LISTING_GROUP:
      return stateCollectionDelete({
        state,
        keyValue: action.group_id,
        name: "listing_groups",
        keyField: "group_id",
      });
    case SET_SELECTED_LISTING_GROUPS:
      return {...state, selected_listing_groups: action.listing_groups};

    //Bookings
    case SET_BOOKINGS:
      return stateCollectionSet({
        state,
        actionData: action.bookings,
        name: "bookings",
        keyField: ["booking_id", "listing_id", "guest_id"],
        multipleKeys: true,
        keyToGroupBy: "booking_id",
      });
    case ADD_BOOKING:
      return stateCollectionAdd({
        state,
        actionData: action.booking,
        name: "bookings",
        keyField: ["booking_id", "listing_id", "guest_id"],
        multipleKeys: true,
        keyToGroupBy: "booking_id",
      });
    case EDIT_BOOKING:
      return stateCollectionEdit({
        state,
        actionData: action.booking,
        name: "bookings",
        keyField: ["booking_id", "listing_id", "guest_id"],
        multipleKeys: true,
        keyToGroupBy: "booking_id",
      });
    case SET_SELECTED_BOOKING:
      return {...state, selected_booking_id: action.bookingId};
    case SET_SELECTED_GUEST_BOOKINGS:
      return {...state, selected_guest_bookings: action.guest_bookings};

    //Account
    case SET_USER:
      return {...state, current_user: action.current_user};
    case SET_USER_PROFILE:
      return {...state, user_profile: action.current_user};
    case EDIT_ENSO_USER:
      let new_user_profile = {...state.user_profile};
      const new_user = action.new_enso_user;
      const new_user_is_root =
        state.user_profile.enso_user_id === new_user.enso_user_id;

      if (new_user_is_root)
        new_user_profile = {...new_user_profile, ...new_user};

      const edited_enso_users = state.user_profile.enso_users.map((eu) => {
        if (eu.enso_user_id === new_user.enso_user_id) {
          return {...eu, ...new_user};
        } else return eu;
      });
      new_user_profile.enso_users = edited_enso_users;
      return {...state, user_profile: new_user_profile};
    case SET_BKP_URL:
      return {...state, bkpWizardUrl: action.bkpUrl};
    case FORCE_BILLING:
      return {...state, forceBilling: action.forceBilling};
    case SET_SERVICE_ACCOUNTS:
      return {
        ...state,
        service_accounts: {...state.service_accounts, ...action.accounts},
      };

    //Auth Key
    case SET_KEY:
      return {...state, auth_key: action.auth_key};

    //Guests
    case SET_GUESTS:
      return stateCollectionSet({
        state,
        actionData: action.guests,
        name: "guests",
        keyField: "guest_id",
      });
    case ADD_GUESTS:
      return stateCollectionAdd({
        state,
        actionData: action.guests,
        name: "guests",
        keyField: "guest_id",
        orderOptions: action.orderOptions,
      });
    case EDIT_GUEST:
      let newGuest = _.merge(
        {},
        state.guests_dict[action.guest.guest_id],
        action.guest,
      );
      return stateCollectionEdit({
        state,
        actionData: newGuest,
        name: "guests",
        keyField: "guest_id",
        orderOptions: action.orderOptions,
      });
    case SET_INBOX_DATA:
      return {...state, inbox_data: action.inboxData};

    //Devices
    case SET_DEVICES:
      return stateCollectionSet({
        state,
        actionData: action.devices,
        name: "devices",
        keyField: "device_id",
      });
    case EDIT_DEVICE:
      return stateCollectionEdit({
        state,
        actionData: action.device,
        name: "devices",
        keyField: "device_id",
      });

    //Chatbot Responses
    case ADD_CHATBOT_RESPONSES:
      return {
        ...state,
        chatbot_responses: _.unionBy(
          Object.assign([], state.chatbot_responses),
          action.responses,
          "id",
        ),
      };
    case EDIT_CHATBOT:
      let answered = Object.assign({}, state.chatbot_answered);
      return {
        ...state,
        chatbot_responses: state.chatbot_responses.map((obj) => {
          if (obj[0].id === action.response.id) {
            // increment/decrement total answered questions for a given field
            if (obj[0].body === false && action.response.body !== false) {
              answered[obj[0].field] += 1;
            } else if (
              obj[0].body !== false &&
              action.response.body === false
            ) {
              answered[obj[0].field] += -1;
            }
            return [action.response];
          } else {
            return obj[0];
          }
        }),
        user_profile: {
          ...state.user_profile,
          chatbot_answered: answered,
        },
      };

    //Notifications
    case SET_NOTIFICATIONS:
      return stateCollectionSet({
        state,
        actionData: action.notifications,
        name: "notifications",
        keyField: ["guest_id", "listing_id", "id"],
        multipleKeys: true,
        keyToGroupBy: "id",
      });
    case ADD_NOTIFICATION:
      return stateCollectionAdd({
        state,
        actionData: action.notifications,
        name: "notifications",
        keyField: ["guest_id", "listing_id", "id"],
        multipleKeys: true,
        keyToGroupBy: "id",
      });
    case DELETE_NOTIFICATION:
      const newNotifsById = {...state.notifications_dict.id};
      const newNotifsByGuestId = {
        ...state.notifications_dict.guest_id[action.notification.guest_id],
      };
      delete newNotifsById[action.notification.id];
      delete newNotifsByGuestId[action.notification.id];
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) => n.id !== action.notification.id,
        ),
        notifications_dict: {
          ...state.notifications_dict,
          id: newNotifsById,
          guest_id: {
            ...state.notifications_dict.guest_id,
            [action.notification.guest_id]: newNotifsByGuestId,
          },
        },
      };

    case ADD_ATTACHED_OBJECT:
      const mapperObj = action.mapperObject;
      const data = action.object;
      if (typeof mapperObj[1] === "string")
        return stateCollectionAdd({
          state,
          actionData: data,
          name: mapperObj[0],
          keyField: mapperObj[1],
        });
      else {
        const options = mapperObj[1];
        return stateCollectionAdd({
          state,
          actionData: data,
          name: mapperObj[0],
          keyField: options.keyField,
          multipleKeys: options.multipleKeys,
          keyToGroupBy: options.keyToGroupBy,
        });
      }
    case UPDATE_ATTACHED_OBJECT:
      const mapperObject = action.mapperObject;
      const nestedField = action.nestedField;
      let newValue = !!nestedField
        ? {
            ...state[`${mapperObject[0]}_dict`][action.object[mapperObject[1]]],
            [nestedField]: {...action.object},
          }
        : action.object;
      if (typeof mapperObject[1] === "string") {
        return stateCollectionEdit({
          state,
          actionData: newValue,
          name: mapperObject[0],
          keyField: mapperObject[1],
        });
      } else {
        const options = mapperObject[1];
        return stateCollectionEdit({
          state,
          actionData: newValue,
          name: mapperObject[0],
          keyField: options.keyField,
          multipleKeys: options.multipleKeys,
          keyToGroupBy: options.keyToGroupBy,
        });
      }

    case SET_LOADED:
      return {
        ...state,
        loaded: {
          ...state.loaded,
          [action.data_type]: action.data,
        },
      };
    case ADD_LOADED_EVENTS:
      return {
        ...state,
        loaded: {
          ...state.loaded,
          events: {
            ...state.loaded.events,
            [action.listing_id]: state.loaded.events[action.listing_id].concat(
              action.data,
            ),
          },
        },
      };

    case SET_SELECTED_VIEW_ITEM:
      return {
        ...state,
        selected_view_items: {
          ...state.selected_view_items,
          [action.view]: {
            item: action.item,
            props:
              action.view === "dashboard"
                ? {
                    ...state.selected_view_items.dashboard.props,
                    ...(action.props ?? {}),
                  }
                : (action.props ?? {}),
          },
        },
      };
    case OPEN_GENERAL_ERROR:
      return {...state, general_error: {open: true, ...action.errorProps}};
    case CLOSE_GENERAL_ERROR:
      return {...state, general_error: {open: false, title: ""}};
    case OPEN_GENERAL_ALERT_SUCCESS:
      return {
        ...state,
        general_alert_success: {open: true, ...action.successProps},
      };
    case CLOSE_GENERAL_ALERT_SUCCESS:
      return {...state, general_alert_success: {open: false, title: ""}};

    case SET_DEVICE_TYPE:
      return {...state, deviceType: action.deviceType};
    case SET_NAVBAR_VISIBILITY:
      return {...state, hideNavbar: !action.show};

    case SET_ERROR_PATH_BLOCKED:
      return {...state, errorPathBlocked: action.blocked};

    default:
      return state;
  }
}

export default defaultReducer;
