import React, {useContext} from "react";
import {Storage} from "aws-amplify";
import {useDispatch, useSelector} from "react-redux";
import {isMobile} from "react-device-detect";
import {withLDConsumer} from "launchdarkly-react-client-sdk";
// UI
import useSendInputStyles from "styles/useSendInputStyles";
import {Avatar, Badge, MenuList, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import SvgIcon from "@material-ui/core/SvgIcon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ImageIcon from "@material-ui/icons/InsertPhoto";
import {ReactComponent as RobotIcon} from "assets/icons/robot.svg";
import {ReactComponent as BoardingPassIcon} from "assets/icons/boarding-pass.svg";
// Custom
import {TemplateComponentsDialog} from "components/Dialogs/TemplateComponentsDialog";
import CommandsMenu from "components/MultiOption/CommandsMenu";
import TemplateInput from "components/TextFields/TemplateInput";
import EmailEditor from "components/Misc/EmailEditor.jsx";
import FullLoader from "components/Dialogs/FullLoader";
import CustomMenu from "core/menus/CustomMenu";
// Actions
import {
  closeGeneralError,
  openGeneralError,
  setNavbarVisibility,
} from "redux/actions/settingsActions";
import {
  appendMessage,
  editMessages,
  getGuestChatOptions,
  sendMessages,
} from "redux/actions/messagesActions";
import {getGuestKeycardLink} from "redux/actions/accountsActions";
import {
  editGuest,
  setInboxData,
  updateGuest,
} from "redux/actions/guestsActions";

// Utilities
import {
  asyncLoop,
  getAffectedInboxArray,
  newUuid,
  searchCommand,
  sleep,
  stripHtmlTags,
  validateFile,
} from "utilities/helperFunctions";
import useWindowUnloadEffect from "hooks/useWindowUnloadEffect";
import {useImageCompressor} from "utilities/fileUtilities";
import {inboxesWithNoCount} from "configuration/constants";
import useWindowDimensions from "hooks/useWindowDimensions";
import {useResizeDetector} from "react-resize-detector";
import {MessagesContext} from "routes/Messages";
import {THEME} from "configuration/settings.js";
import {useTranslation} from "react-i18next";
import config from "configuration/settings.js";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";

const commandsLoaderItems = [{loading: true}, {loading: true}, {loading: true}];

const SendInput = ({
  slc_channel,
  guest,
  resetSelectedChannel,
  guest_email,
  hasEmailChannel,
  current_user,
  user_profile,
  subject = "",
  defaultBody = "",
  openEmailEditor,
  schedule,
  hideChatbot,
  disableGutters,
  handleSaveScheduled,
  AItext = "",
  clearAIText,
  templates,
  whatsappAccountId,
  selectedTemplateId,
  setSelectedTemplateId,
  setMessageBodyHook,
  onMessageSent = () => {},
  onMessageError = () => {},
  flags,
}) => {
  const dispatch = useDispatch();
  const classes = useSendInputStyles({disableGutters});
  const inputRef = React.useRef(null);
  const {currentInbox, setModifiedData} = useContext(MessagesContext);
  const inboxes = useSelector((state) => state.defaultReducer.inbox_data);
  const {t} = useTranslation();
  const imgInputRef = React.useRef(null);
  const menuRef = React.useRef(null);
  const guestChatOptions = useSelector(
    (state) => state.messagesReducer.guest_chat_options,
  );
  const [body, setMessageBody] = React.useState(
    !!AItext ? AItext : defaultBody,
  );
  const [attachmentsAnchorEl, setAttachmentsAnchorEl] = React.useState(null);
  const [chatbotAnchorEl, setChatbotAnchorEl] = React.useState(null);
  const [shiftKeyPressed, setShiftKeyPressed] = React.useState(false);
  const [enterKeyPressed, setEnterKeyPressed] = React.useState(false);
  const [filesToUpload, setFilesToUpload] = React.useState([]);
  const [attachedImages, setAttachedImages] = React.useState([]);
  const [loaderOpen, setLoaderOpen] = React.useState({
    open: false,
    title: "Uploading...",
    subtitle: "",
  });
  const [progressObj, setProgressObj] = React.useState({loaded: 0, total: 100});
  const [compressEnabled, setCompressEnabled] = React.useState(false);
  const [templatePreviews, setTemplatePreviews] = React.useState({});
  const [autocompleteKey, setAutocompleteKey] = React.useState(true);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [templateMatchModalOpen, setTemplateMatchModalOpen] =
    React.useState(false);
  const [templatePairing, setTemplatePairing] = React.useState(false);
  const [emailContent, setEmailContent] = React.useState(null);
  const [commandText, setCommandText] = React.useState(null);
  const [AIEmailContent, setAIEmailContent] = React.useState(null);
  const [commands, setCommands] = React.useState([]);
  const [filteredCommands, setFilteredCommands] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [commandsAnchorEl, setCommandsAnchorEl] = React.useState(null);
  const [selectedCommandIndex, setSelectedCommandIndex] = React.useState(-1);
  const [BPLink, setBPLink] = React.useState("");
  const [loadingCommands, setLoadingCommands] = React.useState(false);
  const prevGuest = usePrevious(guest);
  const prevLoadingCommands = usePrevious(loadingCommands);
  const {width, height, ref} = useResizeDetector();
  const {width: windowWidth, height: windowHeight} = useWindowDimensions();
  const inputRowsMax = Math.floor((windowHeight * 0.7) / 16); // max input rows: (70% window height) / (rows 16px each)
  const showCannedResponses = !!flags.cannedResponses;
  const isEmail = slc_channel === 2;

  useWindowUnloadEffect(() => removeImages(), true);

  function setBody(b) {
    setMessageBodyHook && setMessageBodyHook(b);
    setMessageBody(b);
  }

  useImageCompressor({
    srcImgs:
      !!compressEnabled && filesToUpload[0] === "upload"
        ? filesToUpload.slice(1)
        : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
  });

  React.useEffect(() => {
    return () => {
      showNavbar(true);
    };
  }, []);
  React.useEffect(() => {
    setLoadingCommands((prev) => true);
    dispatch(
      getGuestChatOptions({
        guestId: guest.guest_id,
        onSuccess: () => {
          setLoadingCommands((prev) => false);
        },
        onError: () => {
          setLoadingCommands((prev) => false);
        },
      }),
    );
  }, [guest.guest_id]);

  React.useEffect(() => {
    let newCommands = guestChatOptions[guest.guest_id] || [];
    console.log("GOT NEW COMMANDS", newCommands);
    setCommands((prev) => [
      {
        type: "bp",
        title: "Boarding pass",
        subheader: "Add boarding pass",
        text: "insert_BP",
      },
      ...newCommands,
    ]);
  }, [guestChatOptions]);

  React.useEffect(() => {
    if (!!prevLoadingCommands && !loadingCommands) {
      if (slc_channel === 2) {
        let newContent = stripHtmlTags(emailContent?.content ?? "");
        const {results, searchText} = searchCommand(newContent, commands);
        setSearchText((prev) => searchText);
        setFilteredCommands(results);
      } else {
        const {results, searchText} = searchCommand(body, commands);
        setSearchText((prev) => searchText);
        setFilteredCommands(results);
      }
    }
  }, [loadingCommands]);

  React.useEffect(() => {
    if (
      !guest ||
      !guest.guest_id ||
      !guest.bookings?.length ||
      prevGuest?.guest_id === guest?.guest_id
    ) {
      return;
    }
    dispatch(
      getGuestKeycardLink(
        guest.guest_id,
        guest.bookings[guest.bookings.length - 1],
        (link) => {
          setBPLink((prev) => link);
        },
      ),
    );
  }, [guest]);

  React.useEffect(() => {
    if (slc_channel !== 2 || !showCannedResponses) {
      return;
    }
    let newContent = stripHtmlTags(emailContent?.content ?? "");
    if (newContent.includes("/")) {
      const {results, searchText} = searchCommand(newContent, commands);
      setSearchText((prev) => searchText);
      setFilteredCommands(results);
      let emailEditor = document.querySelector(".quill");
      setCommandsAnchorEl(emailEditor);
    } else {
      setSearchText((prev) => "");
      setFilteredCommands([]);
      setCommandsAnchorEl(null);
    }
  }, [slc_channel, emailContent]);

  React.useEffect(() => {
    if (!!AItext) {
      if (isEmail) {
        setAIEmailContent((prev) => AItext);
      } else if (slc_channel === -1 && !!hasEmailChannel) {
        setAIEmailContent((prev) => AItext);
      } else {
        setBody((prev) => AItext);
      }
      clearAIText();
    }
  }, [AItext]);

  React.useEffect(() => {
    if (!!prevGuest && prevGuest.guest_id !== guest.guest_id) {
      setEmailContent((prev) => null);
      setBody((prev) => defaultBody);
    }
  }, [guest]);

  React.useEffect(() => {
    if (!filesToUpload.length) return;
    if (filesToUpload[0] === "upload") {
      sleep(250).then(() => setCompressEnabled((prev) => true));
    }
  }, [filesToUpload]);

  React.useEffect(() => {
    if (!!commandsAnchorEl) {
      setSelectedCommandIndex(0);
    } else {
      setSelectedCommandIndex(-1);
    }
  }, [commandsAnchorEl]);

  const handleCloseMenu = () => setAttachmentsAnchorEl(null);
  const handleCloseChatbotMenu = () => setChatbotAnchorEl(null);
  const handleCloseError = () => dispatch(closeGeneralError());
  const showNavbar = (show) => dispatch(setNavbarVisibility(show));
  const handleCloseCannedResponses = () => setCommandsAnchorEl(null);

  const handleSelectResponse = (response, emailEnterKey) => {
    let prevText = "";
    let nextText = "";
    let lastIndexOfSlash = -1;
    let endOfCommandIndex = -1;
    if (!isEmail) {
      lastIndexOfSlash = body.lastIndexOf("/");
      endOfCommandIndex = body
        .substring(lastIndexOfSlash)
        .split(/[\s\n]+/)[0].length;
      prevText = body.substring(0, lastIndexOfSlash);
      nextText = body.substring(lastIndexOfSlash + endOfCommandIndex);
    }
    if (response.text === "insert_BP") {
      if (!isEmail) {
        setBody((prev) => prevText + BPLink + nextText);
      }
    } else if (!isEmail) {
      setBody((prev) => prevText + response.text + nextText);
    }
    setCommandText({
      search: "/" + searchText + (emailEnterKey ? "\n" : ""),
      result: response.text,
    });
    setSearchText((prev) => "");
    setFilteredCommands([]);
    setCommandsAnchorEl(null);
  };

  const handleError = (type, errorText) => {
    let message = "";
    let subtitle = "";
    switch (type) {
      case "sending_failed":
        message = "Failed to send message";
        subtitle =
          "There was a problem trying to send your message, please try again later";
        break;
      case "image_failed":
        message = "Failed to attach image";
        subtitle = errorText;
        break;
    }
    dispatch(
      openGeneralError({
        open: true,
        message: message,
        disableSeverity: true,
        subtitle: subtitle,
        onClose: handleCloseError,
      }),
    );
  };

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled((prev) => false);
    setFilesToUpload((prev) => []);
    handleUploadImages(outputImgs);
  };

  const removeImages = () => {
    if (attachedImages.length) {
      for (let index = 0; index < attachedImages.length; index++) {
        const img = attachedImages[index];
        Storage.remove(img.name);
      }
    }
  };

  const handleLoaderClose = (links, type) => {
    if (!links.length) {
      return;
    }
    if (type === "images") {
      setAttachedImages((prev) => links);
    }
    setLoaderOpen((prev) => ({...prev, open: false}));
  };

  const scrollToItem = (index) => {
    const menuItem = menuRef.current.querySelectorAll("li")[index];
    if (menuItem) {
      menuItem.scrollIntoView({block: "nearest", behavior: "smooth"});
    }
  };

  const handleKeyUp = (e) => {
    if (["Shift", "Enter"].includes(e.key)) {
      if (shiftKeyPressed && enterKeyPressed) {
        let trimmedBody = body.trim();
        if (slc_channel !== 2 && !!trimmedBody.length) {
          sendMessage(trimmedBody);
        }
      }
      if (e.key === "Shift") setShiftKeyPressed(() => false);
      if (e.key === "Enter") setEnterKeyPressed(() => false);
    }
  };

  const handleKeyDown = (e) => {
    if (!!commandsAnchorEl && !!filteredCommands.length) {
      if (filteredCommands.length > 0) {
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setSelectedCommandIndex((prevIndex) => {
            const newIndex =
              prevIndex < filteredCommands.length - 1 ? prevIndex + 1 : 0;
            scrollToItem(newIndex);
            return newIndex;
          });
        } else if (e.key === "ArrowUp") {
          e.preventDefault();
          setSelectedCommandIndex((prevIndex) => {
            const newIndex =
              prevIndex > 0
                ? prevIndex > filteredCommands.length - 1
                  ? filteredCommands.length - 1
                  : prevIndex - 1
                : filteredCommands.length - 1;
            scrollToItem(newIndex);
            return newIndex;
          });
        } else if (e.key === "Enter" && selectedCommandIndex >= 0) {
          e.preventDefault();
          handleSelectResponse(filteredCommands[selectedCommandIndex], true);
        } else if (e.key === "Escape") {
          setSearchText((prev) => "");
          setFilteredCommands([]);
          setCommandsAnchorEl(null);
        }
      }
    }
    if (isEmail) {
      return;
    }
    if (e.key === "Shift" && !shiftKeyPressed) setShiftKeyPressed(() => true);
    if (e.key === "Enter" && !enterKeyPressed) setEnterKeyPressed(() => true);
  };

  const handleInputChange = (e) => {
    if (shiftKeyPressed && enterKeyPressed) return false;
    const val = e.target.value;
    setBody(() => val);
    if (showCannedResponses) {
      if (val.includes("/")) {
        const {results, searchText} = searchCommand(val, commands);
        setSearchText((prev) => searchText);
        setFilteredCommands(results);
        setCommandsAnchorEl(ref.current);
      } else {
        setSearchText((prev) => "");
        setFilteredCommands([]);
        setCommandsAnchorEl(null);
      }
    }
  };

  const handleInputBlur = () => {
    if (isMobile) {
      showNavbar(true);
    }
  };
  const handleInputFocus = () => {
    if (isMobile) {
      showNavbar(false);
    }
  };

  const handleFile = (file) => {
    return new Promise((resolve) => {
      const {isValid, error} = validateFile(file);
      if (isValid) {
        setFilesToUpload((prev) => [...prev, file]);
      } else {
        handleError("image_failed", error);
      }
      resolve(true);
    });
  };

  const loadImages = async (files) => {
    await asyncLoop(files, async (f) => {
      await handleFile(f);
    });
    setFilesToUpload((prev) => {
      if (!prev.length) return prev;
      else return ["upload", ...prev];
    });
  };

  const handleRemoveImg = (img) => {
    const newAttachedImages = attachedImages.filter((i) => i.id !== img.id);
    setAttachedImages((prev) => newAttachedImages);
    Storage.remove(img.name);
  };

  const handleUploadImages = async (files) => {
    const multiple = files.length > 1;
    const totalProgress = {
      current: 0,
      totalSize: files.reduce((acc, curr) => acc + curr.size, 0),
    };
    setProgressObj((prev) => ({loaded: 0, total: totalProgress.totalSize}));
    setLoaderOpen((prev) => ({
      open: true,
      title: multiple ? "Uploading Images..." : "Uploading Image...",
      subtitle: "",
    }));
    const newLinks = [];
    await asyncLoop(files, async (item, index) => {
      const newId = newUuid();
      const imageName = `${current_user}/guests/${guest.guest_id}/attachment-${newId}`;
      if (multiple) {
        setLoaderOpen((prev) => ({
          ...prev,
          subtitle: `(${index + 1}/${files.length})`,
        }));
      }
      if (index > 0) {
        totalProgress.current += files[index - 1].size;
      }
      await Storage.put(imageName, item, {
        contentType: item.type,
        progressCallback(progress) {
          setProgressObj((prev) => ({
            ...prev,
            loaded: totalProgress.current + progress.loaded,
          }));
        },
      });
      newLinks.push({
        id: newId,
        url: `${config.s3.URL}${imageName}`,
        name: imageName,
      });
    });
    handleLoaderClose(newLinks, "images");
  };

  const onImageInputChange = (e) => {
    loadImages(e.target.files);
  };

  const clearCommandText = () => {
    setCommandText(null);
  };

  const sendMessage = (event, emailMsg) => {
    const message = emailMsg ?? body.trim();
    if (!message && !selectedTemplateId && !attachedImages.length) {
      return;
    }
    if (!schedule) {
      dispatch(
        editGuest({
          ...guest,
          unread_messages: false,
          unanswered_messages: false,
        }),
      );
    }
    setEmailContent((prev) => null);
    if (schedule) {
      handleSaveScheduled(message);
      return;
    }
    let channel = [
      "SMS",
      "airbnb",
      "email",
      "whatsapp",
      "vrbo",
      "homeaway",
      "booking_com",
    ][slc_channel];
    const date = Date.now();
    let sent = parseInt(date);
    const data = {
      body: {
        enso_user_id: user_profile.enso_user_id,
        guest_id: guest.guest_id,
        channel: channel,
        message: message,
        enso_key: guest.enso_key,
        attachments: !!attachedImages.length
          ? attachedImages.map((img) => img.url)
          : [],
        integration_template_id: selectedTemplateId,
        service_id: whatsappAccountId,
        sent,
      },
    };
    setBody((prev) => "");
    setAttachedImages((prev) => []);
    resetSelectedChannel();

    const newMessage = {...data.body, id: parseInt(date), sender_type: "user"};

    if (channel == "whatsapp" && selectedTemplateId) {
      dispatch(
        appendMessage(guest.guest_id, {
          ...{...data.body, message: templatePreviews?.[selectedTemplateId]},
          sent: parseInt(date),
          id: parseInt(date),
          sender_type: "user",
        }),
      );
      setAutocompleteKey(!autocompleteKey);
      setSelectedTemplateId(null);
    }

    onMessageSent();

    let inboxCountModified = false;
    let selectedInbox = currentInbox ?? "all";
    if (
      (!!guest.unanswered_messages || !!guest.unread_messages) &&
      !inboxesWithNoCount.includes(selectedInbox)
    ) {
      inboxCountModified = true;
      let newInbox = inboxes[selectedInbox];
      newInbox.total_count--;
      dispatch(setInboxData({...inboxes, [selectedInbox]: newInbox}));
    }

    // Decrement affected inbox totals
    let updatedGuest = {
      ...guest,
      unanswered_messages: false,
      unread_messages: false,
    };
    setModifiedData({
      forceInboxesUpdate: true,
      subtractFromInbox: getAffectedInboxArray(updatedGuest, selectedInbox),
    });

    dispatch(
      sendMessages(
        data,
        newMessage,
        (response) => {
          dispatch(
            updateGuest({
              guest_id: guest.guest_id,
              multipleFields: {
                unread_messages: false,
                unanswered_messages: false,
              },
              disableAlert: true,
            }),
          );
          dispatch(editMessages(response, parseInt(date)));
        },
        () => {
          if (inboxCountModified) {
            let newInbox = inboxes[selectedInbox];
            newInbox.total_count++;
            dispatch(setInboxData({...inboxes, [selectedInbox]: newInbox}));
          }
          // Restore affected inbox totals
          setModifiedData({
            forceInboxesUpdate: true,
            addToInbox: getAffectedInboxArray(guest, selectedInbox),
          });
          handleError("sending_failed");
          onMessageError();
        },
      ),
    );
  };

  const getKeycard = () => {
    setAttachmentsAnchorEl((prev) => null);
    setBody((prev) => prev + BPLink);
  };

  const handleImageAttachment = () => {
    setAttachmentsAnchorEl((prev) => null);
    imgInputRef.current.click();
  };

  const toggleChatbot = () => {
    setChatbotAnchorEl((prev) => null);
    dispatch(
      updateGuest({
        guest_id: guest.guest_id,
        field: "chatbot_enabled",
        val: !guest.chatbot_enabled,
        disableAlert: true,
      }),
    );
  };

  function getMenus() {
    return (
      <>
        <CustomMenu
          zIndex={3}
          open={!!attachmentsAnchorEl}
          anchorEl={attachmentsAnchorEl}
          placement="top-start"
          onClose={handleCloseMenu}
          content={
            <MenuList>
              <MenuItem onClick={getKeycard}>
                <ListItemIcon className={classes.listItemIcon}>
                  <SvgIcon viewBox="0 -1 21 16" component={BoardingPassIcon} />
                </ListItemIcon>
                <Typography id="keycard">Boarding Pass</Typography>
              </MenuItem>
              <MenuItem onClick={handleImageAttachment}>
                <ListItemIcon className={classes.listItemIcon}>
                  <ImageIcon fontSize="small" />
                </ListItemIcon>
                <Typography>Image</Typography>
              </MenuItem>
            </MenuList>
          }
        />
        <CustomMenu
          zIndex={3}
          open={!!chatbotAnchorEl}
          anchorEl={chatbotAnchorEl}
          placement="top-start"
          onClose={handleCloseChatbotMenu}
          content={
            <MenuList>
              <MenuItem onClick={toggleChatbot}>
                {guest.chatbot_enabled ? "Disable Chatbot" : "Enable Chatbot"}
              </MenuItem>
            </MenuList>
          }
        />
      </>
    );
  }

  function whatsappConvoInitiated() {
    let now = Date.now();
    let WAExpiry = guest?.metadata?.whatsapp_expiry ?? now;
    if (WAExpiry.toString().length === 10) {
      WAExpiry = WAExpiry * 1000;
    }
    return WAExpiry > now;
  }

  function getWhatsappInput() {
    if (whatsappConvoInitiated()) {
      setSelectedTemplateId(null);
      return getBasicInput("whatsapp", true);
    } else {
      return (
        <div className={classes.inputContainer}>
          <FormControl className={clsx(classes.formControl)}>
            <TemplateInput
              guest={guest}
              templates={templates}
              whatsappAccountId={whatsappAccountId}
              templatePreviews={templatePreviews}
              setTemplatePreviews={setTemplatePreviews}
              selectedTemplateId={selectedTemplateId}
              setSelectedTemplateId={setSelectedTemplateId}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              templateMatchModalOpen={templateMatchModalOpen}
              setTemplateMatchModalOpen={setTemplateMatchModalOpen}
              templatePairing={templatePairing}
              autocompleteKey={autocompleteKey}
              fullWidth
              className={classes.autocompleteInput}
              style={{maxHeight: 34}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        {...params.InputProps.endAdornment}
                        position="end"
                        className={clsx(classes.inputAdornment, {
                          [classes.mb2]: height > 30 && height < 48,
                          [classes.mb4]: height >= 48,
                        })}
                      >
                        <Tooltip title="Shift + Enter to send message">
                          <span>
                            <Button
                              variant="contained"
                              onClick={sendMessage}
                              size="small"
                              color="primary"
                              disableElevation
                              id="create-schedule-message-btn"
                              disabled={
                                (!body && !selectedTemplateId) ||
                                !selectedTemplate?.paired
                              }
                              className={clsx(
                                classes.saveBtn,
                                classes["whatsapp"],
                              )}
                            >
                              {schedule ? "Schedule" : "Send"}
                            </Button>
                          </span>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </div>
      );
    }
  }

  function getBasicInput(channel, hideAttachments) {
    return (
      <>
        <div className={classes.inputContainer}>
          <FormControl className={clsx(classes.formControl)}>
            {!!attachedImages.length && (
              <div className={classes.attachments}>
                {attachedImages.map((img) => (
                  <Badge
                    key={img.id}
                    overlap="rectangular"
                    badgeContent={
                      <IconButton
                        disableRipple
                        className={classes.badge}
                        onClick={() => handleRemoveImg(img)}
                      >
                        <CloseIcon className="badge-icon" />
                      </IconButton>
                    }
                  >
                    <Avatar
                      src={img.url}
                      variant="rounded"
                      className="attached-image"
                    >
                      <ImageIcon />
                    </Avatar>
                  </Badge>
                ))}
              </div>
            )}
            <OutlinedInput
              value={body}
              multiline
              ref={ref}
              maxRows={inputRowsMax}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              placeholder={
                showCannedResponses
                  ? t("type_slash_for_commands")
                  : `${channel} Message`
              }
              className={classes.input}
              id="messageInput"
              classes={{
                notchedOutline: classes.outlinedInput,
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  className={clsx(classes.inputAdornment, {
                    [classes.mb2]: height > 30 && height < 48,
                    [classes.mb4]: height >= 48,
                  })}
                >
                  {!hideAttachments && (
                    <Tooltip title="Attachments">
                      <IconButton
                        className={classes.iconButton}
                        onClick={(e) => setAttachmentsAnchorEl(e.currentTarget)}
                      >
                        <AddIcon
                          style={{color: THEME[channel.toLowerCase()]}}
                          id="attachments"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!hideChatbot && (
                    <Tooltip title="Chatbot">
                      <IconButton
                        className={classes.iconButton}
                        onClick={(e) => setChatbotAnchorEl(e.currentTarget)}
                      >
                        <SvgIcon
                          viewBox="0 0 22 19"
                          htmlColor={
                            guest.chatbot_enabled
                              ? THEME[channel.toLowerCase()]
                              : THEME.typography
                          }
                          className={classes.svgIcon}
                          component={RobotIcon}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Shift + Enter to send message">
                    <span>
                      <Button
                        variant="contained"
                        onClick={sendMessage}
                        size="small"
                        color="primary"
                        disableElevation
                        disabled={!body && !attachedImages.length}
                        className={clsx(
                          classes.saveBtn,
                          classes[channel.toLowerCase()],
                        )}
                      >
                        {schedule ? "Schedule" : "Send"}
                      </Button>
                    </span>
                  </Tooltip>
                </InputAdornment>
              }
            />
          </FormControl>
          {getMenus()}
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      <TemplateComponentsDialog
        template={selectedTemplate}
        open={templateMatchModalOpen}
        setOpen={setTemplateMatchModalOpen}
        serviceId={whatsappAccountId}
        setInProgress={setTemplatePairing}
      />

      <input
        id="image-attachment"
        ref={imgInputRef}
        style={{display: "none"}}
        type="file"
        multiple
        accept="image/*"
        onChange={onImageInputChange}
      />
      <FullLoader
        open={loaderOpen.open}
        loadingText={loaderOpen.title}
        subtitle={loaderOpen.subtitle}
        disableDismiss
        progress={progressObj}
      />
      {showCannedResponses && (
        <CommandsMenu
          menuRef={menuRef}
          searchText={searchText}
          data={
            loadingCommands
              ? [...filteredCommands, ...commandsLoaderItems]
              : filteredCommands
          }
          selectedIndex={selectedCommandIndex}
          anchorEl={commandsAnchorEl}
          onClose={handleCloseCannedResponses}
          onSelect={handleSelectResponse}
        />
      )}
      {
        {
          0: getBasicInput("SMS"),
          1: getBasicInput("Airbnb"),
          2: (
            <EmailEditor
              email_signature={user_profile.email_signature}
              guest={guest}
              guest_email={guest_email}
              subject={subject}
              schedule={schedule}
              commandText={commandText}
              clearCommandText={clearCommandText}
              externalEmailContent={emailContent}
              onKeyDown={handleKeyDown}
              AIprops={{content: AIEmailContent}}
              cleanAIContent={() => setAIEmailContent((prev) => null)}
              setExternalEmailContent={(content) =>
                setEmailContent((prev) => content)
              }
              sendMessage={sendMessage}
              closeFn={() => resetSelectedChannel()}
            />
          ),
          3: getWhatsappInput(),
          4: getBasicInput("VRBO"),
          5: getBasicInput("Home Away"),
          6: getBasicInput("Booking.com"),
        }[slc_channel]
      }
    </React.Fragment>
  );
};

export default withLDConsumer()(SendInput);
