import React from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
// UI
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import useExperiencePanelStyles from "styles/useExperiencesPanelStyles";
// Custom
import {EmptyExperienceGrid} from "components/Helpers/EmptyPanels";
import {EmptyListPanel} from "components/Helpers/EmptyPanels";
import CustomCardHeader from "core/cards/CustomCardHeader";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import FullLoader from "components/Dialogs/FullLoader";
import UpdateSession from "components/Dialogs/UpdateSession";
import CustomSwitch from "core/switches/CustomSwitch";
import ExperienceTypeIcon from "../Panels/Experience/ExperienceTypeIcon";
import TemplateCard from "components/Cards/TemplateCard";
import CloseIconButton from "core/buttons/CloseIconButton";
import DeleteIconButton from "core/buttons/DeleteIconButton";
import EditIconButton from "core/buttons/EditIconButton";
import CreateFromExistingList from "./CreateFromExistingList";
import CreationButton from "components/MultiOption/CreationButton";
import GJEInfoCard from "components/Cards/GJEInfoCard";
// Actions
import {
  editHouse,
  editListingGroup,
  getListingGroupDetails,
} from "redux/actions/listingsActions";
import {
  deleteExperience,
  updateExperience,
} from "redux/actions/experiencesActions";
// Utilities
import {
  compareObjectsByName,
  getExperienceConnectionLabel,
} from "utilities/formatUtilities";
import {experienceTypesLabels} from "configuration/constants";
import {THEME} from "configuration/settings";
import clsx from "clsx";
import _ from "lodash";

const availableExpKeys = [
  "inq",
  "cnf",
  "pci",
  "ci",
  "cu",
  "pco",
  "co",
  "ps",
  "mk",
  "msg",
];

export default function ExperienceList({
  layoutType,
  experienceType,
  selectedExperience,
  noTitle,
  listingId,
  setSelectedExperience,
  createExperience,
  isParentLoading,
  isLive = true,
  closeOpenExperience,
  disableEdit,
  disableGutters,
  customList,
  extraGutters,
  setCustomList,
  updateGJ,
  selectTemplate,
  isGroupView,
}) {
  const classes = useExperiencePanelStyles({extraGutters});
  const dispatch = useDispatch();
  const {group_id, step, card} = useParams();
  const prevExp = React.useRef(null);
  const availableExperiences = useSelector(
    (state) => state.experiencesReducer.available_experiences,
  );
  const configuredExperiences = useSelector(
    (state) => state.experiencesReducer.configured_experiences,
  );
  const houses = useSelector((state) => state.defaultReducer.house_data_dict);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const loadingConfiguredExp = useSelector(
    (state) => state.defaultReducer.loading,
  ).configured_experiences;
  const loadingPresets = useSelector(
    (state) => state.defaultReducer.loading,
  ).experience_presets;
  const [checkedValues, setCheckedValues] = React.useState({});
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [updateSession, setUpdateSession] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [showExistingExperiences, setShowExistingExperiences] =
    React.useState(false);
  const hasConfiguredExperiences = React.useMemo(() => {
    if (!!listingId) {
      return !!Object.keys(houses[listingId]?.listing_experiences || {}).filter(
        (k) => k !== "hid",
      ).length;
    } else if (isGroupView) {
      return true;
    } else {
      return false;
    }
  }, [listingId, houses, configuredExperiences, customList]);

  React.useEffect(() => {
    if (!hasConfiguredExperiences) {
      setCheckedValues((prev) => ({}));
      return;
    }

    const newConfiguredExp = {};
    if (!!listingId) {
      for (const type in houses[listingId].listing_experiences) {
        _.each(houses[listingId].listing_experiences[type], (exp) => {
          if (exp.connected_to === "ALL") {
            newConfiguredExp[exp.experience_id] =
              !!configuredExperiences.experienceId[exp.experience_id]?.enabled;
          } else {
            newConfiguredExp[exp.experience_id] = exp.enabled;
          }
        });
      }
    } else if (!!customList) {
      _.each(customList, (exp) => {
        newConfiguredExp[exp.experience_id] = exp.enabled;
      });
    }

    setCheckedValues((prev) => newConfiguredExp);
  }, [listingId, houses, configuredExperiences, customList]);

  const onExperienceSelect = (experience, isDifferentExp) => () => {
    if (!!customList) {
      updateGJ();
    }
    setSelectedExperience(
      isDifferentExp ? experience : null,
      "config_exp",
      isDifferentExp,
    );
  };

  const openTemplateConfiguration = (template) => () => {
    const newExp = {
      actions: template.actions,
      connected_to: template.connected_to,
      description: template.description,
      enabled: template.enabled,
      experience_type: template.experience_type,
      name: template.name,
    };
    selectTemplate(newExp, "config_exp", true, false);
  };

  const onDeleteSuccess = (exp) => {
    setLoading((prev) => false);
    closeDeleteConfirmation();
    setSelectedItem((prev) => null);
    if (isGroupView) {
      dispatch(
        getListingGroupDetails({groupId: group_id, section: step, card: card}),
      );
    } else {
      let newExperiences = {...houses[listingId].listing_experiences};
      newExperiences[exp.experience_type] = newExperiences[
        exp.experience_type
      ].filter((ex) => ex.experience_id !== exp.experience_id);
      let newListing = {
        ...houses[listingId],
        listing_experiences: newExperiences,
      };
      dispatch(editHouse(newListing));
    }
  };

  const onDeleteError = () => {
    setLoading((prev) => false);
    closeDeleteConfirmation();
    setSelectedItem((prev) => null);
  };

  const handleToggleEnabled = (exp) => (e) => {
    const name = e.target.name;
    const checked = e.target.checked;
    prevExp.current = exp;
    const newExperience = {
      experience: {...exp, enabled: checked, delete_scheduled: true},
      experience_id: exp.experience_id,
    };
    setCheckedValues((prev) => ({...prev, [name]: checked}));
    updateCustomList(newExperience.experience);
    dispatch(
      updateExperience({
        body: newExperience,
        listingId,
        onSuccess: (response) => updateCustomList(response.experience),
        onError: () => updateCustomList(prevExp.current),
      }),
    );
  };

  const updateCustomList = (newExp) => {
    if (!!customList) {
      const newExperiences = customList.map((ce) => {
        if (ce.experience_id === newExp.experience_id) {
          return newExp;
        } else {
          return ce;
        }
      });
      // setCustomList(newExperiences)
    }
  };

  const handleExperienceDelete = (exp) => () => {
    setSelectedItem((prev) => exp);
    handleDelete({exp});
    setOpenDeleteConfirmation((prev) => true);
  };

  const handleDelete = ({exp, confirm}) => {
    if (isGroupView && !!confirm) {
      const newGroup = listingGroups[group_id];
      const newData = newGroup.guest_journey.sections[step].cards[
        card
      ].data.filter((item) => item.experience_id !== exp.experience_id);
      newGroup.guest_journey.sections[step].cards[card].data = newData;
      dispatch(editListingGroup(newGroup));
    }

    if (!!confirm) {
      setLoading((prev) => "Deleting experience...");
    }
    if (confirm && !isGroupView && closeOpenExperience) {
      closeOpenExperience();
    }

    dispatch(
      deleteExperience({
        listingId,
        experienceId: exp.experience_id,
        experienceType: exp.experience_type,
        deleteScheduled: true,
        updateId:
          !!confirm && !!updateSession
            ? updateSession.update_session.update_id
            : null,
        onSuccess: () => onDeleteSuccess(exp),
        onError: onDeleteError,
        onUpdate: !confirm
          ? (update) => {
              setUpdateSession((prev) => update);
              setLoading((prev) => false);
              setOpenDeleteConfirmation((prev) => true);
            }
          : null,
      }),
    );
  };

  const closeDeleteConfirmation = () => {
    setOpenDeleteConfirmation((prev) => false);
    setUpdateSession((prev) => null);
  };

  const onCreateFromScratch = () => {
    createExperience();
  };
  const onCreateFromExisting = () => {
    setShowExistingExperiences((prev) => true);
  };

  const handleExperienceSelect = (exp) => {
    const newExp = {
      ...exp,
      experience_type: experienceType ?? exp.experience_type,
      connected_to: group_id,
      actions: exp.actions.map((act) => ({
        ...act,
        id: undefined,
        trigger_id: undefined,
        actions: act.actions.map((a) => ({...a, id: undefined})),
      })),
    };
    delete newExp.created_at;
    delete newExp.enso_key;
    delete newExp.id;
    delete newExp.experience_id;
    setShowExistingExperiences((prev) => false);
    setSelectedExperience(newExp, "config_exp", true, false);
  };

  function getHeader() {
    if (!!noTitle) {
      return null;
    }
    return (
      <CustomCardHeader
        title={
          !!group_id
            ? (listingGroups[group_id]?.guest_journey?.sections[experienceType]
                .cards.experiences.label ?? "Message templates")
            : "Message templates"
        }
        className={classes.titleRow}
        action={
          <>
            <Box mr={3}>
              <CreationButton
                type="experience"
                disabled={loading}
                onScratch={onCreateFromScratch}
                onExisting={onCreateFromExisting}
              />
            </Box>
            <CloseIconButton
              disablePadding
              sm
              onClick={() => closeOpenExperience()}
            />
          </>
        }
      />
    );
  }

  function getExperienceList(expList = [], expType) {
    if (!expList.length) {
      return null;
    }
    return (
      <List
        component="nav"
        dense
        id={"current-experiences-tab"}
        className={classes.list}
      >
        {expList.map((exp, index) => {
          if (!!expType && exp.experience_type !== expType) {
            return null;
          }
          const isSelected =
            selectedExperience?.experience_id === exp.experience_id;
          return (
            <div
              key={exp.experience_id}
              className={clsx(classes.item, {
                [classes.gutters]: !disableGutters,
              })}
              id={`div-exp-item-${index}`}
            >
              <Box flexGrow={1}>
                <ListItem
                  button
                  disableRipple
                  onClick={onExperienceSelect(exp, true)}
                  className={clsx(classes.listItem, {
                    "-full-pad": !disableEdit,
                    "-partial-pad": !!disableEdit && !!isLive,
                  })}
                  classes={{selected: classes.selected}}
                  selected={isSelected}
                  id={`exp-item-${index}`}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <div className={classes.itemTitleRow}>
                        <Typography
                          variant="button"
                          className={classes.itemText}
                        >
                          {exp.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          className={clsx(classes.ellipsis, classes.tag)}
                        >
                          {getExperienceConnectionLabel(
                            exp,
                            houses,
                            listingGroups,
                          )}
                        </Typography>
                      </div>
                    }
                  />
                  {!disableEdit ? (
                    <ListItemSecondaryAction
                      className={clsx(
                        classes.actionsContainer,
                        "-actions-container",
                      )}
                    >
                      {(!isGroupView || isLive) && (
                        <div className={classes.switch}>
                          <CustomSwitch
                            disabled={disableEdit}
                            name={exp.experience_id}
                            checked={checkedValues[exp.experience_id] || false}
                            onChange={handleToggleEnabled(exp)}
                          />
                        </div>
                      )}
                      <EditIconButton
                        disablePadding
                        onClick={onExperienceSelect(exp, true)}
                      />
                      <DeleteIconButton
                        disablePadding
                        marginLeft={2}
                        onClick={handleExperienceDelete(exp)}
                      />
                    </ListItemSecondaryAction>
                  ) : (
                    !!isLive && (
                      <ListItemSecondaryAction
                        className={clsx(
                          classes.actionsContainer,
                          "-actions-container",
                        )}
                      >
                        <div className={classes.switch}>
                          <CustomSwitch
                            disabled={disableEdit}
                            name={exp.experience_id}
                            checked={checkedValues[exp.experience_id] || false}
                            onChange={handleToggleEnabled(exp)}
                          />
                        </div>
                      </ListItemSecondaryAction>
                    )
                  )}
                </ListItem>
              </Box>
            </div>
          );
        })}
      </List>
    );
  }

  function getListContent() {
    let index = -1;
    let recommendedExperiences = availableExperiences[experienceType];
    return (
      <div
        className={clsx(classes.content, classes.listContent, {
          pt0: !!hasConfiguredExperiences && isGroupView,
        })}
        id={"experiences-tab"}
      >
        {!hasConfiguredExperiences ? (
          <EmptyListPanel
            loading={isParentLoading || loadingConfiguredExp}
            noTitle
            noSearchbar
            list="experiences"
            disableTopSpacing
            useSectionHeaders
          />
        ) : (
          <>
            {isGroupView && (
              <Box>
                <GJEInfoCard type={`experiences.${step}`} banner />
              </Box>
            )}
            {isGroupView && !!customList.length && (
              <Box marginBottom={4} paddingX={4}>
                <Typography variant="h1">{"Active experiences"}</Typography>
              </Box>
            )}
            {isGroupView
              ? getExperienceList(customList.sort(compareObjectsByName))
              : availableExpKeys.map((expType) => {
                  let expList = [];
                  if (!!listingId) {
                    if (!houses[listingId].listing_experiences[expType]) {
                      return null;
                    }
                    expList =
                      houses[listingId].listing_experiences[expType].sort(
                        compareObjectsByName,
                      );
                  }
                  index++;

                  return (
                    <React.Fragment key={expType}>
                      <div
                        className={clsx(classes.row, "mb-2", {
                          "mt-4": index > 0,
                          [classes.gutters]: !disableGutters,
                        })}
                      >
                        <ExperienceTypeIcon
                          type={expType}
                          className={classes.icon}
                        />
                        <Typography variant="h1">
                          {experienceTypesLabels[expType]}
                        </Typography>
                      </div>
                      {getExperienceList(expList, expType)}
                    </React.Fragment>
                  );
                })}
            {!!recommendedExperiences?.length && (
              <Box my={4} px={4}>
                <Typography variant="h1">{"Recommended"}</Typography>
              </Box>
            )}
            {!!recommendedExperiences?.length && (
              <Box
                display="flex"
                flexDirection="column"
                style={{gap: THEME.spacing.md}}
                px={4}
              >
                {availableExperiences[experienceType]?.map((exp) => (
                  <TemplateCard
                    template={exp}
                    key={exp.title}
                    type="experience"
                    title={exp.title}
                    urlIcon={exp.icon_url}
                    subheader={exp.subtitle}
                    bgImage={exp.picture_url}
                    category={experienceType}
                    disabled={
                      !!selectedExperience?.preset_id &&
                      exp.preset_id !== selectedExperience.preset_id
                    }
                    bgPosition={
                      ["cu", "ps"].includes(experienceType)
                        ? "bottom"
                        : "center"
                    }
                    onClick={openTemplateConfiguration(exp)}
                  />
                ))}
              </Box>
            )}
          </>
        )}
      </div>
    );
  }

  const experienceCards = (
    <div
      className={clsx(classes.content, {
        pt0:
          !showExistingExperiences &&
          !!availableExperiences[experienceType]?.length,
      })}
    >
      {showExistingExperiences ? (
        <CreateFromExistingList
          resource="experience"
          onSelect={handleExperienceSelect}
        />
      ) : (
        <>
          {!availableExperiences[experienceType]?.length ? (
            <EmptyExperienceGrid loading={loadingPresets} />
          ) : (
            <>
              <Box mx={-4}>
                <GJEInfoCard type={`experiences.${step}`} banner />
              </Box>
              <Box marginBottom={4}>
                <Typography variant="h1">{"Recommended"}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                style={{gap: THEME.spacing.md}}
              >
                {availableExperiences[experienceType]?.map((exp) => (
                  <TemplateCard
                    template={exp}
                    key={exp.title}
                    type="experience"
                    title={exp.title}
                    urlIcon={exp.icon_url}
                    subheader={exp.subtitle}
                    bgImage={exp.picture_url}
                    category={experienceType}
                    disabled={
                      !!selectedExperience?.preset_id &&
                      exp.preset_id !== selectedExperience.preset_id
                    }
                    bgPosition={
                      ["cu", "ps"].includes(experienceType)
                        ? "bottom"
                        : "center"
                    }
                    onClick={openTemplateConfiguration(exp)}
                  />
                ))}
              </Box>
            </>
          )}
        </>
      )}
    </div>
  );

  const loadingModal = (
    <FullLoader
      open={!!loading}
      disableDismiss
      onClose={() => setLoading((prev) => false)}
      loadingText={loading}
    />
  );

  const confirmDeleteModal = (!!updateSession || openDeleteConfirmation) && (
    <ConfirmDialog
      disableDismiss
      open={!loading}
      onClose={closeDeleteConfirmation}
      title="Delete experience?"
      message={
        <>
          {
            "This experience will be permanently deleted, and all currently scheduled experiences, if any, will be cancelled."
          }
          {!updateSession ? (
            <Box
              mt={4}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            !!updateSession?.update_session?.payload?.length && (
              <>
                <br />
                {"Deleting this experience will affect the following:"}
                <UpdateSession updates={updateSession.update_session.payload} />
              </>
            )
          )}
        </>
      }
      confirmLabel="Delete"
      confirmDisabled={!updateSession}
      confirmAction={() => handleDelete({exp: selectedItem, confirm: true})}
      cancelLabel="Cancel"
      cancelAction={closeDeleteConfirmation}
    />
  );

  return (
    <div className={classes.root} id={"experiences-tab"}>
      {loadingModal}
      {confirmDeleteModal}
      {getHeader()}
      {layoutType === "list" ? (
        showExistingExperiences ? (
          <div className={classes.content}>
            <CreateFromExistingList
              resource="experience"
              onSelect={handleExperienceSelect}
            />
          </div>
        ) : (
          getListContent()
        )
      ) : (
        experienceCards
      )}
    </div>
  );
}
