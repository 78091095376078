import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Waypoint} from "react-waypoint";
// UI
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import RightArrowIcon from "@material-ui/icons/ArrowForwardIos";
import Arrow from "@material-ui/icons/NavigateNext";
import useHouseListStyles from "styles/useHouseListStyles";
//Custom
import EmptyContentText from "components/Misc/EmptyContentText";
import {EmptyListPanel} from "components/Helpers/EmptyPanels";
import VirtualizedList from "components/Lists/VirtualizedList";
import CustomCardHeader from "core/cards/CustomCardHeader";
import SearchBar from "core/bars/SearchBar";
import PrimaryButton from "core/buttons/PrimaryButton";
import WarningAlert from "core/alerts/WarningAlert";
// Actions
import {getHouseContent, getHouses} from "redux/actions/listingsActions";
import {setStartKeys} from "redux/actions/settingsActions";
// Utilities
import {getEncodedFilters} from "utilities/helperFunctions.js";
import {compareObjectsByName} from "utilities/formatUtilities";
import {FIXED_SIZES} from "configuration/settings.js";
import {isTablet} from "react-device-detect";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";
import qs from "qs";

const filterKeys = [
  "listing_active",
  "listing_tags",
  "listing_errors",
  "ungrouped",
];
const sortKeys = ["alphabetical"];

export default function ListingGroupList({
  selectHouse,
  selected,
  multiselect,
  customTitle,
  disableGutters,
  disableSelect,
  createListing,
  customActions,
  customItem,
  hideTitle,
  disableSearch,
  hideScrollbar = true,
  itemSize = null,
  label = "Listings",
  useGroups,
  selectGroup,
  createGroup,
  groupOpen,
  setGroupOpen,
  detailsPanelClosed,
  disableContentEdit,
  groupId,
}) {
  const styleProps = {disableGutters, disableSelect, detailsPanelClosed};
  const classes = useHouseListStyles(styleProps);
  const dispatch = useDispatch();
  const didMount = React.useRef(false);
  const isLoadingMore = React.useRef(false);
  const isLoadingGroupListings = React.useRef(false);
  const listRef = React.useRef(null);
  const showLastGroupID = React.useRef(false);
  // Selectors
  const totals = useSelector((state) => state.defaultReducer.totals);
  const houses = useSelector((state) => state.defaultReducer.house_data);
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups,
  );
  const listingGroupObj = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const startKeys = useSelector((state) => state.defaultReducer.start_keys);
  const current_user = useSelector(
    (state) => state.defaultReducer.current_user,
  );
  const houses_dict = useSelector(
    (state) => state.defaultReducer.house_data_dict,
  );
  const loadingHouses = useSelector(
    (state) => state.defaultReducer.loading,
  ).listings;
  const hasError = useSelector((state) => state.defaultReducer.errors).listings
    .hasError;
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  // State
  const [data, setData] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [totalData, setTotalData] = React.useState(totals.listings);
  const [hasNextPage, setHasNextPage] = React.useState(
    startKeys.listings === null ? true : startKeys.listings,
  );
  const listingItemHeight =
    itemSize ||
    (multiselect ? FIXED_SIZES.listings_dense : FIXED_SIZES.listings);

  const sort = sortKeys[0];
  const prevGroupOpen = usePrevious(groupOpen);
  const prevGroups = usePrevious(listingGroups);
  const prevGroupObj = usePrevious(listingGroupObj);
  const isDefaultGroup = React.useMemo(
    () => !groupOpen || groupOpen === "ALL",
    [groupOpen],
  );
  let syncInProgress =
    serviceAccounts?.sync_in_progress?.action === "SYNC_LISTINGS";

  React.useEffect(() => {
    if (!searchText && !filters.length && isDefaultGroup) {
      setHouses(houses);
      setTotalData((prev) => totals.listings);
    } else {
      let newHouses = data.map((h) =>
        !!houses_dict[h?.listing_id] ? houses_dict[h.listing_id] : h,
      );
      if (!isDefaultGroup) {
        newHouses = newHouses.sort(compareObjectsByName);
      }
      setHouses(newHouses);
    }
  }, [houses, totals, selected]);

  React.useEffect(() => {
    if (!searchText && !filters.length && isDefaultGroup) {
      setTotalData((prev) => totals.listings);
      setHouses(houses);
      setHasNextPage((prev) =>
        startKeys.listings === null ? true : startKeys.listings,
      );
      scrollToTop();
    } else {
      if (!prevGroupOpen !== groupOpen) {
        isLoadingGroupListings.current = true;
      }
      loadData();
    }
  }, [searchText, filters, groupOpen]);

  React.useEffect(() => {
    if (!isTablet && !!window.StonlyWidget) {
      window.StonlyWidget("sendData", {
        guideData: {
          hasGroups: listingGroups.length > 1,
        },
      });
    }

    if (!!listingGroups.length && !didMount.current) {
      didMount.current = true;
      setGroupOpen(groupOpen);
    } else if (!!prevGroups && prevGroups.length < listingGroups.length) {
      showLastGroupID.current = true;
      setGroupOpen(null);
    }
  }, [listingGroups]);

  React.useEffect(() => {
    if (!!showLastGroupID.current && !groupId) {
      showLastGroupID.current = false;
    }
  }, [groupId]);

  React.useEffect(() => {
    if (
      !groupOpen ||
      groupOpen === "ALL" ||
      !prevGroupObj ||
      !!searchText ||
      !!filters.length
    ) {
      return;
    }
    const prevGroup = prevGroupObj[groupOpen];
    const currGroup = listingGroupObj[groupOpen];
    if (
      !!prevGroup &&
      !!currGroup &&
      prevGroup.connected_to.ids.length !== currGroup.connected_to.ids.length
    ) {
      const newHouses = currGroup.connected_to.ids.map((id) => houses_dict[id]);
      setHouses(newHouses.sort(compareObjectsByName));
    }
  }, [listingGroupObj]);

  const compareHousesBySelectedState = (a, b) => {
    const isASelected = selected.includes(a.listing_id);
    const isBSelected = selected.includes(b.listing_id);
    if (isASelected && isBSelected) {
      return 0;
    } else if (isASelected && !isBSelected) {
      return -1;
    } else if (!isASelected && isBSelected) {
      return 1;
    } else return 0;
  };

  function setHouses(newHouses) {
    if (!!multiselect) {
      setData((prev) => newHouses.sort(compareHousesBySelectedState));
      if (!!listRef.current) {
        listRef.current.forceUpdateGrid();
      }
    } else {
      setData((prev) => newHouses);
    }
  }

  function scrollToTop() {
    if (!!listRef.current) {
      listRef.current.scrollToRow(0);
    }
  }

  const loadData = () => {
    let params = `enso_key=${current_user}&start=0&sort=${sort}`;
    if (!!searchText) {
      params += `&${qs.stringify({query: searchText})}`;
    }
    if (!!filters.length) {
      params += `&filters=${getEncodedFilters(filters)}`;
    }
    if (!searchText && !filters.length && !isDefaultGroup) {
      params += `&filters=${getEncodedFilters([{path: "listing_group_id", value: groupOpen}])}`;
    }

    dispatch(
      getHouses(
        params,
        true,
        (response) => {
          isLoadingGroupListings.current = false;
          setTotalData((prev) => response.count);
          setHasNextPage((prev) => response.start_key);
          setHouses(response.hits.sort(compareObjectsByName));
        },
        () => (isLoadingGroupListings.current = false),
      ),
    );
  };

  const loadNextPage = () => {
    isLoadingMore.current = true;
    let params = `enso_key=${current_user}&start=${data.length}&sort=${sort}`;
    if (!!searchText) {
      params += `&${qs.stringify({query: searchText})}`;
    }
    if (!!filters.length) {
      params += `&filters=${getEncodedFilters(filters)}`;
    }
    if (!searchText && !filters.length && !isDefaultGroup) {
      params += `&filters=${getEncodedFilters([{path: "listing_group_id", value: groupOpen}])}`;
    }

    dispatch(
      getHouses(
        params,
        true,
        (response) => {
          setHasNextPage((prev) => response.start_key);
          if (!searchText && !filters.length && isDefaultGroup) {
            dispatch(setStartKeys("listings", response.start_key));
          } else {
            setHouses(data.concat(response.hits));
          }
          isLoadingMore.current = false;
        },
        () => {
          isLoadingMore.current = false;
        },
      ),
    );
  };

  const handleSelectListing = (newHouse) => {
    if (disableSelect) {
      return;
    }
    if (!multiselect) {
      dispatch(getHouseContent(newHouse.listing_id));
    }
    selectHouse(newHouse);
  };

  const handleDeleteFilter = (filter) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const filterInd = prevFilters.findIndex(
        (f) => f.path === filter.path && f.value === filter.value,
      );
      if (filterInd !== -1) {
        newFilters.splice(filterInd, 1);
      }
      return newFilters;
    });
  };

  const handleGroupClick = (g, isLink) => () => {
    if (isLink) {
      setGroupOpen(g.group_id);
      selectGroup(g);
    } else if (groupOpen === g.group_id) {
      setGroupOpen(null);
    } else if (g.group_id === "ALL") {
      setGroupOpen(g.group_id);
    } else {
      setGroupOpen(g.group_id);
      selectGroup(g);
    }
  };

  function getCustomItem({index}) {
    const house = data[index];
    const waypoint =
      hasNextPage && index === data.length - 10 ? (
        <Waypoint onEnter={() => loadNextPage()} />
      ) : null;

    return customItem(house, waypoint);
  }

  function getHouseListItem({index}) {
    const house = data[index];
    const isSelected = multiselect
      ? selected.includes(house.listing_id)
      : selected === house.listing_id;

    return (
      <>
        {hasNextPage && index === data.length - 10 && (
          <Waypoint onEnter={() => loadNextPage()} />
        )}
        <ListItem
          button
          disableRipple
          dense={!!multiselect}
          divider
          className={classes.listItem}
          selected={isSelected}
          onClick={() => handleSelectListing(house)}
          classes={{
            selected: multiselect
              ? classes.multiselectSelected
              : classes.selected,
          }}
          id={`listing-item-${index}`}
        >
          <ListItemIcon className={classes.itemIcon}>
            {multiselect && (
              <Checkbox
                edge="start"
                size="small"
                checked={isSelected}
                disableRipple
                className={classes.checkbox}
              />
            )}
            <Avatar className={classes.img} src={house.picture}>
              {""}
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={house.nickname || house.name}
            primaryTypographyProps={{
              variant: "h1",
              className: clsx(classes.ellipsis, classes.title),
            }}
            secondary={house?.address?.formatted}
            secondaryTypographyProps={{
              variant: "body1",
              className: clsx(classes.ellipsis, classes.subtitle),
            }}
          />
          {!multiselect && !disableSelect && (
            <RightArrowIcon className={classes.icon} />
          )}
        </ListItem>
      </>
    );
  }

  function getGroups() {
    if (!!searchText || !!filters.length) {
      return null;
    }
    return (
      <List dense>
        {listingGroups.map((g, i) => {
          const isExpanded = groupOpen === g.group_id;
          if (groupOpen && !isExpanded) {
            return null;
          }
          return (
            <ListItem
              button
              divider={!isExpanded}
              disableRipple
              id={`gje-btn-group${g.group_id !== "ALL" && i === listingGroups.length - 1 && showLastGroupID.current ? "-last" : i}`}
              key={g.group_id ?? i}
              className={clsx(classes.groupItem, {"-expanded": isExpanded})}
              classes={{divider: classes.listItemDivider}}
            >
              <ListItemText
                primary={
                  <div className={classes.groupTitle}>
                    <div className="clickableRow" onClick={handleGroupClick(g)}>
                      <Arrow
                        className={clsx(classes.expandArrowIcon, {
                          "-expanded": isExpanded,
                        })}
                      />
                      <div className="-title">{g.name}</div>
                    </div>
                    {g.group_id !== "ALL" && (
                      <Button
                        color="secondary"
                        disableElevation
                        disableRipple
                        variant="text"
                        onClick={handleGroupClick(g, true)}
                        className={clsx(classes.settingsBtn, "-settingsBtn")}
                      >
                        {"Manage guest journey"}
                      </Button>
                    )}
                  </div>
                }
                primaryTypographyProps={{
                  variant: "h1",
                  className: classes.groupText,
                }}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  const actions = customActions ?? (
    <>
      <PrimaryButton
        size="small"
        label="Create listing group"
        onClick={() => {
          setAnchorEl(null);
          createGroup();
        }}
      />
    </>
  );

  const searchbar = React.useMemo(() => {
    return (
      <div className={classes.searchContainer}>
        <SearchBar
          enableFilters
          disableGutters
          filters={filters}
          short={multiselect}
          filterKeys={filterKeys}
          searchInput={searchText}
          placeholder={`Search ${totals.listings} listings`}
          handleDeleteFilter={handleDeleteFilter}
          handleSearchInput={(val) => setSearchText((prev) => val)}
          setFilters={(newFilters) => setFilters((prev) => newFilters)}
        />
      </div>
    );
  }, [searchText, filters, totalData, totals]);

  function getHouseList() {
    if (startKeys.listings === null) {
      return null;
    }
    const hideList =
      !!useGroups && !groupOpen && !searchText && !filters.length;
    return loadingHouses &&
      !isLoadingMore.current &&
      (!!searchText || !!filters.length) &&
      isDefaultGroup ? (
      <EmptyListPanel
        grey
        loading
        list="listings"
        noTitle
        noSearchbar
        multiselect={multiselect}
      />
    ) : (
      <>
        {useGroups && getGroups()}
        {isLoadingGroupListings.current ? (
          <EmptyListPanel
            grey
            loading
            noTitle
            noSearchbar
            list="listings"
            multiselect={multiselect}
          />
        ) : (
          !hideList && (
            <div
              className={clsx(classes.listingsContainer, {
                "-paddingTop": !!searchText || !!filters.length,
              })}
            >
              {data.length ? (
                <VirtualizedList
                  hideScrollbar={hideScrollbar}
                  listRef={listRef}
                  itemBottomMargin={12}
                  getRowItem={!!customItem ? getCustomItem : getHouseListItem}
                  rowHeight={listingItemHeight}
                  totalRows={data.length}
                />
              ) : (
                <EmptyContentText
                  label={"No listings connected to this group"}
                />
              )}
            </div>
          )
        )}
      </>
    );
  }

  return startKeys.listings === null ? (
    <EmptyListPanel
      grey
      loading={!hasError}
      list="listings"
      noTitle={!!customTitle || !!multiselect}
      multiselect={multiselect}
    />
  ) : (
    <div className={classes.root}>
      {!hideTitle && (
        <CustomCardHeader
          type={customTitle ? "text" : "header"}
          className={classes.header}
          action={actions}
          title={
            !!customTitle
              ? customTitle
              : !!multiselect
                ? searchbar
                : ` ${label}`
          }
        />
      )}
      {!multiselect && !disableSearch && searchbar}
      {syncInProgress && (
        <Box px={4}>
          <WarningAlert
            sync
            small
            disableMargin
            title={"Listing Sync in Progress"}
            subtitle={"Updating from PMS"}
          />
        </Box>
      )}
      <div
        className={clsx(classes.content, classes.contentGroups)}
        id={"listings-list"}
      >
        {getHouseList()}
      </div>
    </div>
  );
}
