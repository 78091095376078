import * as API from "../api/bpAPI";

export function getBPContentPreview(
  params,
  onSuccess = () => {},
  onError = () => {},
) {
  return async () => {
    try {
      const response = await API.getBPPreview({body: params});
      console.log("GOT BP PREVIEW", response);
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET BP PREVIEW", error);
      onError();
    }
  };
}

export function getBPProductContentPreview(
  params,
  onSuccess = () => {},
  onError = () => {},
) {
  return async () => {
    try {
      const response = await API.getBPProductPreview({body: params});
      console.log("GOT BP PRODUCT PREVIEW", response);
      onSuccess(response);
    } catch (error) {
      console.log("ERROR - GET BP PRODUCT PREVIEW", error);
      onError();
    }
  };
}
