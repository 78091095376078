import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useRouteMatch} from "react-router-dom";
// UI
import {Box} from "@material-ui/core";
import useListingProductsPanelStyles from "styles/useListingProductsPanel";
import "react-quill/dist/quill.snow.css";
// Custom
import CustomCardHeader from "core/cards/CustomCardHeader";
import ListingContentList from "../../Lists/ListingContentList";
import ProductModal from "components/Dialogs/ProductModal";
import StripeAccountCard from "components/Cards/StripeAccountCard";
import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import {EmptyHouseProducts} from "components/Helpers/EmptyPanels";
import BackButton from "core/buttons/BackButton";
import ProductGrid from "components/Grids/ProductGrid";
import CustomDialog from "core/dialogs/CustomDialog";
import CreationButton from "components/MultiOption/CreationButton";
import CreateFromExistingList from "components/Lists/CreateFromExistingList";
import CloseIconButton from "core/buttons/CloseIconButton";
import TemplateCard from "components/Cards/TemplateCard";
import GJEInfoCard from "components/Cards/GJEInfoCard";
import ProductIcon from "core/icons/ProductIcon";
// Actions
import {
  deleteListingResource,
  editHouse,
  getListingGroupDetails,
  handleUpdateGroup,
  saveListingResource,
  updateListingResource,
} from "redux/actions/listingsActions";
import {getServiceAccounts} from "redux/actions/servicesActions";
// Utilities
import {
  multiLangArrayToObj,
  multiLangObjToArray,
} from "utilities/formatUtilities";
import {
  capitalize,
  getProductBGImage,
  newUuid,
} from "utilities/helperFunctions";
import {productsFees, productsUpsells} from "configuration/enums";
import _ from "lodash";
import clsx from "clsx";

export default function ListingProductsPanel({
  listingId,
  outsideView,
  isParentLoading,
  onClose,
  disableEdit,
  goBack,
  type,
}) {
  const classes = useListingProductsPanelStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const saveOption = React.useRef(null);
  const loadingResource = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_resource_action;
  const loadingContent = useSelector(
    (state) => state.defaultReducer.loading,
  ).listing_content;
  const listingGroups = useSelector(
    (state) => state.defaultReducer.listing_groups_dict,
  );
  const listings = useSelector((state) => state.defaultReducer.house_data_dict);
  const serviceAccounts = useSelector(
    (state) => state.defaultReducer.service_accounts,
  );
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const matchCardSection = useRouteMatch(
    "/admin/listings/groups/:group_id/:step/:card",
  );
  // Refs
  const prevResources = React.useRef([]);
  //State
  const [stripeAccount, setStripeAccount] = React.useState(
    serviceAccounts?.accounts?.stripe?.[0],
  );
  const [openModal, setOpenModal] = React.useState(false);
  const [upsells, setUpsells] = React.useState([]);
  const [fees, setFees] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [productToDelete, setProductToDelete] = React.useState(null);
  const [modalContentType, setModalContentType] = React.useState(null);
  const [expanded, setExpanded] = React.useState(null);
  const [skus, setSkus] = React.useState({});
  const [langs, setLangs] = React.useState(["en"]);
  const [headerImage, setHeaderImage] = React.useState(null);
  const [selectedLang, setSelectedLang] = React.useState("en");
  const [textContent, setTextContent] = React.useState({
    name: {en: ""},
    desc: {en: ""},
    skus: {},
  });
  // General
  const listing = React.useMemo(
    () => listings[listingId] || {},
    [listingId, listings],
  );
  const {groupId, section, card} = React.useMemo(() => {
    if (!matchCardSection?.isExact) {
      return {groupId: listing?.group_id, section: null, card: null};
    }
    return {
      groupId: matchCardSection.params.group_id,
      section: matchCardSection.params.step,
      card: matchCardSection.params.card,
    };
  }, [location, listing]);
  const listingContent = listing?.listing_content || {};
  const selectedGroup = listingGroups[groupId];
  const guestJourney = selectedGroup?.guest_journey ?? {};
  const groupData = guestJourney.sections?.[section]?.cards[card]?.data ?? [];
  const isGroupView = matchCardSection?.isExact;
  const isFeeType = type === "fees";
  const selectFromOptionEnabled = ["existing", "template"].includes(
    modalContentType,
  );
  const selectedCurrency = !!matchCardSection?.isExact
    ? listings[selectedGroup?.connected_to?.ids?.[0]]?.currency
    : listing?.currency;

  React.useEffect(() => {
    if (!!openModal && !selectFromOptionEnabled) {
      return;
    }
    if (serviceAccounts?.accounts == undefined) {
      setStripeAccount(false);
      dispatch(getServiceAccounts({stripeOnly: true}));
    }
    if (type == "fees" && isGroupView) {
      setFees((prev) => [...groupData]);
      setUpsells((prev) => []);
    } else if (type == "upsells" && isGroupView) {
      setFees((prev) => []);
      setUpsells((prev) => [...groupData]);
    } else if (type === "fees") {
      setFees((prev) => [...(listingContent.fees || [])]);
      setUpsells((prev) => []);
    } else if (type === "upsells") {
      setFees((prev) => []);
      setUpsells((prev) => [...(listingContent.upsells || [])]);
    }
  }, [openModal, selectFromOptionEnabled, listing, location, selectedGroup]);

  React.useEffect(() => {
    if (!!Object.keys(serviceAccounts?.accounts ?? {}).length) {
      setStripeAccount((prev) => serviceAccounts?.accounts?.stripe?.[0]);
    }
  }, [serviceAccounts]);

  const getUpdatedListing = (product, isFee, type) => {
    let newProducts = isFee
      ? [...listing?.listing_content.fees]
      : [...listing?.listing_content.upsells];
    switch (type) {
      case "add":
        newProducts.push({...product, id: "new_product"});
        break;
      case "update":
        newProducts = newProducts.map((p) =>
          p.resource_id === product.resource_id ? product : p,
        );
        break;
    }

    return {
      ...listing,
      listing_content: {
        ...listing?.listing_content,
        [isFee ? "fees" : "upsells"]: newProducts,
      },
    };
  };

  const resetContent = (product) => {
    const {newContent, newLangs} = multiLangArrayToObj([
      {key: "name", newKey: "name", content: product.properties},
      {key: "description", newKey: "desc", content: product.properties},
      {
        key: "skus",
        newKey: "skus",
        content: product.properties,
        subfieldKeys: ["name"],
      },
    ]);
    let newSkus = {};
    _.each(product.properties?.skus || [], (s) => {
      newSkus[s.sku_id] = s;
    });
    setTextContent((prev) => newContent);
    setSkus((prev) => newSkus);
    setLangs((prev) => newLangs);
    setSelectedLang((prev) => newLangs[0]);
    setHeaderImage((prev) => product.properties?.header_image ?? null);
  };

  function openProductModal(product, key, is_fee = false) {
    resetContent(product);
    setSelectedProduct({
      ...product,
      is_custom: product.properties.product_type.startsWith("custom_"),
      ind: key,
      is_fee: is_fee,
    });
    setOpenModal(true);
  }

  const addProduct = (product, type, isFee = false) => {
    let newSku = {
      is_fee: isFee,
      is_custom: !product?.requires_stripe,
      is_new: true,
      ind: null,
      resource: isFee ? "fee" : "up",
      properties: {
        conditions: product?.conditions || [],
        skus: product?.skus?.map((s) => ({
          ...s,
          sku_id: `new_sku_${newUuid()}`,
        })) || [{value: 10, unit: "1", sku_id: `new_sku_${newUuid()}`}],
        name: product?.name || "Custom",
        product_type: type,
        currency: selectedCurrency,
        manually_approve: true,
        header_image: product?.header_image ?? null,
        description: product?.description,
      },
    };
    resetContent(newSku);
    setSelectedProduct((prev) => newSku);
    setOpenModal(true);
  };

  const selectExistingProduct = (p) => {
    let newSku = {
      is_fee: p.resource === "fee",
      is_custom: p.properties.product_type.startsWith("custom_"),
      is_new: true,
      ind: null,
      resource: p.resource,
      properties: {
        conditions: p.properties.conditions || [],
        skus:
          p.properties.skus?.map((s) => ({
            ...s,
            sku_id: `new_sku_${newUuid()}`,
            properties: undefined,
          })) || [{value: 10, unit: "1", sku_id: `new_sku_${newUuid()}`}] ||
          [],
        name: p.properties.name || "Custom",
        product_type: p.properties.product_type,
        currency: selectedCurrency,
        manually_approve: p.properties.manually_approve,
        description: p.properties.description || [{language: "en", value: ""}],
      },
    };
    resetContent(newSku);
    setSelectedProduct((prev) => newSku);
    setModalContentType((prev) => null);
    setHeaderImage((prev) => p.properties?.header_image ?? null);
  };

  const selectProductTemplate = (template, k) => () => {
    addProduct(template, template.product_type, isFeeType);
    setModalContentType((prev) => null);
    setExpanded((prev) => null);
    setHeaderImage((prev) => getProductBGImage(type, k));
  };

  const handleMenuOptionClick = (actionType, type, isFee) => () => {
    setModalContentType((prev) => actionType);
    if (["existing", "template"].includes(actionType)) {
      setOpenModal((prev) => true);
    } else {
      addProduct(null, type, isFee);
    }
  };

  const closeModal = () => {
    setOpenModal((prev) => false);
    setModalContentType((prev) => null);
    setSelectedProduct({});
    setHeaderImage((prev) => null);
  };

  const resetConfirmationValues = () => {
    saveOption.current = null;
    closeDeleteConfirmation();
  };

  const closeDeleteConfirmation = () => setProductToDelete((prev) => null);

  const onSaveSuccess = () => {
    if (matchCardSection?.isExact) {
      dispatch(getListingGroupDetails({groupId, section, card}));
    }
  };

  const onDeleteSuccess = () => {
    resetConfirmationValues();
    if (matchCardSection?.isExact) {
      dispatch(getListingGroupDetails({groupId, section, card}));
    }
  };

  const onError = () => {
    resetConfirmationValues();
    if (matchCardSection?.isExact) {
      dispatch(getListingGroupDetails({groupId, section, card}));
    }
  };

  const formatSkus = (newSkus) => {
    let newFormattedSkus = [];
    _.each(newSkus, (s) => {
      let ns = {...s, value: Number(s.value)};
      if (newSkus.length === 1) {
        delete ns.name;
      }
      if (s.sku_id.includes("new_sku_")) {
        delete ns.sku_id;
      }
      if (s.unit.includes("{input_value}")) {
        ns.unit = ns.unit.replace("{input_value}", ns.value / 100);
        ns.value = "1";
        if (s.hasOwnProperty("value2")) {
          ns.unit = ns.unit.replace("{input_value_2}", ns.value2);
          delete ns.value2;
        }
      }
      newFormattedSkus.push(ns);
    });
    return newFormattedSkus;
  };

  const formatMultiLangTextContentForRequest = () => {
    let newContent = multiLangObjToArray(textContent, langs, [
      {key: "name", requestKey: "name"},
      {key: "desc", requestKey: "description", isHTML: true},
      {
        key: "skus",
        requestKey: "skus",
        subfieldProps: {
          keys: ["name"],
          object: skus,
        },
      },
    ]);

    return {...newContent, skus: formatSkus(newContent.skus)};
  };

  const getUpdatedContent = (addResource) => {
    const newProduct = {
      ...selectedProduct,
      properties: {
        ...selectedProduct.properties,
        ...formatMultiLangTextContentForRequest(),
      },
    };

    if (!!headerImage && !newProduct.is_fee) {
      newProduct.properties.header_image = headerImage;
    }

    return addResource
      ? {...newProduct, resource: newProduct.is_fee ? "fee" : "up"}
      : newProduct;
  };

  function handleAddProduct(option) {
    saveOption.current = option;
    const newProduct = {
      ...selectedProduct,
      properties: {
        ...selectedProduct.properties,
        ...formatMultiLangTextContentForRequest(),
      },
    };

    if (!!headerImage && !newProduct.is_fee) {
      newProduct.properties.header_image = headerImage;
    }

    const newListing = isGroupView
      ? null
      : getUpdatedListing(
          newProduct,
          newProduct.is_fee,
          !!newProduct.resource_id ? "update" : "add",
        );
    let updateFunc = newProduct.is_fee ? setFees : setUpsells;
    prevResources.current = newProduct.is_fee ? [...fees] : [...upsells];

    if (!!newProduct.resource_id) {
      // Update product
      if (isGroupView) {
        handleUpdateGroup({
          action: "update",
          group: selectedGroup,
          section,
          card,
          listingResource: newProduct,
          dispatch,
        });
      }
      let listingId = isGroupView
        ? selectedGroup?.connected_to?.ids[0]
        : listing?.id;
      let newProducts = [];
      if (
        newProduct.connection_type === "ids" &&
        !newProduct.connected_ids.listing?.includes(listingId) &&
        !!newListing
      ) {
        newProducts = newListing.listing_content[
          newProduct.is_fee ? "fees" : "upsells"
        ].filter((p) => p.resource_id !== newProduct.resource_id);
        newListing.listing_content[newProduct.is_fee ? "fees" : "upsells"] =
          newProducts;
      }

      updateFunc((prev) =>
        prev.map((pr) =>
          pr.resource_id === newProduct.resource_id ? newProduct : pr,
        ),
      );

      dispatch(
        updateListingResource({
          body: {
            ...newProduct,
            resource: newProduct.is_fee ? "fee" : "up",
          },
          disableListingUpdate: !newListing,
          listing: newListing,
          onSuccess: (res) => {
            updateFunc((prev) =>
              prevResources.current.map((r) =>
                r.resource_id === res.resource_id ? res : r,
              ),
            );
            onSaveSuccess();
          },
          onError: () => {
            updateFunc((prev) => [...prevResources.current]);
            onError();
          },
        }),
      );
    } else {
      // Create product
      if (isGroupView) {
        handleUpdateGroup({
          action: "add",
          group: selectedGroup,
          section,
          card,
          listingResource: newProduct,
          dispatch,
        });
      }

      updateFunc((prev) => [...prev, newProduct]);

      dispatch(
        saveListingResource({
          body: {
            ...newProduct,
            resource: newProduct.is_fee ? "fee" : "up",
          },
          type: newProduct.is_fee ? "fee" : "upsell",
          disableListingUpdate: !newListing,
          listing: newListing,
          onSuccess: (res) => {
            let updatedResources = [...prevResources.current, res];
            updateFunc((prev) => updatedResources);
            if (!isGroupView) {
              dispatch(
                editHouse({
                  ...listing,
                  listing_content: {
                    ...listing.listing_content,
                    [newProduct.is_fee ? "fees" : "upsells"]: updatedResources,
                  },
                }),
              );
            }
            onSaveSuccess();
          },
          onError: () => {
            updateFunc((prev) => [...prevResources.current]);
            onError();
          },
        }),
      );
    }
    closeModal();
  }

  const handleRemoveProduct = () => {
    closeDeleteConfirmation();
    let func = null;
    let new_products = null;
    const newData = {};

    if (productToDelete.is_fee) {
      new_products = [...fees];
      func = setFees;
    } else {
      new_products = [...upsells];
      func = setUpsells;
    }

    new_products.splice(productToDelete.index, 1);
    func(new_products);
    newData.fees = productToDelete.is_fee ? new_products : fees;
    newData.upsells = !productToDelete.is_fee ? new_products : upsells;
    deleteProduct(newData);
  };

  const deleteProduct = (newData) => {
    const newListing = isGroupView
      ? null
      : {
          ...listing,
          listing_content: {
            ...listing?.listing_content,
            upsells: newData.upsells,
            fees: newData.fees,
          },
        };
    if (isGroupView) {
      handleUpdateGroup({
        action: "delete",
        group: selectedGroup,
        section,
        card,
        listingResource: productToDelete,
        dispatch,
      });
    }
    dispatch(
      deleteListingResource({
        resource: productToDelete.is_fee ? "fee" : "up",
        type: productToDelete.is_fee ? "fee" : "upsell",
        resource_id: productToDelete.resource_id,
        disableListingUpdate: !newListing,
        listing: newListing,
        onSuccess: onDeleteSuccess,
        onError: onError,
      }),
    );
  };

  function getSelectFromOptionContent() {
    if (modalContentType === "existing") {
      return (
        <Box height="100%">
          <CreateFromExistingList
            resource={`${type.slice(0, -1)}`}
            onSelect={selectExistingProduct}
          />
        </Box>
      );
    } else if (modalContentType === "template") {
      const obj = isFeeType ? productsFees : productsUpsells;
      const availableKeys = Object.keys(obj);
      return (
        <div className={classes.templateContainer}>
          {availableKeys.map((k) => {
            const templ = {...obj[k], upsellId: isFeeType ? undefined : k};
            return (
              <TemplateCard
                key={k}
                expandable
                type={type}
                currency={selectedCurrency}
                subheader={templ.header}
                expanded={expanded === k}
                expandItem={() =>
                  setExpanded((prev) => (prev === k ? null : k))
                }
                icon={<ProductIcon type={type} product={k} />}
                template={templ}
                title={templ.name}
                bgImage={getProductBGImage(type, k)}
                onClick={selectProductTemplate(templ, k)}
              />
            );
          })}
        </div>
      );
    }
  }

  const selectFromOptionModal = (
    <CustomDialog
      fullWidth
      disableExit
      disableActions
      maxWidth="md"
      customHeight="100%"
      disableEnforceFocus
      open={!!openModal && !!selectFromOptionEnabled}
      titleVariant="header"
      transitionDuration={0}
      title={`New ${type.slice(0, -1)}`}
      onClose={closeModal}
      content={getSelectFromOptionContent()}
    />
  );

  const productModal = !!openModal && !selectFromOptionEnabled && (
    <ProductModal
      open
      langs={langs}
      content={textContent}
      listing={listing}
      selectedLang={selectedLang}
      isGroupView={!!matchCardSection?.isExact}
      listing_id={listing?.listing_id ?? selectedProduct?.listing_id}
      group_id={listing?.group_id ?? groupId}
      groupView={matchCardSection?.isExact}
      stripeAccount={stripeAccount}
      selectedGroup={selectedGroup}
      selected={selectedProduct}
      headerImage={headerImage}
      disabled={disableEdit}
      getUpdatedContent={getUpdatedContent}
      skus={skus}
      setSkus={setSkus}
      setLangs={setLangs}
      setContent={setTextContent}
      handleClose={closeModal}
      handleSave={handleAddProduct}
      editData={setSelectedProduct}
      setHeaderImage={setHeaderImage}
      setSelectedLang={setSelectedLang}
    />
  );

  const title = (
    <CustomCardHeader
      title={
        !!goBack && !isGroupView ? (
          <BackButton
            header={!isMobileView}
            color={!!isMobileView ? "secondary" : "primary"}
            goBack={goBack}
          />
        ) : (
          `${capitalize(type)}`
        )
      }
      className={classes.header}
      action={
        (!goBack || isGroupView) && (
          <>
            {!disableEdit && (
              <Box mr={3}>
                <CreationButton
                  type={type.slice(0, -1)}
                  disabled={
                    isParentLoading || loadingContent || loadingResource
                  }
                  onScratch={handleMenuOptionClick(
                    "scratch",
                    isFeeType ? "custom_fee" : "custom_upsell",
                    !!isFeeType,
                  )}
                  onExisting={handleMenuOptionClick("existing")}
                  onTemplate={handleMenuOptionClick("template")}
                />
              </Box>
            )}
            <CloseIconButton sm disablePadding onClick={onClose} />
          </>
        )
      }
    />
  );

  const outsideTitleRow = (
    <ListingContentList
      listingId={listing?.listing_id}
      onlyItems={["products"]}
      disableBackground
    />
  );

  const confirmDeleteProductModal = productToDelete !== null && (
    <ConfirmDialog
      open
      maxWidth="xs"
      disableDismiss
      onClose={resetConfirmationValues}
      title={`Delete ${productToDelete.is_fee ? "fee" : "upsell"}?`}
      message={
        <>
          {`This ${productToDelete.is_fee ? "fee" : "upsell"} with all its configuration will be deleted.`}
        </>
      }
      confirmLabel="Delete"
      confirmAction={handleRemoveProduct}
      cancelLabel="Cancel"
      cancelAction={resetConfirmationValues}
    />
  );

  const feeSection = (
    <ProductGrid
      type="fees"
      data={fees}
      currency={selectedCurrency}
      onAdd={(p) => addProduct(p, p.product_type, true)}
      onDelete={(p, i) =>
        setProductToDelete((prev) => ({
          index: i,
          is_fee: true,
          resource_id: p.resource_id,
        }))
      }
      onEdit={(product, ind) => openProductModal(product, ind, true)}
    />
  );

  const upsellSection = (
    <ProductGrid
      type="upsells"
      data={upsells}
      listing={listing}
      setData={setUpsells}
      disableReorder={isGroupView}
      currency={selectedCurrency}
      onAdd={(p, isTemplate) => {
        addProduct(
          {
            ...p,
            header_image: isTemplate
              ? getProductBGImage("upsells", p.upsellId)
              : null,
          },
          p.product_type,
        );
      }}
      onDelete={(p, i) =>
        setProductToDelete((prev) => ({
          index: i,
          is_fee: false,
          resource_id: p.resource_id,
        }))
      }
      onEdit={(product, ind) => openProductModal(product, ind)}
    />
  );

  function getContent() {
    if (isParentLoading || (loadingContent && !isGroupView)) {
      return (
        <EmptyHouseProducts hideSuggestions={disableEdit} type={type} loading />
      );
    } else if (stripeAccount === false) {
      return (
        <EmptyHouseProducts hideSuggestions={disableEdit} type={type} loading />
      );
    } else if (stripeAccount === null || !stripeAccount?.properties?.enabled) {
      return (
        <div className={classes.content}>
          <StripeAccountCard account={stripeAccount} />
        </div>
      );
    } else {
      return (
        <>
          {isFeeType && feeSection}
          {!isFeeType && upsellSection}
        </>
      );
    }
  }

  return (
    <div className={classes.root}>
      {title}
      {!!outsideView && outsideTitleRow}
      {selectFromOptionModal}
      {productModal}
      {confirmDeleteProductModal}
      <div className={clsx(classes.content, {pt0: !!isGroupView})}>
        {isFeeType && isGroupView && (
          <Box textAlign="left" mx={-4}>
            <GJEInfoCard banner type="fees" />
          </Box>
        )}
        {!isFeeType && isGroupView && (
          <Box textAlign="left" mx={-4}>
            <GJEInfoCard banner type="upsells" />
          </Box>
        )}
        {getContent()}
      </div>
    </div>
  );
}
