import React, {useMemo, useRef, useState} from "react";
// UI
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as VerificationIcon} from "assets/icons/user-check.svg";
import ArrowIcon from "@material-ui/icons/ArrowForwardIos";
import {useFlags, withLDConsumer} from "launchdarkly-react-client-sdk";
// Custom
import VerificationServiceCard from "components/Cards/VerificationServiceCard";
import DeleteIconButton from "core/buttons/DeleteIconButton";
import EditIconButton from "core/buttons/EditIconButton";
import PrimaryButton from "core/buttons/PrimaryButton";
import CustomSwitch from "core/switches/CustomSwitch";
import CustomDialog from "core/dialogs/CustomDialog";
import PreviewBPPanel from "../PreviewBPPanel";
// Utilities
import {addonServices} from "configuration/enums.js";
import {THEME} from "configuration/settings";
import usePrevious from "hooks/usePrevious";
import clsx from "clsx";
import {hyphenToCamelCase} from "utilities/helperFunctions";

const verifierServices = ["autohost", "superhog", "chekin"];
const verifiers = ["govt_id_front", "govt_id_back", "selfie"];

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    margin: theme.spacing(1, 0, 2),
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    gap: theme.spacing(3),
  },
  sectionRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  content: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    overflowY: "auto",
    overflowX: "hidden",
    flexFlow: "wrap",
  },
  grayCard: {
    backgroundColor: "#FBFBFC",
    border: "1px solid #E6E6E6",
    borderRadius: 10,
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  cardText: {
    ...THEME.customTypography.title2,
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > svg": {marginRight: theme.spacing(1)},
  },
  manualCard: {
    overflow: "visible",
    padding: theme.spacing(4),
    borderRadius: 10,
    border: "1px solid #F5F5F5",
    background: "#F5F5F5",
    boxShadow:
      "0px 3px 3px 0px rgba(155, 160, 176, 0.10), 0px 1px 0px 0px rgba(21, 50, 111, 0.05)",
    marginBottom: theme.spacing(3),
    "&.expanded": {paddingBottom: 0},
    "& > button": {
      display: "flex",
      color: "transparent !important",
    },
  },
  cardHeader: {
    padding: 0,
    flex: 1,
  },
  cardActions: {
    justifyContent: "flex-end",
    padding: theme.spacing(4),
    marginLeft: -theme.spacing(4),
    marginRight: -theme.spacing(4),
    borderRadius: "0 0 10px 10px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.10)",
  },
  arrowIcon: {
    fontSize: 16,
    transition: "0.15s",
    color: theme.palette.text.primary,
    "&.selected": {transform: "rotate(90deg)"},
  },
  manualContainer: {padding: theme.spacing(3, 0)},
  checkboxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: -10,
  },
}));

const VERIFICATION_LABELS = {
  govt_id_front: "Front of ID",
  govt_id_back: "Back of ID",
  selfie: "Selfie",
};

function VerificationPanel({
  verification_service,
  id_upload,
  editData,
  group,
  verificationType,
  setVerificationType,
  initialVerificationService,
  disableEdit,
  connectedTo,
  getUpdatedContent,
}) {
  const flags = useFlags();
  const classes = useStyles();
  const isPreviewing = useRef(true);
  const editionStarted = useRef(false);
  const originalVerifServ = useRef(verification_service);
  const [newAutomatedVerifier, setNewAutomatedVerifier] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [checked, setChecked] = useState({
    govt_id_front: true,
    govt_id_back: true,
    selfie: true,
  });
  const [previewData, setPreviewData] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const prevVerifServ = usePrevious(verification_service);
  const options = useMemo(() => {
    let newOptions = [];
    verifierServices.map((vs) => newOptions.push(...addonServices[vs]));
    return newOptions;
  }, []);
  let listingId = group.connected_to.ids[0];

  const resetData = () => {
    setData({
      verification_service: initialVerificationService,
      id_upload: id_upload,
    });
    setVerificationType(
      initialVerificationService === "enso"
        ? "manual"
        : !!initialVerificationService
          ? "automated"
          : "none",
    );
    setChecked((prev) => ({
      govt_id_front: !!id_upload?.govt_id_front,
      govt_id_back: !!id_upload?.govt_id_back,
      selfie: !!id_upload?.selfie,
    }));
  };

  React.useEffect(() => {
    if (!modalOpen) {
      setExpanded(null);
    }
  }, [modalOpen]);

  React.useEffect(() => {
    if (prevVerifServ === "unset" && !editionStarted.current) {
      originalVerifServ.current = verification_service;
      if (!!verification_service) {
        isPreviewing.current = false;
      }
      setExpanded(
        verification_service === "enso" ? "manual" : verification_service,
      );
    }
  }, [verification_service]);

  React.useEffect(() => {
    if (!data || !modalOpen) {
      return;
    }
    let newData = getUpdatedContent(true);
    setPreviewData(newData);
  }, [data, modalOpen]);

  React.useEffect(() => {
    resetData();
  }, [verificationType, id_upload, verification_service]);

  const handleVerificationTypeChange = (option, disableSave) => {
    const triggerSaving = !disableSave;
    setVerificationType(option);
    if (option === "manual") {
      editData({verification_service: "enso"}, triggerSaving);
    } else if (option === "none") {
      editData({verification_service: null}, triggerSaving);
    } else if (!!newAutomatedVerifier) {
      editData({verification_service: newAutomatedVerifier}, triggerSaving);
    } else if (initialVerificationService !== "enso") {
      editData(
        {verification_service: initialVerificationService},
        !!initialVerificationService,
      );
    } else {
      editData({verification_service: null}, triggerSaving);
    }
  };

  const handleVerifierClick = (option, disableSave) => {
    const triggerSaving = !disableSave;
    if (verificationType !== "automated") {
      setVerificationType("automated");
    }
    setNewAutomatedVerifier((prev) => option["Verifier"]);
    editData({verification_service: option["Verifier"]}, triggerSaving);
  };

  const handleCancelDataChange = () => {
    resetData();
    setModalOpen(false);
  };

  const onExpand = (item, option) => () => {
    if (!editionStarted.current) {
      editionStarted.current = true;
    }
    if (!isPreviewing.current) {
      isPreviewing.current = true;
    }
    if (expanded === item) {
      setExpanded(null);
      handleVerificationTypeChange("none", true);
    } else {
      setExpanded(item);
      if (item === "manual") {
        handleVerificationTypeChange("manual", true);
        if (originalVerifServ.current === "enso") {
          isPreviewing.current = false;
        }
      } else {
        if (originalVerifServ.current === item) {
          isPreviewing.current = false;
        }
        handleVerifierClick(option, true);
      }
    }
  };

  function handleIDOptions(field) {
    setChecked((prev) => ({...prev, [field]: !prev[field]}));
    if (verification_service === "enso") {
      const triggerSaving = !isPreviewing.current;
      editData(
        {id_upload: {...id_upload, [field]: !id_upload[field]}},
        triggerSaving,
      );
    }
  }

  function VerificationSwitch(field) {
    return (
      <div key={field} className={classes.checkboxRow}>
        <Typography style={{fontWeight: 500}}>
          {" "}
          Collect {VERIFICATION_LABELS[field]}
        </Typography>
        <CustomSwitch
          disabled={disableEdit || verification_service !== "enso"}
          checked={!!checked[field]}
          onClick={() => handleIDOptions(field)}
        />
      </div>
    );
  }

  function getVerificationSummary() {
    switch (verificationType) {
      case "automated":
        return options.find(
          (opt) => opt.Verifier === data?.verification_service,
        )?.Name;
      case "manual":
        let active_verifiers = verifiers
          .filter((v) => !!id_upload[v])
          .map((v) => VERIFICATION_LABELS[v]);
        let last_verifier = active_verifiers.pop() ?? "";
        return `Collect ${active_verifiers.join(", ")}${!!active_verifiers.length ? " & " : ""}${last_verifier}`;

      default:
        return null;
    }
  }

  function getDialogContent() {
    return (
      <>
        <Card
          className={clsx(classes.manualCard, {
            expanded: expanded === "manual",
          })}
        >
          <CardActionArea disableRipple onClick={onExpand("manual")}>
            <CardHeader
              title={"Manual"}
              className={classes.cardHeader}
              titleTypographyProps={{
                variant: "h2",
                color: "textPrimary",
                style: {fontWeight: 500},
              }}
              subheader={
                <Box mt={2}>
                  {
                    "Collect selfies and identify documents from your guests to verify their identity."
                  }
                </Box>
              }
              subheaderTypographyProps={{color: "textPrimary"}}
            />
            <ArrowIcon
              className={clsx(classes.arrowIcon, {
                selected: expanded === "manual",
              })}
            />
          </CardActionArea>
          <Collapse in={expanded === "manual"} timeout={0}>
            <CardContent className={classes.manualContainer}>
              {verifiers.map((v) => VerificationSwitch(v))}
            </CardContent>
            <CardActions className={classes.cardActions}>
              <PrimaryButton
                size="small"
                color="secondary"
                label={
                  !isPreviewing.current && verification_service === "enso"
                    ? "Selected"
                    : "Select"
                }
                disabled={
                  disableEdit ||
                  (verification_service === "enso" && !isPreviewing.current)
                }
                onClick={() => {
                  isPreviewing.current = false;
                  handleVerificationTypeChange("manual");
                }}
              />
            </CardActions>
          </Collapse>
        </Card>
        {options.map((opt) => {
          const flag = hyphenToCamelCase(
            `verification-service-${opt.Provider.toLowerCase()}-integration`,
          );

          if (Object.hasOwn(flags, flag) && !flags[flag]) {
            return null;
          }

          return (
            <VerificationServiceCard
              mb={3}
              expanded={expanded === opt.Verifier}
              option={opt}
              isPreviewing={isPreviewing.current}
              key={opt["Subscription ID"]}
              connectedTo={connectedTo}
              selectedVerifier={verification_service}
              onEnable={(opt) => {
                isPreviewing.current = false;
                handleVerifierClick(opt);
              }}
              onExpand={onExpand(opt.Verifier, opt)}
            />
          );
        })}
      </>
    );
  }

  function getPreviewContent() {
    if (!previewData) {
      return null;
    }
    return (
      <PreviewBPPanel
        type={"id_upload"}
        verifier={verification_service}
        content={previewData}
        listingId={listingId}
      />
    );
  }

  const idCollectionModal = (
    <CustomDialog
      fullWidth
      disableExit
      maxWidth="lg"
      customHeight={"calc(100% - 20px)"}
      open={!!modalOpen}
      title="New ID Collection"
      onClose={handleCancelDataChange}
      content={getDialogContent()}
      previewContent={getPreviewContent()}
    />
  );

  return (
    <div>
      {idCollectionModal}
      <Box py={1} className={classes.sectionRow}>
        <SvgIcon
          className={classes.icon}
          viewBox="0 0 16 16"
          component={VerificationIcon}
        />
        <Typography variant="h1">{"ID Collection"}</Typography>
      </Box>
      <Box className={classes.infoContainer}>
        <Typography style={{flex: 1}}>
          {
            "Collect IDs directly or through our verification partners. If you prefer to not collect any IDs, leave empty."
          }
        </Typography>
        {verificationType === "none" && (
          <PrimaryButton
            size="small"
            className="mr-2"
            id="addIDCollection"
            label={"Add setting"}
            disabled={disableEdit}
            onClick={() => {
              setModalOpen(true);
            }}
          />
        )}
      </Box>
      {verificationType !== "none" && (
        <div className={classes.grayCard}>
          <Typography
            className={classes.cardText}
            onClick={() => setModalOpen(true)}
            component="div"
          >
            {getVerificationSummary()}
          </Typography>
          {!disableEdit && (
            <>
              <EditIconButton
                disablePadding
                spaceRight={3}
                onClick={() => setModalOpen((prev) => true)}
              />
              <DeleteIconButton
                disablePadding
                onClick={() => handleVerificationTypeChange("none")}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default withLDConsumer()(VerificationPanel);
