import React from "react";
// UI
import {
  Checkbox,
  makeStyles,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@material-ui/core";
import {Col, Row} from "reactstrap";

// Custom
import MultiButtonBar from "core/buttons/MultiButtonBar";
// Utils
import {compareTexts} from "utilities/formatUtilities";
import clsx from "clsx";
import {capitalize} from "utilities/helperFunctions";
import {triggerParams} from "configuration/specs";
import {FIXED_SIZES} from "configuration/settings";
import {THEME} from "configuration/settings.js";
import NotificationSettingsPanel from "components/Panels/NotificationSettingsPanel";
import _ from "lodash";
import CustomSwitch from "core/switches/CustomSwitch";
import FilledSelect from "core/selects/FilledSelect";
import {useSelector} from "react-redux";
import VirtualizedList from "components/Lists/VirtualizedList";
import {func} from "prop-types";

const useStyles = makeStyles((theme) => ({
  notificationsContainer: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    border: "1px solid rgba(229, 229, 234, 0.75)",
    borderRadius: 10,
  },
  triggerList: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  group_toggle_box: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    flexGrow: 1,
    flexBasis: "100%",
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  strong: {
    color: "#000",
    fontWeight: 600,
  },
  checkbox: {padding: theme.spacing(2)},
  controlLabel: {
    marginBottom: 0,
    alignItems: "flex-start",
    "& svg": {
      width: 16,
      height: 16,
    },
  },
  checkboxLabel: {paddingTop: 6},
  item: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
}));

export default function UserSettingsPanel({
  selectedListings,
  setSelectedListings,
  scopedGroupsEnabled,
  setScopedGroupsEnabled,
}) {
  const classes = useStyles();
  const groups = useSelector((state) => state.defaultReducer.listing_groups);
  const listing_groups = groups.filter((g) => g.group_id != "ALL");

  function displayRowItem({index}) {
    const rowItem = listing_groups[index];

    const handleOnClick = () => {
      if (selectedListings.includes(rowItem.group_id)) {
        setSelectedListings((prev) => [
          ...prev.filter((item) => item !== rowItem.group_id),
        ]);
      } else {
        setSelectedListings((prev) => [...prev, rowItem.group_id]);
      }
    };

    return (
      <ListItem
        disableRipple
        divider
        dense
        className={classes.item}
        button
        ContainerComponent="div"
        selected={selectedListings.includes(rowItem.group_id)}
        onClick={handleOnClick}
      >
        <ListItemIcon className={classes.itemIcon}>
          <Checkbox
            edge="start"
            size="small"
            checked={selectedListings.includes(rowItem.group_id)}
            disableRipple
            className={classes.checkbox}
          />
        </ListItemIcon>
        <ListItemText
          primary={rowItem.name}
          primaryTypographyProps={{
            variant: "h1",
            className: clsx(classes.ellipsis, classes.title),
          }}
          secondary={
            rowItem.connected_to.ids.length === 1
              ? rowItem.connected_to.ids.length + " Listing included"
              : rowItem.connected_to.ids.length + " Listings included"
          }
          secondaryTypographyProps={{
            variant: "body1",
            className: clsx(classes.ellipsis, classes.subtitle),
          }}
        />
      </ListItem>
    );
  }

  function getSummaryText() {
    if (!scopedGroupsEnabled) {
      return <Typography>This user can see all listings & bookings</Typography>;
    } else if (selectedListings.length == 0) {
      return (
        <Typography color="error">
          Please select at least 1 listing group to enable
        </Typography>
      );
    }
    return (
      <Typography>{`This user can see listings & bookings at ${selectedListings.length} groups`}</Typography>
    );
  }

  return (
    <>
      <Typography className={classes.label} color="textSecondary">
        {
          "Turn on this setting to limit this user to only see listings & bookings from specific listing groups. This will also hide messaging, connected accounts & smart devices in business settings."
        }
      </Typography>
      <div className={classes.group_toggle_box}>
        <div>
          <CustomSwitch
            alignStart
            checked={scopedGroupsEnabled}
            onChange={setScopedGroupsEnabled}
          />
        </div>
        <div>{getSummaryText()}</div>
      </div>

      {scopedGroupsEnabled && (
        <div style={{height: "calc(100vh - 570px)"}}>
          <VirtualizedList
            hideScrollbar
            getRowItem={displayRowItem}
            rowHeight={FIXED_SIZES.connected_channel_accounts}
            totalRows={listing_groups.length}
          />
        </div>
      )}
    </>
  );
}
