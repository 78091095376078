import {API} from "aws-amplify";

export const postServiceStripe = async (body) => {
  try {
    console.log("POSTING SERVICE BODY", body);
    const response = await API.post("ensoAPI", `/services/stripe`, {
      body: body,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const patchServiceStripe = async (body) => {
  try {
    console.log("PATCH STRIPE SERVICE BODY", body);
    const response = await API.patch("ensoAPI", `/services/stripe`, {
      body: body,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getServices = async (stripe_only = false) => {
  let params = "";
  if (stripe_only) {
    params = "?device_accounts=false&pms_accounts=false";
  }
  try {
    const response = await API.get("ensoAPI", `/services${params}`);
    return response;
  } catch (err) {
    throw err;
  }
};
