import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Switch, Typography} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "fit-content",
    display: "inline-block",
    "&.-disabled": {opacity: 0.5},
  },
  root: {
    padding: "16px 10px",
    height: 30,
    width: 70,
    "&.-large": {
      width: 72,
      padding: 0,
    },
  },
  track: {
    borderRadius: 999,
    opacity: "1 !important",
    backgroundColor: "transparent !important",
  },
  thumb: {
    backgroundColor: "#FAFAFA",
    boxShadow: "none",
    width: 18,
    height: 18,
    margin: 0,
    "&.-large": {
      width: 26,
      height: 26,
    },
  },
  startLeft: {marginLeft: -10},
  disabled: {
    "& + $track": {
      opacity: "0.5 !important",
    },
  },
  switchBase: {
    padding: "7px 11px",
    "& input": {
      width: 67,
      left: 0,
    },
    "&.-checked": {
      transform: "translateX(30px) !important",
      "& input": {left: "-100%"},
    },
    "&.-large": {
      padding: "2px 3px",
      "&.-checked": {transform: "translateX(43px) !important"},
    },
  },
  label: {
    color: "#FFF",
    position: "absolute",
    top: "50%",
    left: 10,
    width: 50,
    height: 21,
    borderRadius: 999,
    fontSize: 11,
    lineHeight: "15px",
    fontWeight: 700,
    transform: "translateY(-50%)",
    backgroundColor: "#111421",
    display: "flex",
    alignItems: "center",
    paddingRight: 7,
    justifyContent: "flex-end",
    "&.-large": {
      fontSize: 15,
      lineHeight: "20px",
      left: 2,
      height: "27px !important",
      width: 70,
      paddingRight: 11,
    },
    "&.-checked": {
      "&.-large": {
        paddingLeft: 13,
        paddingRight: 7,
      },
      height: 20,
      justifyContent: "flex-start",
      backgroundColor: "#25D363",
      paddingLeft: 10,
    },
  },
}));

function CustomSwitch(props) {
  const {alignStart, checked, large, label, disabled, ...other} = props;
  const classes = useStyles({checked: checked});

  return (
    <div
      className={clsx(classes.container, {
        [classes.startLeft]: alignStart,
        "-disabled": !!disabled,
      })}
    >
      <Typography
        className={clsx(classes.label, {
          "-checked": !!checked,
          "-large": !!large,
        })}
      >
        {!!checked ? "ON" : "OFF"}
      </Typography>
      <Switch
        {...other}
        size="medium"
        checked={checked}
        disabled={disabled}
        classes={{
          root: clsx(classes.root, {"-large": !!large}),
          track: clsx("switch-track", classes.track, {
            "-checked": !!checked,
            "-unchecked": !checked,
          }),
          thumb: clsx("switch-thumb", classes.thumb, {"-large": !!large}),
          switchBase: clsx(classes.switchBase, {
            "-checked": !!checked,
            "-large": !!large,
          }),
          disabled: classes.disabled,
        }}
      />
    </div>
  );
}

export default CustomSwitch;
