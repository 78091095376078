import React from "react";
import {useSelector} from "react-redux";
// UI
import {Box} from "@material-ui/core";
import useThreePanelStyles from "styles/useThreePanelStyles";
// Custom
import Panel1 from "./Panel1";
import Panel2 from "./Panel2";
import Panel3 from "./Panel3";
import ViewProfile from "components/TextFields/ViewProfile";
import MobileThreePanel from "core/transitions/MobileThreePanel";
// Utils
import clsx from "clsx";

export default function ThreePanel({
  panel1,
  panel2 = false,
  panel3 = false,
  panel3Large,
  mobileActiveView,
  backLabel,
  goBack,
  actions,
  hideSubnavControls,
  panel2FixedWidth,
  panel3FixedWidth,
  hideBackBtn,
  tabletPanel3Open,
  bg = "#FFF",
  inboxTabs,
  forcePanel1FullWidth = false,
  usefitWidth = false,
}) {
  const selectedPanelIndex = React.useMemo(() => {
    if (mobileActiveView === "panel1") {
      return 0;
    } else if (mobileActiveView === "panel2") {
      return 1;
    } else if (mobileActiveView === "panel3") {
      return 2;
    } else {
      return -1;
    }
  }, [mobileActiveView]);

  const styleProps = {
    panel3Large,
    panel2FixedWidth,
    panel3FixedWidth,
    bg: Array.isArray(bg) ? bg[selectedPanelIndex] : bg,
    desktopBG1: Array.isArray(bg) ? bg[0] : bg,
    desktopBG2: Array.isArray(bg) ? bg[1] : bg,
    desktopBG3: Array.isArray(bg) ? bg[2] : bg,
  };
  const classes = useThreePanelStyles(styleProps);
  const isTabletView =
    useSelector((state) => state.defaultReducer.deviceType) === "tablet";
  const isMobileView =
    useSelector((state) => state.defaultReducer.deviceType) === "mobile";
  const [activePanel, setActivePanel] = React.useState(null);
  const bottomRounded = "0px 0px 10px 10px";
  const mobileShadow = "0px 4px 8px rgba(28,41,90,0.0392157)";
  const isMessagingPage = window.location.pathname.includes("/admin/messages");

  React.useEffect(() => {
    if (!isTabletView) {
      activePanel !== null && setActivePanel((prev) => null);
    } else if (isMessagingPage) {
      if (!!panel3 && !activePanel) {
        setActivePanel((prev) => "panel2");
      } else if (!!panel3 && activePanel === "panel3") {
        return;
      }
    } else {
      if (tabletPanel3Open) {
        setActivePanel((prev) => "panel3");
      } else {
        setActivePanel((prev) => "panel2");
      }
    }
  }, [panel2, panel3, isTabletView, tabletPanel3Open]);

  const getPanel = (panel, classN, style) => {
    return (
      <Box className={clsx(classes.mobileGridItem, classN)} style={style}>
        {panel}
      </Box>
    );
  };

  const goToProfileBtn =
    isMessagingPage && isTabletView ? (
      <ViewProfile desktop onClick={() => setActivePanel((prev) => "panel3")} />
    ) : null;

  const leftPanel = isMobileView ? (
    getPanel(panel1, classes.mobileListPanel, {
      borderRadius: bottomRounded,
      boxShadow: mobileShadow,
    })
  ) : (
    <Panel1
      content={panel1}
      allRounded={!panel2}
      hasContentAbove={!!inboxTabs && !isMobileView}
      bg={styleProps.desktopBG1}
    />
  );

  const midPanel = isMobileView
    ? getPanel(panel2, classes.mobileDetailsPanel, {
        borderRadius: bottomRounded,
        boxShadow: mobileShadow,
      })
    : panel2 && (
        <Panel2
          content={panel2}
          flex={!!inboxTabs && !isTabletView}
          disableStandaloneStyle
          hasContentAbove={!!inboxTabs && !!isTabletView}
          navigationBtn={goToProfileBtn}
          bg={styleProps.desktopBG2}
          isPanel3Open={isTabletView ? false : !!panel3}
          hidden={isTabletView && activePanel !== "panel2"}
        />
      );

  const rightPanel = isMobileView ? (
    getPanel(panel3, classes.mobileActionPanel, {
      borderRadius: bottomRounded,
      boxShadow: mobileShadow,
    })
  ) : (
    <Panel3
      content={panel3}
      disableStandaloneStyle
      hasContentAbove={!!inboxTabs && !!isTabletView}
      isMessagingPage={isMessagingPage}
      fullWidth={isTabletView}
      bg={styleProps.desktopBG3}
      hidden={!panel3 || (isTabletView && activePanel !== "panel3")}
      goBack={
        isTabletView && isMessagingPage
          ? () => setActivePanel((prev) => "panel2")
          : null
      }
    />
  );

  if (isMobileView) {
    return (
      <MobileThreePanel
        goBack={goBack}
        actions={actions}
        bg={styleProps.bg}
        leftPanel={leftPanel}
        midPanel={midPanel}
        rightPanel={rightPanel}
        backLabel={backLabel}
        hideBackBtn={hideBackBtn}
        activeView={mobileActiveView}
        hideSubnavControls={hideSubnavControls}
      />
    );
  } else {
    return (
      <Box className={clsx(classes.mainContainer, {fitContent: usefitWidth})}>
        <Box
          className={clsx(classes.panelGroup, {
            fullWidth: isTabletView || forcePanel1FullWidth,
            tabletPanel3Open: !!tabletPanel3Open && isTabletView,
          })}
        >
          {!!inboxTabs && inboxTabs}
          <Box className={classes.messagingPanels}>
            {leftPanel}
            {midPanel}
            {isTabletView && isMessagingPage && rightPanel}
          </Box>
        </Box>
        {!(isTabletView && isMessagingPage) && rightPanel}
      </Box>
    );
  }
}
