import React from "react";
import {useDispatch} from "react-redux";
// UI
import {TextField, Typography} from "@material-ui/core";
import {lighten, makeStyles} from "@material-ui/core/styles";
import CustomFieldDropdown from "components/MultiOption/CustomFieldDropdown";
// Actions
import {getCustomFields} from "redux/actions/settingsActions";
import {validateText} from "redux/actions/settingsActions";
// Utils
import {highlightCustomFields} from "utilities/formatUtilities";
import {THEME} from "configuration/settings.js";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > label": {
      color: "rgba(0, 0, 0, 0.6)",
      "&.Mui-focused": {color: "rgba(0, 0, 0, 0.6)"},
    },
    "& > .MuiFilledInput-root": {
      borderRadius: (props) => (props.disableRadius ? 5 : "5px!important"),
      backgroundColor: THEME.inputBg,
      "&:hover": {backgroundColor: THEME.inputBg},
      "&.Mui-focused": {backgroundColor: THEME.inputBg},
      "& > input": {borderRadius: (props) => props.customBorderRadius ?? 5},
    },
  },
  noLabel: {
    "& input": {
      padding: theme.spacing(3),
    },
    "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      MozAppearance: "textfield",
      margin: 0,
    },
    "&.-multiline": {
      "& .MuiFilledInput-root": {
        padding: theme.spacing(3),
      },
    },
  },
  container: {position: "relative"},
  backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "auto",
    borderRadius: 5,
    backgroundColor: THEME.inputBg,
    height: "100%",
    "&.-fullWidth": {width: "100%"},
  },
  highlights: {
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    padding: "27px 12px 10px",
    lineHeight: "1.1876em",
    color: "transparent",
    "& mark": {
      color: "transparent",
      padding: 0,
      borderRadius: 10,
      "&.tag": {backgroundColor: lighten(theme.palette.secondary.main, 0.8)},
      "&.error": {backgroundColor: lighten(theme.palette.error.main, 0.8)},
    },
  },
  highlightInput: {
    margin: 0,
    borderRadius: 5,
    backgroundColor: "transparent",
    "& > .MuiFilledInput-root": {backgroundColor: "transparent !important"},
  },
  characterLimit: {
    color: THEME.subdued,
    textAlign: "right",
    marginTop: (props) => props.characterLimitMarginTop ?? 0,
  },
}));

function FilledTextField(props) {
  const {
    label,
    value,
    InputProps,
    inputRef,
    disableRadius,
    multiline,
    fullWidth,
    onChange,
    useCustomFields,
    noValidation,
    characterLimit = null,
    characterLimitMarginTop,
    AIprops,
    onValidityCheck = null,
    customBorderRadius,
    additionalObjectsVariables = [],
    ...other
  } = props;
  const classes = useStyles({
    disableRadius,
    characterLimitMarginTop,
    customBorderRadius,
  });
  const dispatch = useDispatch();
  const innerInputRef = React.useRef(null);
  const [validationError, setValidationError] = React.useState("");
  const [highlightedText, setHighlightedText] = React.useState("");
  const [customFields, setCustomFields] = React.useState({});

  const updateCustomFields = () => {
    let isMounted = true;
    dispatch(
      getCustomFields({
        objects: additionalObjectsVariables,
        onSuccess: (response) =>
          !isMounted ? null : setCustomFields((prev) => response ?? {}),
      }),
    );

    return () => {
      isMounted = false;
    };
  };
  React.useEffect(updateCustomFields, []);

  React.useEffect(() => {
    let isMounted = true;
    let timer = null;

    if (!useCustomFields || !!noValidation) {
      return;
    }
    setHighlightedText((prev) => value);
    timer = setTimeout(() => {
      dispatch(
        validateText({
          text: value,
          onSuccess: !isMounted
            ? () => null
            : (response) => {
                if (!!onValidityCheck && !!isMounted) {
                  onValidityCheck(
                    response.invalid_fields && !response.invalid_fields.length,
                  );
                }
                const text = highlightCustomFields({
                  text: value,
                  validFields: response.valid_fields,
                  invalidFields: response.invalid_fields,
                });
                setHighlightedText((prev) => text);
                if (!!response.error) {
                  setValidationError((prev) => response.error);
                } else {
                  setValidationError((prev) => "");
                }
              },
        }),
      );
    }, 150);

    return () => {
      clearTimeout(timer);
      isMounted = false;
    };
  }, [value]);

  const insertPersonalizedText =
    ({isAIText = false}) =>
    (personalizedText) => {
      const input = innerInputRef.current;
      if (input) {
        const newValue =
          input.value.substring(0, input.selectionStart) +
          `${isAIText ? personalizedText : `<${personalizedText}>`}` +
          input.value.substring(input.selectionEnd);
        onChange({target: {value: newValue}});
      }
    };

  if (!useCustomFields) {
    return (
      <>
        <TextField
          label={label}
          value={value}
          variant="filled"
          inputRef={inputRef}
          fullWidth={fullWidth}
          multiline={multiline}
          InputProps={{...InputProps, disableUnderline: true}}
          InputLabelProps={{shrink: true}}
          classes={{
            root: clsx(classes.root, {
              [classes.noLabel]: !label,
              "-multiline": !!multiline,
            }),
          }}
          onChange={onChange}
          error={!!validationError}
          helperText={validationError}
          {...other}
        />
        {!!characterLimit && (
          <Typography
            className={classes.characterLimit}
            component="span"
            variant="caption"
          >
            {`${value.length}/${characterLimit}`}
          </Typography>
        )}
      </>
    );
  } else {
    return (
      <>
        <div className={classes.container}>
          <div className={clsx(classes.backdrop, {"-fullWidth": !!fullWidth})}>
            <Typography
              component="div"
              className={classes.highlights}
              dangerouslySetInnerHTML={{__html: highlightedText}}
            />
          </div>
          <TextField
            label={label}
            value={value}
            variant="filled"
            fullWidth={fullWidth}
            inputRef={innerInputRef}
            multiline={multiline}
            InputProps={{...InputProps, disableUnderline: true}}
            InputLabelProps={{shrink: true}}
            classes={{
              root: clsx(classes.root, classes.highlightInput, {
                [classes.noLabel]: !label,
                "-multiline": !!multiline,
              }),
            }}
            onChange={onChange}
            {...other}
          />
        </div>
        {!!characterLimit && (
          <Typography
            className={classes.characterLimit}
            component="span"
            variant="caption"
          >
            {`${value.length}/${characterLimit}`}
          </Typography>
        )}
        <CustomFieldDropdown
          onSelect={insertPersonalizedText({isAIText: false})}
          customFields={customFields}
          AIprops={{
            type: AIprops?.type,
            show: !!AIprops?.type,
            objects: AIprops?.objects,
            insertGeneratedText: insertPersonalizedText({isAIText: true}),
          }}
        />
      </>
    );
  }
}

export default FilledTextField;
