import React from "react";
import {Storage} from "aws-amplify";
// UI
import {InputAdornment, MenuItem, MenuList, Modal} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
// Custom
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";
import CustomMenu from "core/menus/CustomMenu";
import ExpandButton from "core/buttons/ExpandButton";
import FullLoader from "../Dialogs/FullLoader";
// Utilities
import {useImageCompressor} from "utilities/fileUtilities";
import {newUuid, sleep} from "utilities/helperFunctions";
import {validImageTypes} from "configuration/constants";
import config from "configuration/settings.js";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 5),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    width: "80%",
    overflow: "scroll",
  },
}));

export default function ImageTextField({
  picture,
  setPicture,
  label,
  imgPrefix = "img-",
  savePath = "",
  available_pictures = [],
  uploadOnly,
  assetType,
  setAssetType,
}) {
  const classes = useStyles();
  const imgInputRef = React.useRef();
  //State
  const [modalOpen, setModalOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [progressObj, setProgressObj] = React.useState({loaded: 0, total: 100});
  const [loaderOpen, setLoaderOpen] = React.useState(false);
  const [imgToUpload, setImgToUpload] = React.useState(null);
  const [compressEnabled, setCompressEnabled] = React.useState(false);
  const editable = !!assetType && ["picture_url", "video"].includes(assetType);

  useImageCompressor({
    srcImgs: !!compressEnabled && !!imgToUpload ? [imgToUpload] : [],
    onSuccess: (outputImgs) => onCompressSuccess(outputImgs),
  });

  const imageName = React.useMemo(() => {
    if (validImageTypes.some((type) => picture?.includes(type))) {
      return picture.split("/").pop().split("#")[0].split("?")[0];
    } else {
      return picture;
    }
  }, [picture]);

  const browseFile = () => {
    setAssetType("picture");
    if (imgInputRef.current) {
      imgInputRef.current.click();
    }
  };

  const handleLoaderClose = () => setLoaderOpen((prev) => false);

  const onCompressSuccess = (outputImgs) => {
    setCompressEnabled((prev) => false);
    setImgToUpload((prev) => null);
    handleUploadImage(outputImgs[0]);
  };

  const handleUploadImage = async (file) => {
    const imageName = `${savePath}${imgPrefix}${newUuid()}`;
    await Storage.put(imageName, file, {
      contentType: file.type,
      progressCallback(progress) {
        setProgressObj((prev) => ({
          loaded: progress.loaded,
          total: progress.total,
        }));
      },
    });
    const link = `${config.s3.URL}${imageName}`;
    setPicture(link);
    setLoaderOpen((prev) => false);
  };

  const handleSelectImage = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setProgressObj((prev) => ({loaded: 0, total: 100}));
    setLoaderOpen((prev) => true);
    setImgToUpload((prev) => file);
    sleep(250).then(() => setCompressEnabled((prev) => true));
  };

  const handleURLOptionSelected = (asset) => {
    setAssetType(asset);
    setMenuAnchorEl(null);
  };

  const handleSelectPhoto = () => {
    setModalOpen(true);
    setAssetType("picture");
  };

  let adornment = !uploadOnly ? (
    <>
      <ExpandButton
        label="Browse"
        size="small"
        variant="outlined"
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
      />
      <CustomMenu
        overflowAuto
        zIndex={1300}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        timeout={100}
        content={
          <MenuList id="saving-options-menu">
            <MenuItem button disableRipple onClick={browseFile}>
              {"Upload Photo"}
            </MenuItem>
            {available_pictures.length > 0 && (
              <MenuItem button disableRipple onClick={handleSelectPhoto}>
                {"Select Photo"}
              </MenuItem>
            )}
            {!!assetType && (
              <MenuItem
                button
                disableRipple
                onClick={() => handleURLOptionSelected("picture_url")}
              >
                {"Photo URL"}
              </MenuItem>
            )}
            {!!assetType && (
              <MenuItem
                button
                disableRipple
                onClick={() => handleURLOptionSelected("video")}
              >
                {"Video URL"}
              </MenuItem>
            )}
          </MenuList>
        }
      />
    </>
  ) : (
    <PrimaryButton
      label="Browse"
      size="small"
      variant="outlined"
      onClick={browseFile}
    />
  );

  return (
    <div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className={classes.modal}>
          {available_pictures.map((p, i) => (
            <img
              key={p.picture ?? i}
              src={p.picture}
              style={{width: 200}}
              onClick={() => {
                setModalOpen(false);
                setPicture(p.picture);
              }}
            />
          ))}
        </div>
      </Modal>
      <FullLoader
        open={loaderOpen}
        loadingText="Uploading Image..."
        disableDismiss
        onClose={handleLoaderClose}
        progress={progressObj}
      />
      <input
        id="imageAttachmentInput"
        ref={imgInputRef}
        style={{display: "none"}}
        type="file"
        accept="image/*"
        onChange={handleSelectImage}
      />
      <FilledTextField
        disabled={!editable}
        fullWidth
        id="picture"
        label={label}
        autoFocus
        value={!editable ? imageName : picture}
        onChange={!editable ? undefined : (e) => setPicture(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{adornment}</InputAdornment>
          ),
        }}
      />
    </div>
  );
}
